import { Utils } from "@kwilteam/kwil-js";
import { kwil } from "./webKwil";

export async function getDatabases(publicKey) {
    if(publicKey) {
        const dbs = await kwil.listDatabases(publicKey);
        let arr = []
        for(let i = 0; i < dbs.data.length; i++) {
            arr.push(dbs.data[i].name)
        }
        return arr;
    }
}

export async function getSchema(owner, db) {
    const dbid = kwil.getDBID(owner.toLowerCase(), db.toLowerCase());
    const schema = await kwil.getSchema(dbid);
    return schema;
}

export async function select(owner, db, query) {
    const dbid = kwil.getDBID(owner, db);
    const result = await kwil.selectQuery(dbid, query);
    return result;
}

export function organizeSql(arr) {
    let organized
    for (let i = 0; i < arr.length; i++) {
        if(i === arr.length - 1) {
            organized = arr[i]
        } else {
            organized = arr[i] + "\n"
        }
    }
    return organized
}

//ActionBase must be above the classes that extend it
class ActionBase {
    constructor(dbid, publicKey, name, inputs, signer) {
        this.dbid = dbid
        this.publicKey = publicKey
        this.name = name

        if(inputs) {
            this.inputs = inputs
        }

        if(signer) {
            this.signer = signer
        }
    }

    static organizeInputs(inputNames, inputValues){
        let inputObj = {}

        for(let i = 0; i < inputNames.length; i++) {
            inputObj = {
                ...inputObj,
                [inputNames[i]]: inputValues[i]
            }
        }

        return inputObj
    }

    static getDBID(publicKey, db) {
        return kwil.getDBID(publicKey, db);
    }
}

export class ActionCalls extends ActionBase {
    constructor(dbid, publicKey, name, inputs, signer = null) {
        super(dbid, publicKey, name, inputs, signer)
    }

    async buildMsg(description = 'Sign to execute the view action!') {
        let msg = kwil
            .actionBuilder()
            .dbid(this.dbid)
            .name(this.name)

        if(this.inputs) {
            const actionInputs = new Utils.ActionInput().putFromObject(this.inputs)
            msg = msg.concat(actionInputs)
        }

        if(this.signer) {
            msg = msg.signer(this.signer)
            msg.publicKey(this.publicKey)
            msg.description(description)
        }

        return await msg.buildMsg();
    }

    async call(msg) {
        const { data } = await kwil.call(msg);
        return data.result;
    }
}

export class ActionTransactions extends ActionBase {
    constructor(dbid, publicKey, name, inputs, signer) {
        super(dbid, publicKey, name, inputs, signer)
    }

    async buildTx(description = 'Sign to execute your action!') {
        let tx = kwil
            .actionBuilder()
            .dbid(this.dbid)
            .publicKey(this.publicKey)
            .name(this.name)
            .description(description)
            .signer(this.signer)

        if(this.inputs) {
            const actionInputs = new Utils.ActionInput().putFromObject(this.inputs)
            tx = tx.concat(actionInputs)
        }

        return await tx.buildTx();
    }

    async broadcast(tx) {
        const res = await kwil.broadcast(tx);
        return res.data.tx_hash;
    }
}

export function isJsonError(input) {
    try {
        const msg = JSON.parse(input.message);
        return msg.message.toString();
    } catch (e) {
        return input.toString();
    }
} 

export function organizeActions(allActions) {
    let updateActions = [];
    let viewActions = [];

    for(const action of allActions) {
        if(action.mutability === 'update') {
            updateActions.push(action);
        }

        if(action.mutability === 'view') {
            viewActions.push(action);
        }
    }

    return { updateActions, viewActions };
}