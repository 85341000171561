import { Modal } from "@mui/material";
import { CancelWorkspaceButton, ConfirmDropDbButton } from "../../../Mui/buttons";

export default function DropDbModal({ openModal, setOpenModal, dropDbFunc }) {
    return(
        <Modal
            open={openModal}
            onClose={() => setOpenModal(false)}
            sx={{ diplay: "flex" }}
        >
            <div className="new-workspace-modal">
                <h3>Drop Database</h3>
                <div className="modal-divider" />
                <p>Are you sure you want to drop this database?</p>
                <p>This will permanently remove your live database from Kwil. This action cannot be undone.</p>
                <div className="modal-divider" />
                <div className="modal-buttons">
                    <ConfirmDropDbButton
                        onClick={() => dropDbFunc()}
                    >
                        Confirm
                    </ConfirmDropDbButton>
                    <CancelWorkspaceButton
                        onClick={() => setOpenModal(false)}
                    >
                        Cancel
                    </CancelWorkspaceButton>
                </div>
            </div>
        </Modal>
    )
}