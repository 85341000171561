import { createAction, createReducer } from "@reduxjs/toolkit";

export const addTab = createAction('addTab')
export const removeTab = createAction('removeTab')
export const selectFile = createAction('selectFile')
export const unselectFile = createAction('unselectFile')
export const resetLoadedFiles = createAction('resetLoadedFiles')

const initialState = {
    tabFiles: ['index.kf'],
    selectedFile: 'index.kf',
}

export const loadedFiles = createReducer(initialState, {
    [addTab]: (state, action) => {
        return {
            ...state,
            tabFiles: [...state.tabFiles, action.payload],
        }
    },
    [removeTab]: (state, action) => {
        return {
            ...state,
            tabFiles: state.tabFiles.filter(file => file !== action.payload),
        }
    },
    [selectFile]: (state, action) => {
        return {
            ...state,
            selectedFile: action.payload,
        }
    },
    [unselectFile]: (state) => {
        return {
            ...state,
            selectedFile: null,
        }
    },
    [resetLoadedFiles]: () => {
        return {
            tabFiles: [],
            selectedFile: null,
        }
    }
})