function removeInlineComments(code) {
  // Split the code into an array of lines
  const lines = code.split('\n');

  // Iterate through the lines, removing the inline comments
  const filteredLines = lines.map((line) => {
    let inString = false;
    let commentIndex = -1;

    for (let i = 0; i < line.length; i++) {
      const currentChar = line[i];

      if (currentChar === '"' && (i === 0 || line[i - 1] !== '\\')) {
        inString = !inString;
      }

      if (currentChar === "'" && (i === 0 || line[i - 1] !== '\\')) {
        inString = !inString;
      }

      if (currentChar === '/' && line[i + 1] === '/' && !inString) {
        commentIndex = i;
        break;
      }
    }

    if (commentIndex !== -1) {
      // If there's a comment, return the line without the comment
      return line.substring(0, commentIndex);
    } else {
      // If there's no comment, return the line unchanged
      return line;
    }
  });

  // Join the filtered lines back into a single string
  return filteredLines.join('\n');
}

export function removeEvenIndices(arr) {
  return arr.filter((_, index) => index % 2 !== 0);
}

export function cleanCode(allFiles, selectedFiles) {
  let readyForCompile = [];

  for (let i = 0; i < selectedFiles.length; i++) {
      const freshCode = allFiles[`${selectedFiles[i]}`].code;
      const filtered = removeInlineComments(freshCode);
      const updatedFile = { ...allFiles[`${selectedFiles[i]}`], code: filtered }
      readyForCompile.push(updatedFile);
  }

  return readyForCompile
}

export function parseKfArray(codeArray) {
  let parsed = [];

  if(window.parseKuneiform) {
      for(let i = 0; i < codeArray.length; i++) {
          const parsedCode = window.parseKuneiform(codeArray[i].code)
          
          if(parsedCode.error) {
              codeArray[i].error = parsedCode.error
          }

          if(parsedCode.json) {
              codeArray[i].json = parsedCode.json
          }

          parsed.push(codeArray[i])
      }
  }

  return parsed;
}

export function createErrorMessage(arr) {
  let error = "";
  for (let i = 0; i < arr.length; i++) {
      if (arr[i].error) {
          error += `${arr[i].file}: ${arr[i].error}`
      }
  }
  return error
}