import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeTab, selectFile } from "../../redux/reducers/loadedFiles";
import { IDETabButton } from "../Mui/buttons";
import { CloseFileIcon } from "../Mui/icons";
import Monaco from "./Monaco-components/Monaco";

export default function CodeEditor() {
    const selectedFile = useSelector(state => state.loadedFiles.selectedFile);
   
    const [buttonHovered, setButtonHovered] = useState(null);
    const tabButtons = useSelector(state => state.loadedFiles.tabFiles);
    const activeTab = tabButtons.indexOf(selectedFile)

    const dispatch = useDispatch();

    function handleClick(i, fileName) {
        dispatch(selectFile(fileName))
    }

    function detNewFileIndex(i) {
        if (i >= 0) {
            return i
        }

        return 1   
    }

    function closeFile(e, fileName ) {
        e.stopPropagation();
        const newFileIndex = detNewFileIndex(tabButtons.indexOf(fileName));
        dispatch(removeTab(fileName))
        dispatch(selectFile(tabButtons[newFileIndex]))
    }

    function detectNewTab() {
        const lastIndex = tabButtons.length - 1;
        dispatch(selectFile(tabButtons[lastIndex]))
    }

    useEffect(() => {
        if(tabButtons.length > 0) {
            detectNewTab()
        }
    }, [tabButtons.length]) 

    return(
        <div className="code-editor">
            <div className="tab-button-container">
                {tabButtons.map((button, index) => {
                    return(
                        <>
                            <IDETabButton
                                key={index}
                                onClick={() => handleClick(index, button)}
                                sx={{
                                    backgroundColor: activeTab === index ? '#1E1E1E' : 'transparent',
                                }}
                                onMouseEnter={() => setButtonHovered(index)}
                                onMouseLeave={() => setButtonHovered(null)}
                            >
                                <p className='tab-button-text'>
                                    {button}
                                </p>
                                {
                                    buttonHovered === index ?
                                        <CloseFileIcon
                                            onClick={(e) => closeFile(e, button)}
                                        /> : null
                                }
                                
                            </IDETabButton>
                            <>
                                {activeTab !== index && activeTab - 1 !== index ? 
                                    <div className="tab-button-divider" /> :
                                    <div className="tab-button-divider-clear" />
                                }
                            </> 
                        </>   
                    )
                })}
            </div>
            {tabButtons.length > 0 && <Monaco />}
        </div>
    )
}