import { ConnectButton } from "@rainbow-me/rainbowkit";
import { MetamaskButton } from "../../Mui/buttons";

export default function MetamaskInterface() {
    return(
        <ConnectButton.Custom>
            {({
                account,
                chain,
                openAccountModal,
                openChainModal,
                openConnectModal,
                authenticationStatus,
                mounted,
            }) => {
                const ready = mounted && authenticationStatus !== 'loading';
                const connected =
                    ready &&
                    account &&
                    chain &&
                    (!authenticationStatus || authenticationStatus === 'authenticated');
                return(
                    <div
                        {...(!ready && {
                            'aria-hidden': true,
                            'style': {
                                opacity: 0,
                                pointerEvents: 'none',
                                userSelect: 'none',
                            },
                        })}
                    >
                        {(() => {
                            if (!connected) {
                                return(
                                    <MetamaskButton onClick={openConnectModal} type="button">
                                        Connect Wallet
                                    </MetamaskButton>
                                )
                            }

                            if(chain.unsupported) {
                                return(
                                    <MetamaskButton onClick={openChainModal} type="button">
                                        Wrong Network
                                    </MetamaskButton>
                                )
                            }

                            return(
                                <div style={{ display: "flex" }}>
                                    <MetamaskButton onClick={openAccountModal} type="button">
                                        {account.displayName}
                                    </MetamaskButton>
                                </div>
                            )
                        })()}
                    </div>
                )
            }}
        </ConnectButton.Custom>
    )
}