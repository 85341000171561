export default class KuneiformErrorListener {
    errors = [];

    syntaxError(recognizer, offendingSymbol, line, column, msg, e) {
        this.errors.push({
            startLineNumber: line,
            startColumn: column,
            endLineNumber: line,
            endColumn: column + 1,
            message: msg,
            severity: 3,
            code: 'SYNTAX_ERROR'
        })
    }

    getErrors() {
        return this.errors;
    }
}