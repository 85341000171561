function organizedObjToArray(obj) {
    let arr = []

    for (let key in obj) {
        arr = [
            ...arr,
            {
                [key]: obj[key]
            }
        ]
    }

    return arr
}

export function arrangeFiles(fileList, currentWorkspace, currentFolder) {
    let organized = {}
    let unorganized = []
    let organizedFileArray = []

    for (let file in fileList) {
        const folder = fileList[file].folder
        const workSpace = fileList[file].workSpace
        const isCurrentWorkspace = workSpace === currentWorkspace

        if(folder && isCurrentWorkspace) {
            organized = {
                ...organized,
                [folder]: {
                    ...organized[folder],
                    [file]: fileList[file]
                }
            }
        } else if(isCurrentWorkspace) {
            unorganized = [
                ...unorganized,
                fileList[file]
            ]
        }
    }
    
    if(currentFolder && !organized[currentFolder]) {
        organized = {
            ...organized,
            [currentFolder]: {}
        }
    }

    organizedFileArray = organizedObjToArray(organized)

    return { organizedFileArray, unorganized }
}