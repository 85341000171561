import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addTab, selectFile } from "../../../../../redux/reducers/loadedFiles";
import { FileNameButton } from "../../../../Mui/buttons"
import { SingleFileIcon } from "../../../../Mui/icons"
import FileContextMenu from "./FileContextMenu";


export default function FileInterface({ fileName, handleDragStart }) {
    const [openFileContext, setOpenFileContext] = useState(false);
    const [contextPoints, setContextPoints] = useState({ x: 0, y: 0 });
    const tabFiles = useSelector(state => state.loadedFiles.tabFiles);

    const dispatch = useDispatch();

    function rightClick(e) {
        e.preventDefault();
        setOpenFileContext(true);
        setContextPoints({ x: e.pageX, y: e.pageY });
    }

    function handleClick(n) {
        if(tabFiles.includes(n)) {
            dispatch(selectFile(n))
            return
        }

        dispatch(addTab(n))
    }

    useEffect(() => {
        function handleClickOutside() {
            setOpenFileContext(false);
        }
        window.addEventListener("click", handleClickOutside);
        return () => window.removeEventListener("click", handleClickOutside);
    }, [])
    
    return(
        <>
            <FileNameButton
                startIcon={<SingleFileIcon />}
                onContextMenu={(e) => rightClick(e)}
                draggable
                onDragStart={(e) => handleDragStart(fileName)}
                onClick={() => handleClick(fileName)}
            >
                <p className="file-text">{fileName}</p>
            </FileNameButton>
            {openFileContext &&
                <FileContextMenu 
                    left={contextPoints.x}
                    top={contextPoints.y}
                    currentFile={fileName}
                />
            }
        </>
        
    )
}
