import { RemoveWorkspaceButton } from "../../../Mui/buttons";


export default function CompileFileContextMenu({ left, top, json, name }) {

    function downloadJSON(data, fileName) {
        const jsonString = data;

        const blob = new Blob([jsonString], { type: "application/json" });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName + ".json";

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
    }

    return(
        <div
            className="menu-context-container"
            style={{
                left: left,
                top: top
            }}
        >
            <RemoveWorkspaceButton
                onClick={() => downloadJSON(json, name)}
            >
                Export JSON
            </RemoveWorkspaceButton>
        </div>
    )
}