import { useState } from "react";
import { useDispatch } from "react-redux";
import { ActionCalls, ActionTransactions, isJsonError, organizeSql } from "../../../../utils/playFunctions";
import { ActionAccordionDetails, ActionAccordionSummary, CustomAccordion } from "../../../Mui/accordion";
import { ExecuteActionButton } from "../../../Mui/buttons";
import { ExpandMoreIcon } from "../../../Mui/icons";
import { ActionInput } from "../../../Mui/textFields";
import { useSigner } from "wagmi";
import { waitForSuccess } from "../../../../utils/deployFunctions";
import { errorOn, successOff, successOn } from "../../../../redux/reducers/alerts";

export default function PlayAction({ action, owner, db, setDataFromAction, actionExecutedCount, setActionExecutedCount, setNewDeployed }) {
    const [inputVal, setInputVal] = useState([])
    const { name, inputs, statements } = action
    const { data: signer } = useSigner();

    const dispatch = useDispatch()

    async function exec() {
        //check if signer is connected
        if (!signer) {
            dispatch(errorOn("Please connect your wallet to execute actions!"))
            return
        }

        //get dbid
        const dbid = ActionTransactions.getDBID(owner, db)

        //organize the inputs
        const actionInputs = ActionTransactions.organizeInputs(inputs, inputVal)

        try {
            // tx flow
            if (action.mutability === 'update') {
                //instantiate the tx class
                const actionTx = new ActionTransactions(dbid, owner, name, actionInputs, signer)

                //build and broadcast the tx
                const tx = await actionTx.buildTx()
                const hash = await actionTx.broadcast(tx)
                dispatch(successOn(`Transaction broadcasting... Hash: ${hash}`))

                //wait for the tx to be mined
                await waitForSuccess(hash)
                dispatch(successOff())
                dispatch(successOn("Action successfully executed!"))

                //clear the inputs in the component state
                setInputVal([])

                //trigger a new query
                setActionExecutedCount(actionExecutedCount + 1)
                setNewDeployed(actionExecutedCount + 1)
            }

            //call flow
            if (action.mutability === 'view') {
                // in the action.auxiliaries array, if there is 'must_sign' then we need to build a msg and call with signature
                const useSigner = action.auxiliaries.includes('mustsign') || action.auxiliaries.includes('owner')

                // ternary operator either adds or removes the signer from the call
                const actionCall = new ActionCalls(dbid, owner, name, actionInputs, useSigner ? signer : null)
                const msg = await actionCall.buildMsg()
                const result = await actionCall.call(msg)
                setDataFromAction(result)
            }
        } catch (error) {
            console.log(error)
            const message = isJsonError(error)
            dispatch(errorOn(message))
        }
    }

    return (
        <div className="play-action">
            <CustomAccordion disableGutters>
                <ActionAccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    {name}
                </ActionAccordionSummary>
                <ActionAccordionDetails>
                    <div className="action-sql">
                        {organizeSql(statements)}
                    </div>
                    <ActionInputs
                        action={action}
                        inputVal={inputVal}
                        setInputVal={setInputVal}
                        exec={exec}
                    />
                    <ExecuteActionButton
                        onClick={exec}
                    >
                        <p className="execution-button-text">Execute</p>
                    </ExecuteActionButton>
                </ActionAccordionDetails>
            </CustomAccordion>
        </div>
    )
}

function ActionInputs({ action, inputVal, setInputVal, exec }) {
    const { name, inputs } = action

    return (
        <div className="action-execution">
            <div className="action-reqs">
                <p>Action Inputs</p>
            </div>
            <div className="action-body">
                {inputs.length > 0 ?
                    inputs.map((input, i) => {
                        return (
                            <div className="action-input" key={i}>
                                <p>{input}:</p>
                                <ActionInput
                                    placeholder={`${input} value...`}
                                    value={inputVal[i] ? inputVal[i] : ""}
                                    onChange={(e) => {
                                        const val = e.target.value
                                        setInputVal(prev => {
                                            const newArr = [...prev]
                                            newArr[i] = val
                                            return newArr
                                        })
                                    }}
                                    id={`input-${i}-action-${name}`}
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") exec()
                                    }}
                                />
                            </div>
                        )
                    }) :
                    <p className="no-inputs">No Inputs</p>
                }
            </div>
        </div>
    )
}