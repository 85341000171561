// Generated from KuneiformLexer.g4 by ANTLR 4.12.0
// jshint ignore: start
import antlr4 from 'antlr4';


const serializedATN = [4,0,85,626,6,-1,2,0,7,0,2,1,7,1,2,2,7,2,2,3,7,3,2,
4,7,4,2,5,7,5,2,6,7,6,2,7,7,7,2,8,7,8,2,9,7,9,2,10,7,10,2,11,7,11,2,12,7,
12,2,13,7,13,2,14,7,14,2,15,7,15,2,16,7,16,2,17,7,17,2,18,7,18,2,19,7,19,
2,20,7,20,2,21,7,21,2,22,7,22,2,23,7,23,2,24,7,24,2,25,7,25,2,26,7,26,2,
27,7,27,2,28,7,28,2,29,7,29,2,30,7,30,2,31,7,31,2,32,7,32,2,33,7,33,2,34,
7,34,2,35,7,35,2,36,7,36,2,37,7,37,2,38,7,38,2,39,7,39,2,40,7,40,2,41,7,
41,2,42,7,42,2,43,7,43,2,44,7,44,2,45,7,45,2,46,7,46,2,47,7,47,2,48,7,48,
2,49,7,49,2,50,7,50,2,51,7,51,2,52,7,52,2,53,7,53,2,54,7,54,2,55,7,55,2,
56,7,56,2,57,7,57,2,58,7,58,2,59,7,59,2,60,7,60,2,61,7,61,2,62,7,62,2,63,
7,63,2,64,7,64,2,65,7,65,2,66,7,66,2,67,7,67,2,68,7,68,2,69,7,69,2,70,7,
70,2,71,7,71,2,72,7,72,2,73,7,73,2,74,7,74,2,75,7,75,2,76,7,76,2,77,7,77,
2,78,7,78,2,79,7,79,2,80,7,80,2,81,7,81,2,82,7,82,2,83,7,83,2,84,7,84,2,
85,7,85,2,86,7,86,2,87,7,87,2,88,7,88,1,0,1,0,1,1,1,1,1,2,1,2,1,3,1,3,1,
4,1,4,1,5,1,5,1,6,1,6,1,7,1,7,1,8,1,8,1,9,1,9,1,10,1,10,1,11,1,11,1,12,1,
12,1,13,1,13,1,14,1,14,1,15,1,15,1,16,1,16,1,17,1,17,1,18,1,18,1,18,1,19,
1,19,1,19,1,20,1,20,1,20,1,21,1,21,1,22,1,22,1,23,1,23,1,23,1,24,1,24,1,
25,1,25,1,25,1,26,1,26,1,27,1,27,1,27,1,28,1,28,1,28,1,29,1,29,1,29,1,30,
1,30,1,30,1,30,1,30,1,30,1,30,1,30,1,30,1,31,1,31,1,31,1,31,1,32,1,32,1,
32,1,33,1,33,1,33,1,33,1,33,1,33,1,34,1,34,1,34,1,34,1,34,1,34,1,34,1,35,
1,35,1,35,1,35,1,35,1,36,1,36,1,36,1,36,1,36,1,36,1,36,1,37,1,37,1,37,1,
37,1,37,1,37,1,37,1,37,1,38,1,38,1,38,1,38,1,38,1,39,1,39,1,39,1,39,1,39,
1,39,1,39,1,39,1,39,1,40,1,40,1,40,1,40,1,40,1,40,1,41,1,41,1,41,1,41,1,
42,1,42,1,42,1,42,1,42,1,43,1,43,1,43,1,43,1,43,1,44,1,44,1,44,1,44,1,45,
1,45,1,45,1,45,1,46,1,46,1,46,1,46,1,46,1,46,1,46,1,47,1,47,1,47,1,47,1,
47,1,47,1,47,1,48,1,48,1,48,1,48,1,48,1,48,1,48,1,48,1,49,1,49,1,49,1,49,
1,49,1,49,1,49,1,49,1,50,1,50,1,50,1,50,1,50,1,50,1,50,1,50,1,51,1,51,1,
51,1,51,1,51,1,51,1,51,1,52,1,52,1,52,1,52,1,52,1,52,1,53,1,53,1,53,1,53,
1,53,1,53,1,53,1,53,1,53,1,53,1,53,1,53,1,54,1,54,1,54,1,55,1,55,1,55,1,
55,1,55,1,55,1,55,1,55,1,55,1,55,1,55,1,56,1,56,1,56,1,56,1,57,1,57,1,57,
1,57,1,57,1,57,1,57,1,57,1,57,1,57,1,58,1,58,1,58,1,58,1,58,1,58,1,58,1,
58,1,58,1,58,1,59,1,59,1,59,1,60,1,60,1,60,1,60,1,60,1,60,1,60,1,60,1,60,
1,60,1,61,1,61,1,61,1,61,1,61,1,61,1,61,1,61,1,62,1,62,1,62,1,62,1,62,1,
62,1,62,1,62,1,62,1,63,1,63,1,63,1,63,1,63,1,63,1,63,1,63,1,63,1,63,1,63,
1,63,1,64,1,64,1,64,1,64,1,64,1,64,1,64,1,64,1,64,1,65,1,65,1,65,1,65,1,
65,1,65,1,65,1,66,1,66,1,66,1,66,1,66,1,66,1,66,1,67,1,67,1,67,1,67,1,67,
1,67,1,67,1,68,1,68,1,68,1,68,1,68,1,68,1,68,1,69,1,69,1,69,1,69,1,69,1,
70,1,70,1,70,1,70,1,71,1,71,1,71,1,71,1,72,1,72,1,72,1,73,1,73,5,73,537,
8,73,10,73,12,73,540,9,73,1,74,1,74,1,74,1,75,1,75,1,75,1,76,1,76,1,76,1,
77,4,77,552,8,77,11,77,12,77,553,1,78,1,78,1,79,1,79,1,79,1,79,1,79,3,79,
563,8,79,1,80,1,80,4,80,567,8,80,11,80,12,80,568,1,80,4,80,572,8,80,11,80,
12,80,573,1,81,1,81,1,81,1,81,1,82,1,82,1,82,1,82,1,83,1,83,1,83,1,83,5,
83,588,8,83,10,83,12,83,591,9,83,1,83,1,83,1,83,1,83,1,83,1,84,1,84,1,84,
1,84,5,84,602,8,84,10,84,12,84,605,9,84,1,84,1,84,1,85,1,85,1,86,1,86,1,
87,1,87,1,87,3,87,616,8,87,1,88,1,88,5,88,620,8,88,10,88,12,88,623,9,88,
1,88,1,88,1,589,0,89,1,1,3,2,5,3,7,4,9,5,11,6,13,7,15,8,17,9,19,10,21,11,
23,12,25,13,27,14,29,15,31,16,33,17,35,18,37,19,39,20,41,21,43,22,45,23,
47,24,49,25,51,26,53,27,55,28,57,29,59,30,61,31,63,32,65,33,67,34,69,35,
71,36,73,37,75,38,77,39,79,40,81,41,83,42,85,43,87,44,89,45,91,46,93,47,
95,48,97,49,99,50,101,51,103,52,105,53,107,54,109,55,111,56,113,57,115,58,
117,59,119,60,121,61,123,62,125,63,127,64,129,65,131,66,133,67,135,68,137,
69,139,70,141,71,143,72,145,73,147,74,149,75,151,76,153,77,155,78,157,79,
159,80,161,81,163,82,165,83,167,84,169,85,171,0,173,0,175,0,177,0,1,0,22,
2,0,83,83,115,115,2,0,69,69,101,101,2,0,76,76,108,108,2,0,67,67,99,99,2,
0,84,84,116,116,2,0,73,73,105,105,2,0,78,78,110,110,2,0,82,82,114,114,2,
0,85,85,117,117,2,0,80,80,112,112,2,0,68,68,100,100,2,0,65,65,97,97,2,0,
87,87,119,119,2,0,72,72,104,104,2,0,65,90,97,122,4,0,48,57,65,90,95,95,97,
122,2,0,59,59,125,125,2,0,9,9,32,32,2,0,10,10,13,13,3,0,9,10,13,13,32,32,
1,0,48,57,4,0,10,10,13,13,39,39,92,92,633,0,1,1,0,0,0,0,3,1,0,0,0,0,5,1,
0,0,0,0,7,1,0,0,0,0,9,1,0,0,0,0,11,1,0,0,0,0,13,1,0,0,0,0,15,1,0,0,0,0,17,
1,0,0,0,0,19,1,0,0,0,0,21,1,0,0,0,0,23,1,0,0,0,0,25,1,0,0,0,0,27,1,0,0,0,
0,29,1,0,0,0,0,31,1,0,0,0,0,33,1,0,0,0,0,35,1,0,0,0,0,37,1,0,0,0,0,39,1,
0,0,0,0,41,1,0,0,0,0,43,1,0,0,0,0,45,1,0,0,0,0,47,1,0,0,0,0,49,1,0,0,0,0,
51,1,0,0,0,0,53,1,0,0,0,0,55,1,0,0,0,0,57,1,0,0,0,0,59,1,0,0,0,0,61,1,0,
0,0,0,63,1,0,0,0,0,65,1,0,0,0,0,67,1,0,0,0,0,69,1,0,0,0,0,71,1,0,0,0,0,73,
1,0,0,0,0,75,1,0,0,0,0,77,1,0,0,0,0,79,1,0,0,0,0,81,1,0,0,0,0,83,1,0,0,0,
0,85,1,0,0,0,0,87,1,0,0,0,0,89,1,0,0,0,0,91,1,0,0,0,0,93,1,0,0,0,0,95,1,
0,0,0,0,97,1,0,0,0,0,99,1,0,0,0,0,101,1,0,0,0,0,103,1,0,0,0,0,105,1,0,0,
0,0,107,1,0,0,0,0,109,1,0,0,0,0,111,1,0,0,0,0,113,1,0,0,0,0,115,1,0,0,0,
0,117,1,0,0,0,0,119,1,0,0,0,0,121,1,0,0,0,0,123,1,0,0,0,0,125,1,0,0,0,0,
127,1,0,0,0,0,129,1,0,0,0,0,131,1,0,0,0,0,133,1,0,0,0,0,135,1,0,0,0,0,137,
1,0,0,0,0,139,1,0,0,0,0,141,1,0,0,0,0,143,1,0,0,0,0,145,1,0,0,0,0,147,1,
0,0,0,0,149,1,0,0,0,0,151,1,0,0,0,0,153,1,0,0,0,0,155,1,0,0,0,0,157,1,0,
0,0,0,159,1,0,0,0,0,161,1,0,0,0,0,163,1,0,0,0,0,165,1,0,0,0,0,167,1,0,0,
0,0,169,1,0,0,0,1,179,1,0,0,0,3,181,1,0,0,0,5,183,1,0,0,0,7,185,1,0,0,0,
9,187,1,0,0,0,11,189,1,0,0,0,13,191,1,0,0,0,15,193,1,0,0,0,17,195,1,0,0,
0,19,197,1,0,0,0,21,199,1,0,0,0,23,201,1,0,0,0,25,203,1,0,0,0,27,205,1,0,
0,0,29,207,1,0,0,0,31,209,1,0,0,0,33,211,1,0,0,0,35,213,1,0,0,0,37,215,1,
0,0,0,39,218,1,0,0,0,41,221,1,0,0,0,43,224,1,0,0,0,45,226,1,0,0,0,47,228,
1,0,0,0,49,231,1,0,0,0,51,233,1,0,0,0,53,236,1,0,0,0,55,238,1,0,0,0,57,241,
1,0,0,0,59,244,1,0,0,0,61,247,1,0,0,0,63,256,1,0,0,0,65,260,1,0,0,0,67,263,
1,0,0,0,69,269,1,0,0,0,71,276,1,0,0,0,73,281,1,0,0,0,75,288,1,0,0,0,77,296,
1,0,0,0,79,301,1,0,0,0,81,310,1,0,0,0,83,316,1,0,0,0,85,320,1,0,0,0,87,325,
1,0,0,0,89,330,1,0,0,0,91,334,1,0,0,0,93,338,1,0,0,0,95,345,1,0,0,0,97,352,
1,0,0,0,99,360,1,0,0,0,101,368,1,0,0,0,103,376,1,0,0,0,105,383,1,0,0,0,107,
389,1,0,0,0,109,401,1,0,0,0,111,404,1,0,0,0,113,415,1,0,0,0,115,419,1,0,
0,0,117,429,1,0,0,0,119,439,1,0,0,0,121,442,1,0,0,0,123,452,1,0,0,0,125,
460,1,0,0,0,127,469,1,0,0,0,129,481,1,0,0,0,131,490,1,0,0,0,133,497,1,0,
0,0,135,504,1,0,0,0,137,511,1,0,0,0,139,518,1,0,0,0,141,523,1,0,0,0,143,
527,1,0,0,0,145,531,1,0,0,0,147,534,1,0,0,0,149,541,1,0,0,0,151,544,1,0,
0,0,153,547,1,0,0,0,155,551,1,0,0,0,157,555,1,0,0,0,159,562,1,0,0,0,161,
564,1,0,0,0,163,575,1,0,0,0,165,579,1,0,0,0,167,583,1,0,0,0,169,597,1,0,
0,0,171,608,1,0,0,0,173,610,1,0,0,0,175,615,1,0,0,0,177,617,1,0,0,0,179,
180,5,58,0,0,180,2,1,0,0,0,181,182,5,59,0,0,182,4,1,0,0,0,183,184,5,40,0,
0,184,6,1,0,0,0,185,186,5,123,0,0,186,8,1,0,0,0,187,188,5,41,0,0,188,10,
1,0,0,0,189,190,5,125,0,0,190,12,1,0,0,0,191,192,5,44,0,0,192,14,1,0,0,0,
193,194,5,36,0,0,194,16,1,0,0,0,195,196,5,35,0,0,196,18,1,0,0,0,197,198,
5,64,0,0,198,20,1,0,0,0,199,200,5,46,0,0,200,22,1,0,0,0,201,202,5,61,0,0,
202,24,1,0,0,0,203,204,5,43,0,0,204,26,1,0,0,0,205,206,5,45,0,0,206,28,1,
0,0,0,207,208,5,42,0,0,208,30,1,0,0,0,209,210,5,47,0,0,210,32,1,0,0,0,211,
212,5,37,0,0,212,34,1,0,0,0,213,214,5,126,0,0,214,36,1,0,0,0,215,216,5,124,
0,0,216,217,5,124,0,0,217,38,1,0,0,0,218,219,5,60,0,0,219,220,5,60,0,0,220,
40,1,0,0,0,221,222,5,62,0,0,222,223,5,62,0,0,223,42,1,0,0,0,224,225,5,38,
0,0,225,44,1,0,0,0,226,227,5,124,0,0,227,46,1,0,0,0,228,229,5,61,0,0,229,
230,5,61,0,0,230,48,1,0,0,0,231,232,5,60,0,0,232,50,1,0,0,0,233,234,5,60,
0,0,234,235,5,61,0,0,235,52,1,0,0,0,236,237,5,62,0,0,237,54,1,0,0,0,238,
239,5,62,0,0,239,240,5,61,0,0,240,56,1,0,0,0,241,242,5,33,0,0,242,243,5,
61,0,0,243,58,1,0,0,0,244,245,5,60,0,0,245,246,5,62,0,0,246,60,1,0,0,0,247,
248,5,100,0,0,248,249,5,97,0,0,249,250,5,116,0,0,250,251,5,97,0,0,251,252,
5,98,0,0,252,253,5,97,0,0,253,254,5,115,0,0,254,255,5,101,0,0,255,62,1,0,
0,0,256,257,5,117,0,0,257,258,5,115,0,0,258,259,5,101,0,0,259,64,1,0,0,0,
260,261,5,97,0,0,261,262,5,115,0,0,262,66,1,0,0,0,263,264,5,116,0,0,264,
265,5,97,0,0,265,266,5,98,0,0,266,267,5,108,0,0,267,268,5,101,0,0,268,68,
1,0,0,0,269,270,5,97,0,0,270,271,5,99,0,0,271,272,5,116,0,0,272,273,5,105,
0,0,273,274,5,111,0,0,274,275,5,110,0,0,275,70,1,0,0,0,276,277,5,105,0,0,
277,278,5,110,0,0,278,279,5,105,0,0,279,280,5,116,0,0,280,72,1,0,0,0,281,
282,5,112,0,0,282,283,5,117,0,0,283,284,5,98,0,0,284,285,5,108,0,0,285,286,
5,105,0,0,286,287,5,99,0,0,287,74,1,0,0,0,288,289,5,112,0,0,289,290,5,114,
0,0,290,291,5,105,0,0,291,292,5,118,0,0,292,293,5,97,0,0,293,294,5,116,0,
0,294,295,5,101,0,0,295,76,1,0,0,0,296,297,5,118,0,0,297,298,5,105,0,0,298,
299,5,101,0,0,299,300,5,119,0,0,300,78,1,0,0,0,301,302,5,109,0,0,302,303,
5,117,0,0,303,304,5,115,0,0,304,305,5,116,0,0,305,306,5,115,0,0,306,307,
5,105,0,0,307,308,5,103,0,0,308,309,5,110,0,0,309,80,1,0,0,0,310,311,5,111,
0,0,311,312,5,119,0,0,312,313,5,110,0,0,313,314,5,101,0,0,314,315,5,114,
0,0,315,82,1,0,0,0,316,317,5,105,0,0,317,318,5,110,0,0,318,319,5,116,0,0,
319,84,1,0,0,0,320,321,5,116,0,0,321,322,5,101,0,0,322,323,5,120,0,0,323,
324,5,116,0,0,324,86,1,0,0,0,325,326,5,98,0,0,326,327,5,108,0,0,327,328,
5,111,0,0,328,329,5,98,0,0,329,88,1,0,0,0,330,331,5,109,0,0,331,332,5,105,
0,0,332,333,5,110,0,0,333,90,1,0,0,0,334,335,5,109,0,0,335,336,5,97,0,0,
336,337,5,120,0,0,337,92,1,0,0,0,338,339,5,109,0,0,339,340,5,105,0,0,340,
341,5,110,0,0,341,342,5,108,0,0,342,343,5,101,0,0,343,344,5,110,0,0,344,
94,1,0,0,0,345,346,5,109,0,0,346,347,5,97,0,0,347,348,5,120,0,0,348,349,
5,108,0,0,349,350,5,101,0,0,350,351,5,110,0,0,351,96,1,0,0,0,352,353,5,110,
0,0,353,354,5,111,0,0,354,355,5,116,0,0,355,356,5,110,0,0,356,357,5,117,
0,0,357,358,5,108,0,0,358,359,5,108,0,0,359,98,1,0,0,0,360,361,5,112,0,0,
361,362,5,114,0,0,362,363,5,105,0,0,363,364,5,109,0,0,364,365,5,97,0,0,365,
366,5,114,0,0,366,367,5,121,0,0,367,100,1,0,0,0,368,369,5,100,0,0,369,370,
5,101,0,0,370,371,5,102,0,0,371,372,5,97,0,0,372,373,5,117,0,0,373,374,5,
108,0,0,374,375,5,116,0,0,375,102,1,0,0,0,376,377,5,117,0,0,377,378,5,110,
0,0,378,379,5,105,0,0,379,380,5,113,0,0,380,381,5,117,0,0,381,382,5,101,
0,0,382,104,1,0,0,0,383,384,5,105,0,0,384,385,5,110,0,0,385,386,5,100,0,
0,386,387,5,101,0,0,387,388,5,120,0,0,388,106,1,0,0,0,389,390,5,102,0,0,
390,391,5,111,0,0,391,392,5,114,0,0,392,393,5,101,0,0,393,394,5,105,0,0,
394,395,5,103,0,0,395,396,5,110,0,0,396,397,5,95,0,0,397,398,5,107,0,0,398,
399,5,101,0,0,399,400,5,121,0,0,400,108,1,0,0,0,401,402,5,102,0,0,402,403,
5,107,0,0,403,110,1,0,0,0,404,405,5,114,0,0,405,406,5,101,0,0,406,407,5,
102,0,0,407,408,5,101,0,0,408,409,5,114,0,0,409,410,5,101,0,0,410,411,5,
110,0,0,411,412,5,99,0,0,412,413,5,101,0,0,413,414,5,115,0,0,414,112,1,0,
0,0,415,416,5,114,0,0,416,417,5,101,0,0,417,418,5,102,0,0,418,114,1,0,0,
0,419,420,5,111,0,0,420,421,5,110,0,0,421,422,5,95,0,0,422,423,5,117,0,0,
423,424,5,112,0,0,424,425,5,100,0,0,425,426,5,97,0,0,426,427,5,116,0,0,427,
428,5,101,0,0,428,116,1,0,0,0,429,430,5,111,0,0,430,431,5,110,0,0,431,432,
5,95,0,0,432,433,5,100,0,0,433,434,5,101,0,0,434,435,5,108,0,0,435,436,5,
101,0,0,436,437,5,116,0,0,437,438,5,101,0,0,438,118,1,0,0,0,439,440,5,100,
0,0,440,441,5,111,0,0,441,120,1,0,0,0,442,443,5,110,0,0,443,444,5,111,0,
0,444,445,5,95,0,0,445,446,5,97,0,0,446,447,5,99,0,0,447,448,5,116,0,0,448,
449,5,105,0,0,449,450,5,111,0,0,450,451,5,110,0,0,451,122,1,0,0,0,452,453,
5,99,0,0,453,454,5,97,0,0,454,455,5,115,0,0,455,456,5,99,0,0,456,457,5,97,
0,0,457,458,5,100,0,0,458,459,5,101,0,0,459,124,1,0,0,0,460,461,5,115,0,
0,461,462,5,101,0,0,462,463,5,116,0,0,463,464,5,95,0,0,464,465,5,110,0,0,
465,466,5,117,0,0,466,467,5,108,0,0,467,468,5,108,0,0,468,126,1,0,0,0,469,
470,5,115,0,0,470,471,5,101,0,0,471,472,5,116,0,0,472,473,5,95,0,0,473,474,
5,100,0,0,474,475,5,101,0,0,475,476,5,102,0,0,476,477,5,97,0,0,477,478,5,
117,0,0,478,479,5,108,0,0,479,480,5,116,0,0,480,128,1,0,0,0,481,482,5,114,
0,0,482,483,5,101,0,0,483,484,5,115,0,0,484,485,5,116,0,0,485,486,5,114,
0,0,486,487,5,105,0,0,487,488,5,99,0,0,488,489,5,116,0,0,489,130,1,0,0,0,
490,491,7,0,0,0,491,492,7,1,0,0,492,493,7,2,0,0,493,494,7,1,0,0,494,495,
7,3,0,0,495,496,7,4,0,0,496,132,1,0,0,0,497,498,7,5,0,0,498,499,7,6,0,0,
499,500,7,0,0,0,500,501,7,1,0,0,501,502,7,7,0,0,502,503,7,4,0,0,503,134,
1,0,0,0,504,505,7,8,0,0,505,506,7,9,0,0,506,507,7,10,0,0,507,508,7,11,0,
0,508,509,7,4,0,0,509,510,7,1,0,0,510,136,1,0,0,0,511,512,7,10,0,0,512,513,
7,1,0,0,513,514,7,2,0,0,514,515,7,1,0,0,515,516,7,4,0,0,516,517,7,1,0,0,
517,138,1,0,0,0,518,519,7,12,0,0,519,520,7,5,0,0,520,521,7,4,0,0,521,522,
7,13,0,0,522,140,1,0,0,0,523,524,5,110,0,0,524,525,5,111,0,0,525,526,5,116,
0,0,526,142,1,0,0,0,527,528,5,97,0,0,528,529,5,110,0,0,529,530,5,100,0,0,
530,144,1,0,0,0,531,532,5,111,0,0,532,533,5,114,0,0,533,146,1,0,0,0,534,
538,7,14,0,0,535,537,7,15,0,0,536,535,1,0,0,0,537,540,1,0,0,0,538,536,1,
0,0,0,538,539,1,0,0,0,539,148,1,0,0,0,540,538,1,0,0,0,541,542,3,17,8,0,542,
543,3,147,73,0,543,150,1,0,0,0,544,545,3,15,7,0,545,546,3,147,73,0,546,152,
1,0,0,0,547,548,3,19,9,0,548,549,3,147,73,0,549,154,1,0,0,0,550,552,3,173,
86,0,551,550,1,0,0,0,552,553,1,0,0,0,553,551,1,0,0,0,553,554,1,0,0,0,554,
156,1,0,0,0,555,556,3,177,88,0,556,158,1,0,0,0,557,563,3,131,65,0,558,563,
3,133,66,0,559,563,3,135,67,0,560,563,3,137,68,0,561,563,3,139,69,0,562,
557,1,0,0,0,562,558,1,0,0,0,562,559,1,0,0,0,562,560,1,0,0,0,562,561,1,0,
0,0,563,160,1,0,0,0,564,566,3,159,79,0,565,567,3,171,85,0,566,565,1,0,0,
0,567,568,1,0,0,0,568,566,1,0,0,0,568,569,1,0,0,0,569,571,1,0,0,0,570,572,
8,16,0,0,571,570,1,0,0,0,572,573,1,0,0,0,573,571,1,0,0,0,573,574,1,0,0,0,
574,162,1,0,0,0,575,576,7,17,0,0,576,577,1,0,0,0,577,578,6,81,0,0,578,164,
1,0,0,0,579,580,7,18,0,0,580,581,1,0,0,0,581,582,6,82,0,0,582,166,1,0,0,
0,583,584,5,47,0,0,584,585,5,42,0,0,585,589,1,0,0,0,586,588,9,0,0,0,587,
586,1,0,0,0,588,591,1,0,0,0,589,590,1,0,0,0,589,587,1,0,0,0,590,592,1,0,
0,0,591,589,1,0,0,0,592,593,5,42,0,0,593,594,5,47,0,0,594,595,1,0,0,0,595,
596,6,83,0,0,596,168,1,0,0,0,597,598,5,47,0,0,598,599,5,47,0,0,599,603,1,
0,0,0,600,602,8,18,0,0,601,600,1,0,0,0,602,605,1,0,0,0,603,601,1,0,0,0,603,
604,1,0,0,0,604,606,1,0,0,0,605,603,1,0,0,0,606,607,6,84,0,0,607,170,1,0,
0,0,608,609,7,19,0,0,609,172,1,0,0,0,610,611,7,20,0,0,611,174,1,0,0,0,612,
616,8,21,0,0,613,614,5,92,0,0,614,616,9,0,0,0,615,612,1,0,0,0,615,613,1,
0,0,0,616,176,1,0,0,0,617,621,5,39,0,0,618,620,3,175,87,0,619,618,1,0,0,
0,620,623,1,0,0,0,621,619,1,0,0,0,621,622,1,0,0,0,622,624,1,0,0,0,623,621,
1,0,0,0,624,625,5,39,0,0,625,178,1,0,0,0,10,0,538,553,562,568,573,589,603,
615,621,1,0,1,0];


const atn = new antlr4.atn.ATNDeserializer().deserialize(serializedATN);

const decisionsToDFA = atn.decisionToState.map( (ds, index) => new antlr4.dfa.DFA(ds, index) );

export default class KuneiformLexer extends antlr4.Lexer {

    static grammarFileName = "KuneiformLexer.g4";
    static channelNames = [ "DEFAULT_TOKEN_CHANNEL", "HIDDEN" ];
	static modeNames = [ "DEFAULT_MODE" ];
	static literalNames = [ null, "':'", "';'", "'('", "'{'", "')'", "'}'", 
                         "','", "'$'", "'#'", "'@'", "'.'", "'='", "'+'", 
                         "'-'", "'*'", "'/'", "'%'", "'~'", "'||'", "'<<'", 
                         "'>>'", "'&'", "'|'", "'=='", "'<'", "'<='", "'>'", 
                         "'>='", "'!='", "'<>'", "'database'", "'use'", 
                         "'as'", "'table'", "'action'", "'init'", "'public'", 
                         "'private'", "'view'", "'mustsign'", "'owner'", 
                         "'int'", "'text'", "'blob'", "'min'", "'max'", 
                         "'minlen'", "'maxlen'", "'notnull'", "'primary'", 
                         "'default'", "'unique'", "'index'", "'foreign_key'", 
                         "'fk'", "'references'", "'ref'", "'on_update'", 
                         "'on_delete'", "'do'", "'no_action'", "'cascade'", 
                         "'set_null'", "'set_default'", "'restrict'", null, 
                         null, null, null, null, "'not'", "'and'", "'or'" ];
	static symbolicNames = [ null, "COL", "SCOL", "L_PAREN", "L_BRACE", "R_PAREN", 
                          "R_BRACE", "COMMA", "DOLLAR", "HASH", "AT", "PERIOD", 
                          "ASSIGN", "PLUS", "MINUS", "STAR", "DIV", "MOD", 
                          "TILDE", "PIPE2", "LT2", "GT2", "AMP", "PIPE", 
                          "EQ", "LT", "LT_EQ", "GT", "GT_EQ", "SQL_NOT_EQ1", 
                          "SQL_NOT_EQ2", "DATABASE_", "USE_", "AS_", "TABLE_", 
                          "ACTION_", "INIT_", "PUBLIC_", "PRIVATE_", "VIEW_", 
                          "MUSTSIGN_", "OWNER_", "INT_", "TEXT_", "BLOB_", 
                          "MIN_", "MAX_", "MIN_LEN_", "MAX_LEN_", "NOT_NULL_", 
                          "PRIMARY_", "DEFAULT_", "UNIQUE_", "INDEX_", "FOREIGN_KEY_", 
                          "FOREIGN_KEY_ABBR_", "REFERENCES_", "REFERENCES_ABBR_", 
                          "ACTION_ON_UPDATE_", "ACTION_ON_DELETE_", "ACTION_DO_", 
                          "ACTION_DO_NO_ACTION_", "ACTION_DO_CASCADE_", 
                          "ACTION_DO_SET_NULL_", "ACTION_DO_SET_DEFAULT_", 
                          "ACTION_DO_RESTRICT_", "SELECT_", "INSERT_", "UPDATE_", 
                          "DELETE_", "WITH_", "NOT_", "AND_", "OR_", "IDENTIFIER", 
                          "INDEX_NAME", "PARAM_OR_VAR", "BLOCK_VAR_OR_ANNOTATION", 
                          "UNSIGNED_NUMBER_LITERAL", "STRING_LITERAL", "SQL_KEYWORDS", 
                          "SQL_STMT", "WS", "TERMINATOR", "BLOCK_COMMENT", 
                          "LINE_COMMENT" ];
	static ruleNames = [ "COL", "SCOL", "L_PAREN", "L_BRACE", "R_PAREN", "R_BRACE", 
                      "COMMA", "DOLLAR", "HASH", "AT", "PERIOD", "ASSIGN", 
                      "PLUS", "MINUS", "STAR", "DIV", "MOD", "TILDE", "PIPE2", 
                      "LT2", "GT2", "AMP", "PIPE", "EQ", "LT", "LT_EQ", 
                      "GT", "GT_EQ", "SQL_NOT_EQ1", "SQL_NOT_EQ2", "DATABASE_", 
                      "USE_", "AS_", "TABLE_", "ACTION_", "INIT_", "PUBLIC_", 
                      "PRIVATE_", "VIEW_", "MUSTSIGN_", "OWNER_", "INT_", 
                      "TEXT_", "BLOB_", "MIN_", "MAX_", "MIN_LEN_", "MAX_LEN_", 
                      "NOT_NULL_", "PRIMARY_", "DEFAULT_", "UNIQUE_", "INDEX_", 
                      "FOREIGN_KEY_", "FOREIGN_KEY_ABBR_", "REFERENCES_", 
                      "REFERENCES_ABBR_", "ACTION_ON_UPDATE_", "ACTION_ON_DELETE_", 
                      "ACTION_DO_", "ACTION_DO_NO_ACTION_", "ACTION_DO_CASCADE_", 
                      "ACTION_DO_SET_NULL_", "ACTION_DO_SET_DEFAULT_", "ACTION_DO_RESTRICT_", 
                      "SELECT_", "INSERT_", "UPDATE_", "DELETE_", "WITH_", 
                      "NOT_", "AND_", "OR_", "IDENTIFIER", "INDEX_NAME", 
                      "PARAM_OR_VAR", "BLOCK_VAR_OR_ANNOTATION", "UNSIGNED_NUMBER_LITERAL", 
                      "STRING_LITERAL", "SQL_KEYWORDS", "SQL_STMT", "WS", 
                      "TERMINATOR", "BLOCK_COMMENT", "LINE_COMMENT", "WSNL", 
                      "DIGIT", "SINGLE_QUOTE_STRING_CHAR", "SINGLE_QUOTE_STRING" ];

    constructor(input) {
        super(input)
        this._interp = new antlr4.atn.LexerATNSimulator(this, atn, decisionsToDFA, new antlr4.atn.PredictionContextCache());
    }
}

KuneiformLexer.EOF = antlr4.Token.EOF;
KuneiformLexer.COL = 1;
KuneiformLexer.SCOL = 2;
KuneiformLexer.L_PAREN = 3;
KuneiformLexer.L_BRACE = 4;
KuneiformLexer.R_PAREN = 5;
KuneiformLexer.R_BRACE = 6;
KuneiformLexer.COMMA = 7;
KuneiformLexer.DOLLAR = 8;
KuneiformLexer.HASH = 9;
KuneiformLexer.AT = 10;
KuneiformLexer.PERIOD = 11;
KuneiformLexer.ASSIGN = 12;
KuneiformLexer.PLUS = 13;
KuneiformLexer.MINUS = 14;
KuneiformLexer.STAR = 15;
KuneiformLexer.DIV = 16;
KuneiformLexer.MOD = 17;
KuneiformLexer.TILDE = 18;
KuneiformLexer.PIPE2 = 19;
KuneiformLexer.LT2 = 20;
KuneiformLexer.GT2 = 21;
KuneiformLexer.AMP = 22;
KuneiformLexer.PIPE = 23;
KuneiformLexer.EQ = 24;
KuneiformLexer.LT = 25;
KuneiformLexer.LT_EQ = 26;
KuneiformLexer.GT = 27;
KuneiformLexer.GT_EQ = 28;
KuneiformLexer.SQL_NOT_EQ1 = 29;
KuneiformLexer.SQL_NOT_EQ2 = 30;
KuneiformLexer.DATABASE_ = 31;
KuneiformLexer.USE_ = 32;
KuneiformLexer.AS_ = 33;
KuneiformLexer.TABLE_ = 34;
KuneiformLexer.ACTION_ = 35;
KuneiformLexer.INIT_ = 36;
KuneiformLexer.PUBLIC_ = 37;
KuneiformLexer.PRIVATE_ = 38;
KuneiformLexer.VIEW_ = 39;
KuneiformLexer.MUSTSIGN_ = 40;
KuneiformLexer.OWNER_ = 41;
KuneiformLexer.INT_ = 42;
KuneiformLexer.TEXT_ = 43;
KuneiformLexer.BLOB_ = 44;
KuneiformLexer.MIN_ = 45;
KuneiformLexer.MAX_ = 46;
KuneiformLexer.MIN_LEN_ = 47;
KuneiformLexer.MAX_LEN_ = 48;
KuneiformLexer.NOT_NULL_ = 49;
KuneiformLexer.PRIMARY_ = 50;
KuneiformLexer.DEFAULT_ = 51;
KuneiformLexer.UNIQUE_ = 52;
KuneiformLexer.INDEX_ = 53;
KuneiformLexer.FOREIGN_KEY_ = 54;
KuneiformLexer.FOREIGN_KEY_ABBR_ = 55;
KuneiformLexer.REFERENCES_ = 56;
KuneiformLexer.REFERENCES_ABBR_ = 57;
KuneiformLexer.ACTION_ON_UPDATE_ = 58;
KuneiformLexer.ACTION_ON_DELETE_ = 59;
KuneiformLexer.ACTION_DO_ = 60;
KuneiformLexer.ACTION_DO_NO_ACTION_ = 61;
KuneiformLexer.ACTION_DO_CASCADE_ = 62;
KuneiformLexer.ACTION_DO_SET_NULL_ = 63;
KuneiformLexer.ACTION_DO_SET_DEFAULT_ = 64;
KuneiformLexer.ACTION_DO_RESTRICT_ = 65;
KuneiformLexer.SELECT_ = 66;
KuneiformLexer.INSERT_ = 67;
KuneiformLexer.UPDATE_ = 68;
KuneiformLexer.DELETE_ = 69;
KuneiformLexer.WITH_ = 70;
KuneiformLexer.NOT_ = 71;
KuneiformLexer.AND_ = 72;
KuneiformLexer.OR_ = 73;
KuneiformLexer.IDENTIFIER = 74;
KuneiformLexer.INDEX_NAME = 75;
KuneiformLexer.PARAM_OR_VAR = 76;
KuneiformLexer.BLOCK_VAR_OR_ANNOTATION = 77;
KuneiformLexer.UNSIGNED_NUMBER_LITERAL = 78;
KuneiformLexer.STRING_LITERAL = 79;
KuneiformLexer.SQL_KEYWORDS = 80;
KuneiformLexer.SQL_STMT = 81;
KuneiformLexer.WS = 82;
KuneiformLexer.TERMINATOR = 83;
KuneiformLexer.BLOCK_COMMENT = 84;
KuneiformLexer.LINE_COMMENT = 85;



