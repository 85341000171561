import { CharStreams, CommonTokenStream } from "antlr4"
import KuneiformLexer from "./ANTLR/KuneiformLexer"
import KuneiformParser from "./ANTLR/KuneiformParser"
import KuneiformErrorListener from "./error-manager/kuneiformErrorListener"

function parse(string) {
    const inputStream = CharStreams.fromString(string)
    const lexer = new KuneiformLexer(inputStream)
    lexer.removeErrorListeners()
    const kuneiformErrorListener = new KuneiformErrorListener()
    lexer.addErrorListener(kuneiformErrorListener)
    const tokenStream = new CommonTokenStream(lexer)
    const parser = new KuneiformParser(tokenStream)
    parser.removeErrorListeners()
    parser.addErrorListener(kuneiformErrorListener)

    // get ast and errors
    const ast = parser.source_unit()
    const errors = kuneiformErrorListener.getErrors()

    return { ast, errors }
}

export function parseAndGetAstRoot(code) {
    const { ast } = parse(code)
    return ast
}

export function parseAndGetErrors(code) {
    const { errors } = parse(code)
    return errors
}
