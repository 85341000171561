import { InputBase } from "@mui/material";
import { CompileDisabledOption, CompileFilesDropDown } from "../../../Mui/menus";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import CompileFoldersInterface from "./CompileFoldersInterface";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { arrangeFiles } from "../../../../utils/fileHelpers";
import CompileFilesInterface from "./CompileFilesInterface";

export default function CompileFilesMenu({ selectedFiles, setSelectedFiles }) {
    const [organizedFiles, setOrganizedFiles] = useState([])
    const [unOrganizedFiles, setUnOrganizedFiles] = useState([])
    const currentWorkspace = useSelector(state => state.workspaces.currentWorkspace)
    const allFiles = useSelector(state => state.filesDTO)

    useEffect(() => {
        const files = arrangeFiles(allFiles, currentWorkspace)
        setOrganizedFiles(files.organizedFileArray)
        setUnOrganizedFiles(files.unorganized)
    }, [allFiles, currentWorkspace])

    return(
        <>
            <CompileFilesDropDown
                displayEmpty
                multiple
                value={['disabled']}
                input={<InputBase />}
                IconComponent={UnfoldMoreIcon}
                MenuProps={{
                    PaperProps: {
                        style: {
                            background: "rgba(139, 139, 139, 0.9)",
                        }
                    },
                    style: { zIndex: 99 },
                }}
            >
                <CompileDisabledOption value="disabled" disabled>
                    Select Files to Compile
                </CompileDisabledOption>
                {organizedFiles.length > 0 && organizedFiles.map((folder, index) => {
                    return(
                        <CompileFoldersInterface 
                            key={index} 
                            folderName={Object.keys(folder)[0]}
                            files={folder[Object.keys(folder)[0]]}
                            selectedFiles={selectedFiles}
                            setSelectedFiles={setSelectedFiles}
                        />
                    )
                })}
                {unOrganizedFiles.length > 0 && unOrganizedFiles.map((file, index) => {
                    return(
                        <CompileFilesInterface
                            key={index}
                            file={file}
                            selectedFiles={selectedFiles}
                            setSelectedFiles={setSelectedFiles}
                        />
                    )
                })}
            </CompileFilesDropDown>
        </>
    )
}