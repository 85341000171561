import { WebKwil } from "@kwilteam/kwil-js";
import store from "../redux/store";

function getWorkspaceByName(workspaceList, workspaceName) {
    return workspaceList.find((workspace) => workspace.name === workspaceName);
}

function getCurrentWorkspace() {
    const state = store.getState();
    const currentWorkspaceName = state.workspaces.currentWorkspace;
    const workspaceList = state.workspaces.workspaceList;
    const currentWorkspace = getWorkspaceByName(workspaceList, currentWorkspaceName);
    return currentWorkspace;
}

function getProviderFromWorkspace() {
    const currentWorkspace = getCurrentWorkspace();
    return currentWorkspace ? currentWorkspace.provider : null
}

function getChaindIdFromWorkspace() {
    const currentWorkspace = getCurrentWorkspace();
    return currentWorkspace ? currentWorkspace.chainId : null
};

let kwil = new WebKwil({
    kwilProvider: getProviderFromWorkspace(),
    chainId: getChaindIdFromWorkspace(),
    logging: false,
    timeout: 10000,
})

let currentProv = getProviderFromWorkspace();

store.subscribe(() => {
    const newProvider = getProviderFromWorkspace();
    
    if (newProvider !== currentProv) {
        console.log('Provider changed, updating kwil instance.')
        currentProv = newProvider;
        kwil = new WebKwil({
            kwilProvider: newProvider,
            chainId: getChaindIdFromWorkspace(),
            logging: false,
            timeout: 10000,
        })
    }
});

// this function allows us to get a chainId from a new kwil instance.
// Because we construct the kwil instance with a provider above, the function will require the consumer to pass a provider.
export async function getChainId(provider) {
    const kwil = new WebKwil({
        kwilProvider: provider,
    })

    const res = await kwil.chainInfo();

    if(res.status !== 200 || !res.data) {
        throw new Error(`Error getting chainId`)
    }

    return res.data.chain_id;
}

export async function getAccount(address) {
    const res = await kwil.getAccount(address);

    if(res.status !== 200 || !res.data) {
        throw new Error(`Error getting account`)
    }

    return res.data;
}

export { kwil };

