const socialNetworkKf = `database social_network;
// table for storing users
// each wallet can have a user
table users {
    id int primary notnull,
    username text notnull unique minlen(5) maxlen(32),
    age int notnull max(69),
    address text notnull unique // wallet address
}
// table for storing posts
table posts {
    id int primary notnull,
    title text notnull maxlen(300),
    content text notnull maxlen(10000),
    author_id int notnull,
    post_date text notnull
}
// table to track who likes certain posts
table likes {
    user_id int notnull,
    post_id int notnull,
    #like_index primary(user_id, post_id)
}
// table for follower relationships
table followers {
    follower_id int notnull,
    followed_id int notnull,
    #follower_index primary(follower_id, followed_id)
}
// table to track user balances
table balances {
    id int primary,
    name text notnull,
    wallet text,
    balance int default(0)
}
// a public action for creating a user
action create_user ($id, $username, $age) public {
    INSERT INTO users
    VALUES ($id, $username, $age, @caller);
}
// a public action for updating mutable user data
action update_user ($username, $age) public {
    UPDATE users
    SET username=$username, age=$age
    WHERE address=@caller;
}
// a public action to add a balance
action add_balance ($id, $userid, $balance) public {
    INSERT INTO balances
    VALUES (
        $id,
        (SELECT username FROM users WHERE id = $userid),
        @caller,
        $balance
    );
}
// action for checking a users balance
action check_balance() private view {
    SELECT
        CASE
            WHEN balance < 10 THEN ERROR('insufficient balance')
            ELSE null
        END
    FROM balances WHERE wallet = @caller;
}

// action that checks that a user has a balance of 10 before allowing them to read the post
action read_post($post_id) public view {
    check_balance();
    SELECT * FROM posts WHERE id = $post_id;
}

// action for posting
// the date string must be provided by the client’s local time.  In the (near) future, we will support an @block modifier’
// date value is YYYY-MM-DD

action create_post ($id, $title, $content, $username, $date_string) public {
    INSERT INTO posts (id, title, content, author_id, post_date)
    VALUES (
        $id, 
        $title, 
        $content, 
        (SELECT id from users WHERE username = $username),
        $date_string
    );
}
// action for deleting posts posted by the caller
// it is important to use a subquery here instead of a join,
// since delete joins are not yet supported
action delete_post ($id) public {
    DELETE FROM posts
    WHERE id=$id
    AND author_id = (
        SELECT id
        FROM users
        WHERE address=@caller
    );
}
// action for liking posts
action like_post($post_id) public {
    INSERT INTO likes (user_id, post_id)
    VALUES ((
        SELECT id
        FROM users
        WHERE address = @caller
    ),
    $post_id);
}
// action for unliking a post
action unlike_post($post_id) public {
    DELETE FROM likes
    WHERE post_id=$post_id
    AND user_id = (
        SELECT id
        FROM users
        WHERE address=@caller
    );
}
// action for following a user
action follow($username) public {
    INSERT INTO followers (follower_id, followed_id)
    VALUES ((SELECT id FROM users WHERE address = @caller),
    (SELECT id FROM users WHERE username = $username));
}
// unfollow a user
// since Kwil does not support joins in deletes,
// we double subquery
action unfollow($username) public {
    DELETE FROM followers
    WHERE followers.followed_id = (SELECT id FROM users WHERE username = $username)
    AND followers.follower_id = (SELECT id FROM users WHERE address = @caller);
}
// getters
action get_user_by_username($username) public view {
    SELECT *
    FROM users
    WHERE username=$username;
}
action get_user_by_wallet($address) public view {
    SELECT *
    FROM users
    WHERE address = $address;
}`

export { socialNetworkKf }