import { InputBase, Modal } from "@mui/material";
import { CustomMenuItem, WorkspaceTemplateDropDown } from "../../../Mui/menus";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { useState } from "react";
import { WorkspaceNameInput } from "../../../Mui/textFields";
import { CancelWorkspaceButton, ConfirmWorkspaceButton } from "../../../Mui/buttons";
import { useDispatch } from "react-redux";
import { createWorkspace, selectWorkspace } from "../../../../redux/reducers/workspaces";
import { resetLoadedFiles } from "../../../../redux/reducers/loadedFiles";
import { getChainId } from "../../../../utils/webKwil";

export default function NewWorkspaceModal({ newWorkspace, setNewWorkspace }) {
    const [workspaceTemplate, setWorkspaceTemplate] = useState("")
    const [workspaceName, setWorkspaceName] = useState("")
    const [newProvider, setNewProvider] = useState("https://longhorn.kwil.com")
    const [chainId, setChainId] = useState("longhorn")

    const dispatch = useDispatch()

    function handleClose() {
        setNewWorkspace(false)
        setWorkspaceName("")
        setWorkspaceTemplate("")
    }

    async function addWorkspace(name, prov, id) {
        if(!id) {
            id = await getChainId(prov)
        }

        dispatch(createWorkspace({name: name, provider: prov, chainId: id}))
        dispatch(selectWorkspace(name))
        dispatch(resetLoadedFiles())
        handleClose()
    }

    return(
        <Modal
            open={newWorkspace}
            onClose={() => handleClose()}
            sx={{ diplay: "flex" }}
        >
            <div className="new-workspace-modal">
                <h3>New Workspace</h3>
                <div className="modal-divider" />
                <p>Choose Template:</p>
                <WorkspaceTemplateDropDown
                    displayEmpty
                    input={<InputBase />}
                    IconComponent={UnfoldMoreIcon}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                background: "#3B3B3A",
                            }
                        }
                    }}
                    value={workspaceTemplate}
                    onChange={(e) => setWorkspaceTemplate(e.target.value)}
                >
                    <CustomMenuItem value="">Default</CustomMenuItem>
                </WorkspaceTemplateDropDown>
                <p>Workspace Name:</p>
                <WorkspaceNameInput 
                    value={workspaceName}
                    onChange={(e) => setWorkspaceName(e.target.value)}
                    placeholder="Workspace Name"
                    id="workspace-name-input"
                />
                <p>Kwil Provider</p>
                <WorkspaceNameInput
                    id="kwil-provider-input"
                    placeholder="Kwil Provider"
                    value={newProvider}
                    onChange={(e) => setNewProvider(e.target.value)}
                />
                <p>Chain ID</p>
                <WorkspaceNameInput 
                    id="chain-id-input"
                    placeholder="Chain ID"
                    value={chainId}
                    onChange={(e) => setChainId(e.target.value)}
                />
                <div className="modal-buttons">
                    <ConfirmWorkspaceButton
                        onClick={() => addWorkspace(workspaceName, newProvider, chainId)}
                    >
                        OK
                    </ConfirmWorkspaceButton>
                    <CancelWorkspaceButton
                        onClick={() => handleClose()}
                    >
                        Cancel
                    </CancelWorkspaceButton>
                </div>
            </div>
        </Modal>
    )
}
