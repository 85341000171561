
import * as monaco from "monaco-editor-core";
import { languageExtensionPoint, languageID } from "./config";
import { customTheme, baseMonarchLanguage } from "./styling/kuneiformStyling";
import { completeActions, completeExtensions, completeParamList, completeTables, isDbDefined } from "./completion-manager/completion";
import { dbDeclaration, kuneiformActionSuggestions, kuneiformDefaults, kuneiformTableSuggestions } from "./completion-manager/kuneiformSuggestions";
import { parseAndGetErrors } from "./parser";

export function setupEditor(divNode, currentCode) {
    setupLanguage();

    return monaco.editor.create(divNode, {
        value: currentCode || '//Type your code here...',
        language: languageID,
        theme: 'kuneiformTheme',
        autoIndent: true
    })
}

function setupLanguage() {
    monaco.editor.defineTheme('kuneiformTheme', customTheme);

    monaco.languages.register(languageExtensionPoint);
    monaco.languages.onLanguage(languageID, () => {
        monaco.languages.setMonarchTokensProvider(languageID, baseMonarchLanguage);
    });

    monaco.languages.setLanguageConfiguration(languageID, {
        wordPattern: /(-?\d*\.\d\w*)|([^`~!#%^&*()=+\[\]{}\\|;:'",.<>/?\s]+)/g
    });
}

export function setupMonacoCompletionProvider(completeDataRef) {
    return monaco.languages.registerCompletionItemProvider(languageID, {
        provideCompletionItems: (model, position) => generateCompletionItems(completeDataRef, model, position),
        triggerCharacters: ['~']
    })
};

function generateCompletionItems(completeDataRef, model, position) {
    const word = model.getWordUntilPosition(position);
    const range = {
        startLineNumber: position.lineNumber,
        endLineNumber: position.lineNumber,
        startColumn: word.startColumn,
        endColumn: word.endColumn,
    };

    const suggestionKeys = [
        'tables',
        'actions',
        'params',
        'kfDefault',
        'tableDefault',
        'actionDefault',
        'dbDeclaration',
        'extensionList'
    ]

    const suggestions = suggestionKeys.flatMap(key => {
        return (completeDataRef.current[key] || []).map(suggestion => ({ ...suggestion, range }));
    })

    return { suggestions };
}

export function updateCompletionItems(editor, value, isInAction, isInTable) {
    const position = editor.getPosition()
    const offset = editor.getModel()?.getOffsetAt(position)

    const tables = completeTables(value)
    const actions = completeActions(value)
    const params = completeParamList(value, offset)
    const dbDefined = isDbDefined(value)
    const extensions = completeExtensions(value)

    return {
        tables: tables,
        actions: actions,
        params: params,
        extensionList: extensions,
        kfDefault: !isInAction && !isInTable && dbDefined ? kuneiformDefaults : [],
        tableDefault: isInTable ? kuneiformTableSuggestions : [],
        actionDefault: isInAction ? kuneiformActionSuggestions : [],
        dbDeclaration: !dbDefined ? dbDeclaration : [],
    };
}

export function triggerErrors(editor, value) {
    const errorMarkers = parseAndGetErrors(value)
    monaco.editor.setModelMarkers(editor.getModel(), languageID, errorMarkers)
}

export function setupResizeHandler(editor) {
    function handleResize() {
        editor.layout();
    }
    window.addEventListener('resize', handleResize);
    return handleResize;
}
