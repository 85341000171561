// Generated from KuneiformParser.g4 by ANTLR 4.12.0
// jshint ignore: start
import antlr4 from 'antlr4';
import KuneiformParserVisitor from './KuneiformParserVisitor.js';

const serializedATN = [4,1,85,457,2,0,7,0,2,1,7,1,2,2,7,2,2,3,7,3,2,4,7,
4,2,5,7,5,2,6,7,6,2,7,7,7,2,8,7,8,2,9,7,9,2,10,7,10,2,11,7,11,2,12,7,12,
2,13,7,13,2,14,7,14,2,15,7,15,2,16,7,16,2,17,7,17,2,18,7,18,2,19,7,19,2,
20,7,20,2,21,7,21,2,22,7,22,2,23,7,23,2,24,7,24,2,25,7,25,2,26,7,26,2,27,
7,27,2,28,7,28,2,29,7,29,2,30,7,30,2,31,7,31,2,32,7,32,2,33,7,33,2,34,7,
34,2,35,7,35,2,36,7,36,2,37,7,37,2,38,7,38,2,39,7,39,2,40,7,40,2,41,7,41,
2,42,7,42,2,43,7,43,2,44,7,44,2,45,7,45,2,46,7,46,2,47,7,47,2,48,7,48,2,
49,7,49,2,50,7,50,1,0,1,0,1,0,5,0,106,8,0,10,0,12,0,109,9,0,1,0,1,0,1,0,
5,0,114,8,0,10,0,12,0,117,9,0,1,0,1,0,1,1,1,1,1,1,1,2,1,2,1,2,1,2,1,2,1,
2,3,2,130,8,2,1,2,1,2,1,2,1,2,1,3,1,3,1,3,5,3,139,8,3,10,3,12,3,142,9,3,
1,4,1,4,1,4,1,4,1,5,1,5,1,5,1,5,1,5,1,6,1,6,1,6,5,6,156,8,6,10,6,12,6,159,
9,6,1,7,1,7,1,7,1,7,1,8,1,8,1,8,1,8,1,8,1,8,1,8,3,8,172,8,8,5,8,174,8,8,
10,8,12,8,177,9,8,1,8,3,8,180,8,8,1,8,1,8,1,9,1,9,1,9,5,9,187,8,9,10,9,12,
9,190,9,9,1,10,1,10,1,10,5,10,195,8,10,10,10,12,10,198,9,10,1,11,1,11,1,
12,1,12,1,12,1,12,1,12,1,12,1,12,1,12,1,12,1,12,1,12,1,12,1,12,1,12,1,12,
1,12,1,12,1,12,1,12,1,12,1,12,1,12,1,12,1,12,1,12,1,12,1,12,1,12,3,12,230,
8,12,1,13,1,13,1,14,1,14,1,15,1,15,1,15,1,15,1,15,1,15,1,16,1,16,3,16,244,
8,16,1,16,1,16,1,17,1,17,1,17,1,17,1,17,1,17,1,17,1,17,1,17,1,17,5,17,258,
8,17,10,17,12,17,261,9,17,1,18,1,18,1,19,1,19,1,20,1,20,1,21,1,21,1,21,5,
21,272,8,21,10,21,12,21,275,9,21,1,22,5,22,278,8,22,10,22,12,22,281,9,22,
1,22,1,22,1,22,1,22,1,22,1,22,1,22,1,22,1,22,1,22,1,23,3,23,294,8,23,1,23,
1,23,5,23,298,8,23,10,23,12,23,301,9,23,1,24,1,24,1,25,1,25,1,26,1,26,1,
27,1,27,1,28,1,28,1,29,1,29,1,30,1,30,1,31,1,31,1,31,5,31,320,8,31,10,31,
12,31,323,9,31,1,32,1,32,1,33,1,33,1,34,1,34,1,35,1,35,1,36,1,36,1,37,1,
37,1,37,1,37,1,37,1,37,1,37,1,38,4,38,343,8,38,11,38,12,38,344,1,39,1,39,
3,39,349,8,39,1,40,1,40,1,40,1,41,1,41,1,41,3,41,357,8,41,1,41,1,41,1,41,
1,42,1,42,1,42,5,42,365,8,42,10,42,12,42,368,9,42,1,43,1,43,1,43,1,43,1,
43,1,44,1,44,1,45,1,45,1,46,1,46,1,46,1,46,1,47,1,47,3,47,385,8,47,1,48,
1,48,1,49,3,49,390,8,49,1,49,1,49,5,49,394,8,49,10,49,12,49,397,9,49,1,50,
1,50,1,50,1,50,1,50,1,50,1,50,1,50,1,50,1,50,1,50,1,50,1,50,1,50,1,50,1,
50,1,50,5,50,416,8,50,10,50,12,50,419,9,50,1,50,3,50,422,8,50,1,50,1,50,
3,50,426,8,50,1,50,1,50,1,50,1,50,1,50,1,50,1,50,1,50,1,50,1,50,1,50,1,50,
1,50,1,50,1,50,1,50,1,50,1,50,1,50,1,50,1,50,1,50,1,50,1,50,5,50,452,8,50,
10,50,12,50,455,9,50,1,50,0,1,100,51,0,2,4,6,8,10,12,14,16,18,20,22,24,26,
28,30,32,34,36,38,40,42,44,46,48,50,52,54,56,58,60,62,64,66,68,70,72,74,
76,78,80,82,84,86,88,90,92,94,96,98,100,0,15,1,0,42,44,1,0,78,79,2,0,50,
50,52,53,1,0,58,59,1,0,61,65,1,0,54,55,1,0,56,57,1,0,37,38,1,0,40,41,2,0,
13,14,18,18,1,0,15,17,1,0,13,14,1,0,20,23,1,0,25,28,3,0,12,12,24,24,29,30,
457,0,102,1,0,0,0,2,120,1,0,0,0,4,123,1,0,0,0,6,135,1,0,0,0,8,143,1,0,0,
0,10,147,1,0,0,0,12,152,1,0,0,0,14,160,1,0,0,0,16,164,1,0,0,0,18,183,1,0,
0,0,20,191,1,0,0,0,22,199,1,0,0,0,24,229,1,0,0,0,26,231,1,0,0,0,28,233,1,
0,0,0,30,235,1,0,0,0,32,241,1,0,0,0,34,247,1,0,0,0,36,262,1,0,0,0,38,264,
1,0,0,0,40,266,1,0,0,0,42,273,1,0,0,0,44,279,1,0,0,0,46,293,1,0,0,0,48,302,
1,0,0,0,50,304,1,0,0,0,52,306,1,0,0,0,54,308,1,0,0,0,56,310,1,0,0,0,58,312,
1,0,0,0,60,314,1,0,0,0,62,316,1,0,0,0,64,324,1,0,0,0,66,326,1,0,0,0,68,328,
1,0,0,0,70,330,1,0,0,0,72,332,1,0,0,0,74,334,1,0,0,0,76,342,1,0,0,0,78,348,
1,0,0,0,80,350,1,0,0,0,82,356,1,0,0,0,84,361,1,0,0,0,86,369,1,0,0,0,88,374,
1,0,0,0,90,376,1,0,0,0,92,378,1,0,0,0,94,384,1,0,0,0,96,386,1,0,0,0,98,389,
1,0,0,0,100,425,1,0,0,0,102,103,3,2,1,0,103,107,5,2,0,0,104,106,3,4,2,0,
105,104,1,0,0,0,106,109,1,0,0,0,107,105,1,0,0,0,107,108,1,0,0,0,108,115,
1,0,0,0,109,107,1,0,0,0,110,114,3,16,8,0,111,114,3,44,22,0,112,114,3,74,
37,0,113,110,1,0,0,0,113,111,1,0,0,0,113,112,1,0,0,0,114,117,1,0,0,0,115,
113,1,0,0,0,115,116,1,0,0,0,116,118,1,0,0,0,117,115,1,0,0,0,118,119,5,0,
0,1,119,1,1,0,0,0,120,121,5,31,0,0,121,122,3,50,25,0,122,3,1,0,0,0,123,124,
5,32,0,0,124,129,3,52,26,0,125,126,5,4,0,0,126,127,3,6,3,0,127,128,5,6,0,
0,128,130,1,0,0,0,129,125,1,0,0,0,129,130,1,0,0,0,130,131,1,0,0,0,131,132,
5,33,0,0,132,133,3,52,26,0,133,134,5,2,0,0,134,5,1,0,0,0,135,140,3,8,4,0,
136,137,5,7,0,0,137,139,3,8,4,0,138,136,1,0,0,0,139,142,1,0,0,0,140,138,
1,0,0,0,140,141,1,0,0,0,141,7,1,0,0,0,142,140,1,0,0,0,143,144,3,54,27,0,
144,145,5,1,0,0,145,146,3,72,36,0,146,9,1,0,0,0,147,148,3,66,33,0,148,149,
5,3,0,0,149,150,3,12,6,0,150,151,5,5,0,0,151,11,1,0,0,0,152,157,3,14,7,0,
153,154,5,7,0,0,154,156,3,14,7,0,155,153,1,0,0,0,156,159,1,0,0,0,157,155,
1,0,0,0,157,158,1,0,0,0,158,13,1,0,0,0,159,157,1,0,0,0,160,161,3,68,34,0,
161,162,5,12,0,0,162,163,3,70,35,0,163,15,1,0,0,0,164,165,5,34,0,0,165,166,
3,56,28,0,166,167,5,4,0,0,167,175,3,20,10,0,168,171,5,7,0,0,169,172,3,30,
15,0,170,172,3,34,17,0,171,169,1,0,0,0,171,170,1,0,0,0,172,174,1,0,0,0,173,
168,1,0,0,0,174,177,1,0,0,0,175,173,1,0,0,0,175,176,1,0,0,0,176,179,1,0,
0,0,177,175,1,0,0,0,178,180,5,7,0,0,179,178,1,0,0,0,179,180,1,0,0,0,180,
181,1,0,0,0,181,182,5,6,0,0,182,17,1,0,0,0,183,184,3,60,30,0,184,188,3,22,
11,0,185,187,3,24,12,0,186,185,1,0,0,0,187,190,1,0,0,0,188,186,1,0,0,0,188,
189,1,0,0,0,189,19,1,0,0,0,190,188,1,0,0,0,191,196,3,18,9,0,192,193,5,7,
0,0,193,195,3,18,9,0,194,192,1,0,0,0,195,198,1,0,0,0,196,194,1,0,0,0,196,
197,1,0,0,0,197,21,1,0,0,0,198,196,1,0,0,0,199,200,7,0,0,0,200,23,1,0,0,
0,201,230,5,50,0,0,202,230,5,49,0,0,203,230,5,52,0,0,204,205,5,51,0,0,205,
206,5,3,0,0,206,207,3,26,13,0,207,208,5,5,0,0,208,230,1,0,0,0,209,210,5,
45,0,0,210,211,5,3,0,0,211,212,3,28,14,0,212,213,5,5,0,0,213,230,1,0,0,0,
214,215,5,46,0,0,215,216,5,3,0,0,216,217,3,28,14,0,217,218,5,5,0,0,218,230,
1,0,0,0,219,220,5,47,0,0,220,221,5,3,0,0,221,222,3,28,14,0,222,223,5,5,0,
0,223,230,1,0,0,0,224,225,5,48,0,0,225,226,5,3,0,0,226,227,3,28,14,0,227,
228,5,5,0,0,228,230,1,0,0,0,229,201,1,0,0,0,229,202,1,0,0,0,229,203,1,0,
0,0,229,204,1,0,0,0,229,209,1,0,0,0,229,214,1,0,0,0,229,219,1,0,0,0,229,
224,1,0,0,0,230,25,1,0,0,0,231,232,7,1,0,0,232,27,1,0,0,0,233,234,5,78,0,
0,234,29,1,0,0,0,235,236,3,64,32,0,236,237,7,2,0,0,237,238,5,3,0,0,238,239,
3,62,31,0,239,240,5,5,0,0,240,31,1,0,0,0,241,243,7,3,0,0,242,244,5,60,0,
0,243,242,1,0,0,0,243,244,1,0,0,0,244,245,1,0,0,0,245,246,7,4,0,0,246,33,
1,0,0,0,247,248,7,5,0,0,248,249,5,3,0,0,249,250,3,62,31,0,250,251,5,5,0,
0,251,252,7,6,0,0,252,253,3,56,28,0,253,254,5,3,0,0,254,255,3,62,31,0,255,
259,5,5,0,0,256,258,3,32,16,0,257,256,1,0,0,0,258,261,1,0,0,0,259,257,1,
0,0,0,259,260,1,0,0,0,260,35,1,0,0,0,261,259,1,0,0,0,262,263,7,7,0,0,263,
37,1,0,0,0,264,265,5,39,0,0,265,39,1,0,0,0,266,267,7,8,0,0,267,41,1,0,0,
0,268,272,3,36,18,0,269,272,3,38,19,0,270,272,3,40,20,0,271,268,1,0,0,0,
271,269,1,0,0,0,271,270,1,0,0,0,272,275,1,0,0,0,273,271,1,0,0,0,273,274,
1,0,0,0,274,43,1,0,0,0,275,273,1,0,0,0,276,278,3,10,5,0,277,276,1,0,0,0,
278,281,1,0,0,0,279,277,1,0,0,0,279,280,1,0,0,0,280,282,1,0,0,0,281,279,
1,0,0,0,282,283,5,35,0,0,283,284,3,58,29,0,284,285,5,3,0,0,285,286,3,46,
23,0,286,287,5,5,0,0,287,288,3,42,21,0,288,289,5,4,0,0,289,290,3,76,38,0,
290,291,5,6,0,0,291,45,1,0,0,0,292,294,3,48,24,0,293,292,1,0,0,0,293,294,
1,0,0,0,294,299,1,0,0,0,295,296,5,7,0,0,296,298,3,48,24,0,297,295,1,0,0,
0,298,301,1,0,0,0,299,297,1,0,0,0,299,300,1,0,0,0,300,47,1,0,0,0,301,299,
1,0,0,0,302,303,5,76,0,0,303,49,1,0,0,0,304,305,5,74,0,0,305,51,1,0,0,0,
306,307,5,74,0,0,307,53,1,0,0,0,308,309,5,74,0,0,309,55,1,0,0,0,310,311,
5,74,0,0,311,57,1,0,0,0,312,313,5,74,0,0,313,59,1,0,0,0,314,315,5,74,0,0,
315,61,1,0,0,0,316,321,3,60,30,0,317,318,5,7,0,0,318,320,3,60,30,0,319,317,
1,0,0,0,320,323,1,0,0,0,321,319,1,0,0,0,321,322,1,0,0,0,322,63,1,0,0,0,323,
321,1,0,0,0,324,325,5,75,0,0,325,65,1,0,0,0,326,327,5,77,0,0,327,67,1,0,
0,0,328,329,5,74,0,0,329,69,1,0,0,0,330,331,3,26,13,0,331,71,1,0,0,0,332,
333,3,26,13,0,333,73,1,0,0,0,334,335,5,36,0,0,335,336,5,3,0,0,336,337,5,
5,0,0,337,338,5,4,0,0,338,339,3,76,38,0,339,340,5,6,0,0,340,75,1,0,0,0,341,
343,3,78,39,0,342,341,1,0,0,0,343,344,1,0,0,0,344,342,1,0,0,0,344,345,1,
0,0,0,345,77,1,0,0,0,346,349,3,80,40,0,347,349,3,82,41,0,348,346,1,0,0,0,
348,347,1,0,0,0,349,79,1,0,0,0,350,351,5,81,0,0,351,352,5,2,0,0,352,81,1,
0,0,0,353,354,3,84,42,0,354,355,5,12,0,0,355,357,1,0,0,0,356,353,1,0,0,0,
356,357,1,0,0,0,357,358,1,0,0,0,358,359,3,86,43,0,359,360,5,2,0,0,360,83,
1,0,0,0,361,366,3,88,44,0,362,363,5,7,0,0,363,365,3,88,44,0,364,362,1,0,
0,0,365,368,1,0,0,0,366,364,1,0,0,0,366,367,1,0,0,0,367,85,1,0,0,0,368,366,
1,0,0,0,369,370,3,94,47,0,370,371,5,3,0,0,371,372,3,98,49,0,372,373,5,5,
0,0,373,87,1,0,0,0,374,375,5,76,0,0,375,89,1,0,0,0,376,377,5,77,0,0,377,
91,1,0,0,0,378,379,5,74,0,0,379,380,5,11,0,0,380,381,5,74,0,0,381,93,1,0,
0,0,382,385,3,92,46,0,383,385,3,58,29,0,384,382,1,0,0,0,384,383,1,0,0,0,
385,95,1,0,0,0,386,387,5,74,0,0,387,97,1,0,0,0,388,390,3,100,50,0,389,388,
1,0,0,0,389,390,1,0,0,0,390,395,1,0,0,0,391,392,5,7,0,0,392,394,3,100,50,
0,393,391,1,0,0,0,394,397,1,0,0,0,395,393,1,0,0,0,395,396,1,0,0,0,396,99,
1,0,0,0,397,395,1,0,0,0,398,399,6,50,-1,0,399,426,3,26,13,0,400,426,3,88,
44,0,401,426,3,90,45,0,402,403,5,3,0,0,403,404,3,100,50,0,404,405,5,5,0,
0,405,426,1,0,0,0,406,407,7,9,0,0,407,426,3,100,50,11,408,409,5,71,0,0,409,
426,3,100,50,4,410,411,3,96,48,0,411,421,5,3,0,0,412,417,3,100,50,0,413,
414,5,7,0,0,414,416,3,100,50,0,415,413,1,0,0,0,416,419,1,0,0,0,417,415,1,
0,0,0,417,418,1,0,0,0,418,422,1,0,0,0,419,417,1,0,0,0,420,422,5,15,0,0,421,
412,1,0,0,0,421,420,1,0,0,0,421,422,1,0,0,0,422,423,1,0,0,0,423,424,5,5,
0,0,424,426,1,0,0,0,425,398,1,0,0,0,425,400,1,0,0,0,425,401,1,0,0,0,425,
402,1,0,0,0,425,406,1,0,0,0,425,408,1,0,0,0,425,410,1,0,0,0,426,453,1,0,
0,0,427,428,10,10,0,0,428,429,5,19,0,0,429,452,3,100,50,11,430,431,10,9,
0,0,431,432,7,10,0,0,432,452,3,100,50,10,433,434,10,8,0,0,434,435,7,11,0,
0,435,452,3,100,50,9,436,437,10,7,0,0,437,438,7,12,0,0,438,452,3,100,50,
8,439,440,10,6,0,0,440,441,7,13,0,0,441,452,3,100,50,7,442,443,10,5,0,0,
443,444,7,14,0,0,444,452,3,100,50,6,445,446,10,3,0,0,446,447,5,72,0,0,447,
452,3,100,50,4,448,449,10,2,0,0,449,450,5,73,0,0,450,452,3,100,50,3,451,
427,1,0,0,0,451,430,1,0,0,0,451,433,1,0,0,0,451,436,1,0,0,0,451,439,1,0,
0,0,451,442,1,0,0,0,451,445,1,0,0,0,451,448,1,0,0,0,452,455,1,0,0,0,453,
451,1,0,0,0,453,454,1,0,0,0,454,101,1,0,0,0,455,453,1,0,0,0,32,107,113,115,
129,140,157,171,175,179,188,196,229,243,259,271,273,279,293,299,321,344,
348,356,366,384,389,395,417,421,425,451,453];


const atn = new antlr4.atn.ATNDeserializer().deserialize(serializedATN);

const decisionsToDFA = atn.decisionToState.map( (ds, index) => new antlr4.dfa.DFA(ds, index) );

const sharedContextCache = new antlr4.atn.PredictionContextCache();

export default class KuneiformParser extends antlr4.Parser {

    static grammarFileName = "KuneiformParser.g4";
    static literalNames = [ null, "':'", "';'", "'('", "'{'", "')'", "'}'", 
                            "','", "'$'", "'#'", "'@'", "'.'", "'='", "'+'", 
                            "'-'", "'*'", "'/'", "'%'", "'~'", "'||'", "'<<'", 
                            "'>>'", "'&'", "'|'", "'=='", "'<'", "'<='", 
                            "'>'", "'>='", "'!='", "'<>'", "'database'", 
                            "'use'", "'as'", "'table'", "'action'", "'init'", 
                            "'public'", "'private'", "'view'", "'mustsign'", 
                            "'owner'", "'int'", "'text'", "'blob'", "'min'", 
                            "'max'", "'minlen'", "'maxlen'", "'notnull'", 
                            "'primary'", "'default'", "'unique'", "'index'", 
                            "'foreign_key'", "'fk'", "'references'", "'ref'", 
                            "'on_update'", "'on_delete'", "'do'", "'no_action'", 
                            "'cascade'", "'set_null'", "'set_default'", 
                            "'restrict'", null, null, null, null, null, 
                            "'not'", "'and'", "'or'" ];
    static symbolicNames = [ null, "COL", "SCOL", "L_PAREN", "L_BRACE", 
                             "R_PAREN", "R_BRACE", "COMMA", "DOLLAR", "HASH", 
                             "AT", "PERIOD", "ASSIGN", "PLUS", "MINUS", 
                             "STAR", "DIV", "MOD", "TILDE", "PIPE2", "LT2", 
                             "GT2", "AMP", "PIPE", "EQ", "LT", "LT_EQ", 
                             "GT", "GT_EQ", "SQL_NOT_EQ1", "SQL_NOT_EQ2", 
                             "DATABASE_", "USE_", "AS_", "TABLE_", "ACTION_", 
                             "INIT_", "PUBLIC_", "PRIVATE_", "VIEW_", "MUSTSIGN_", 
                             "OWNER_", "INT_", "TEXT_", "BLOB_", "MIN_", 
                             "MAX_", "MIN_LEN_", "MAX_LEN_", "NOT_NULL_", 
                             "PRIMARY_", "DEFAULT_", "UNIQUE_", "INDEX_", 
                             "FOREIGN_KEY_", "FOREIGN_KEY_ABBR_", "REFERENCES_", 
                             "REFERENCES_ABBR_", "ACTION_ON_UPDATE_", "ACTION_ON_DELETE_", 
                             "ACTION_DO_", "ACTION_DO_NO_ACTION_", "ACTION_DO_CASCADE_", 
                             "ACTION_DO_SET_NULL_", "ACTION_DO_SET_DEFAULT_", 
                             "ACTION_DO_RESTRICT_", "SELECT_", "INSERT_", 
                             "UPDATE_", "DELETE_", "WITH_", "NOT_", "AND_", 
                             "OR_", "IDENTIFIER", "INDEX_NAME", "PARAM_OR_VAR", 
                             "BLOCK_VAR_OR_ANNOTATION", "UNSIGNED_NUMBER_LITERAL", 
                             "STRING_LITERAL", "SQL_KEYWORDS", "SQL_STMT", 
                             "WS", "TERMINATOR", "BLOCK_COMMENT", "LINE_COMMENT" ];
    static ruleNames = [ "source_unit", "database_directive", "extension_directive", 
                         "ext_config_list", "ext_config", "annotation_decl", 
                         "annotation_attr_list", "annotation_attr", "table_decl", 
                         "column_def", "column_def_list", "column_type", 
                         "column_constraint", "literal_value", "number_value", 
                         "index_def", "foreign_key_action", "foreign_key_def", 
                         "action_visibility", "action_mutability", "action_auxiliary", 
                         "action_attr_list", "action_decl", "param_list", 
                         "parameter", "database_name", "extension_name", 
                         "ext_config_name", "table_name", "action_name", 
                         "column_name", "column_name_list", "index_name", 
                         "annotation_target_name", "annotation_attr_name", 
                         "annotation_attr_value", "ext_config_value", "init_decl", 
                         "action_stmt_list", "action_stmt", "sql_stmt", 
                         "call_stmt", "call_receivers", "call_body", "variable", 
                         "block_var", "extension_call_name", "fn_name", 
                         "sfn_name", "fn_arg_list", "fn_arg_expr" ];

    constructor(input) {
        super(input);
        this._interp = new antlr4.atn.ParserATNSimulator(this, atn, decisionsToDFA, sharedContextCache);
        this.ruleNames = KuneiformParser.ruleNames;
        this.literalNames = KuneiformParser.literalNames;
        this.symbolicNames = KuneiformParser.symbolicNames;
    }

    sempred(localctx, ruleIndex, predIndex) {
    	switch(ruleIndex) {
    	case 50:
    	    		return this.fn_arg_expr_sempred(localctx, predIndex);
        default:
            throw "No predicate with index:" + ruleIndex;
       }
    }

    fn_arg_expr_sempred(localctx, predIndex) {
    	switch(predIndex) {
    		case 0:
    			return this.precpred(this._ctx, 10);
    		case 1:
    			return this.precpred(this._ctx, 9);
    		case 2:
    			return this.precpred(this._ctx, 8);
    		case 3:
    			return this.precpred(this._ctx, 7);
    		case 4:
    			return this.precpred(this._ctx, 6);
    		case 5:
    			return this.precpred(this._ctx, 5);
    		case 6:
    			return this.precpred(this._ctx, 3);
    		case 7:
    			return this.precpred(this._ctx, 2);
    		default:
    			throw "No predicate with index:" + predIndex;
    	}
    };




	source_unit() {
	    let localctx = new Source_unitContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 0, KuneiformParser.RULE_source_unit);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 102;
	        this.database_directive();
	        this.state = 103;
	        this.match(KuneiformParser.SCOL);
	        this.state = 107;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        while(_la===32) {
	            this.state = 104;
	            this.extension_directive();
	            this.state = 109;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        }
	        this.state = 115;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        while(((((_la - 34)) & ~0x1f) === 0 && ((1 << (_la - 34)) & 7) !== 0) || _la===77) {
	            this.state = 113;
	            this._errHandler.sync(this);
	            switch(this._input.LA(1)) {
	            case 34:
	                this.state = 110;
	                this.table_decl();
	                break;
	            case 35:
	            case 77:
	                this.state = 111;
	                this.action_decl();
	                break;
	            case 36:
	                this.state = 112;
	                this.init_decl();
	                break;
	            default:
	                throw new antlr4.error.NoViableAltException(this);
	            }
	            this.state = 117;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        }
	        this.state = 118;
	        this.match(KuneiformParser.EOF);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	database_directive() {
	    let localctx = new Database_directiveContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 2, KuneiformParser.RULE_database_directive);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 120;
	        this.match(KuneiformParser.DATABASE_);
	        this.state = 121;
	        this.database_name();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	extension_directive() {
	    let localctx = new Extension_directiveContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 4, KuneiformParser.RULE_extension_directive);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 123;
	        this.match(KuneiformParser.USE_);
	        this.state = 124;
	        this.extension_name();
	        this.state = 129;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===4) {
	            this.state = 125;
	            this.match(KuneiformParser.L_BRACE);
	            this.state = 126;
	            this.ext_config_list();
	            this.state = 127;
	            this.match(KuneiformParser.R_BRACE);
	        }

	        this.state = 131;
	        this.match(KuneiformParser.AS_);
	        this.state = 132;
	        this.extension_name();
	        this.state = 133;
	        this.match(KuneiformParser.SCOL);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	ext_config_list() {
	    let localctx = new Ext_config_listContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 6, KuneiformParser.RULE_ext_config_list);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 135;
	        this.ext_config();
	        this.state = 140;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        while(_la===7) {
	            this.state = 136;
	            this.match(KuneiformParser.COMMA);
	            this.state = 137;
	            this.ext_config();
	            this.state = 142;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	ext_config() {
	    let localctx = new Ext_configContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 8, KuneiformParser.RULE_ext_config);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 143;
	        this.ext_config_name();
	        this.state = 144;
	        this.match(KuneiformParser.COL);
	        this.state = 145;
	        this.ext_config_value();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	annotation_decl() {
	    let localctx = new Annotation_declContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 10, KuneiformParser.RULE_annotation_decl);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 147;
	        this.annotation_target_name();
	        this.state = 148;
	        this.match(KuneiformParser.L_PAREN);
	        this.state = 149;
	        this.annotation_attr_list();
	        this.state = 150;
	        this.match(KuneiformParser.R_PAREN);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	annotation_attr_list() {
	    let localctx = new Annotation_attr_listContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 12, KuneiformParser.RULE_annotation_attr_list);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 152;
	        this.annotation_attr();
	        this.state = 157;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        while(_la===7) {
	            this.state = 153;
	            this.match(KuneiformParser.COMMA);
	            this.state = 154;
	            this.annotation_attr();
	            this.state = 159;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	annotation_attr() {
	    let localctx = new Annotation_attrContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 14, KuneiformParser.RULE_annotation_attr);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 160;
	        this.annotation_attr_name();
	        this.state = 161;
	        this.match(KuneiformParser.ASSIGN);
	        this.state = 162;
	        this.annotation_attr_value();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	table_decl() {
	    let localctx = new Table_declContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 16, KuneiformParser.RULE_table_decl);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 164;
	        this.match(KuneiformParser.TABLE_);
	        this.state = 165;
	        this.table_name();
	        this.state = 166;
	        this.match(KuneiformParser.L_BRACE);
	        this.state = 167;
	        this.column_def_list();
	        this.state = 175;
	        this._errHandler.sync(this);
	        var _alt = this._interp.adaptivePredict(this._input,7,this._ctx)
	        while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
	            if(_alt===1) {
	                this.state = 168;
	                this.match(KuneiformParser.COMMA);
	                this.state = 171;
	                this._errHandler.sync(this);
	                switch(this._input.LA(1)) {
	                case 75:
	                    this.state = 169;
	                    this.index_def();
	                    break;
	                case 54:
	                case 55:
	                    this.state = 170;
	                    this.foreign_key_def();
	                    break;
	                default:
	                    throw new antlr4.error.NoViableAltException(this);
	                } 
	            }
	            this.state = 177;
	            this._errHandler.sync(this);
	            _alt = this._interp.adaptivePredict(this._input,7,this._ctx);
	        }

	        this.state = 179;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===7) {
	            this.state = 178;
	            this.match(KuneiformParser.COMMA);
	        }

	        this.state = 181;
	        this.match(KuneiformParser.R_BRACE);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	column_def() {
	    let localctx = new Column_defContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 18, KuneiformParser.RULE_column_def);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 183;
	        this.column_name();
	        this.state = 184;
	        this.column_type();
	        this.state = 188;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        while(((((_la - 45)) & ~0x1f) === 0 && ((1 << (_la - 45)) & 255) !== 0)) {
	            this.state = 185;
	            this.column_constraint();
	            this.state = 190;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	column_def_list() {
	    let localctx = new Column_def_listContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 20, KuneiformParser.RULE_column_def_list);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 191;
	        this.column_def();
	        this.state = 196;
	        this._errHandler.sync(this);
	        var _alt = this._interp.adaptivePredict(this._input,10,this._ctx)
	        while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
	            if(_alt===1) {
	                this.state = 192;
	                this.match(KuneiformParser.COMMA);
	                this.state = 193;
	                this.column_def(); 
	            }
	            this.state = 198;
	            this._errHandler.sync(this);
	            _alt = this._interp.adaptivePredict(this._input,10,this._ctx);
	        }

	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	column_type() {
	    let localctx = new Column_typeContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 22, KuneiformParser.RULE_column_type);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 199;
	        _la = this._input.LA(1);
	        if(!(((((_la - 42)) & ~0x1f) === 0 && ((1 << (_la - 42)) & 7) !== 0))) {
	        this._errHandler.recoverInline(this);
	        }
	        else {
	        	this._errHandler.reportMatch(this);
	            this.consume();
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	column_constraint() {
	    let localctx = new Column_constraintContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 24, KuneiformParser.RULE_column_constraint);
	    try {
	        this.state = 229;
	        this._errHandler.sync(this);
	        switch(this._input.LA(1)) {
	        case 50:
	            this.enterOuterAlt(localctx, 1);
	            this.state = 201;
	            this.match(KuneiformParser.PRIMARY_);
	            break;
	        case 49:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 202;
	            this.match(KuneiformParser.NOT_NULL_);
	            break;
	        case 52:
	            this.enterOuterAlt(localctx, 3);
	            this.state = 203;
	            this.match(KuneiformParser.UNIQUE_);
	            break;
	        case 51:
	            this.enterOuterAlt(localctx, 4);
	            this.state = 204;
	            this.match(KuneiformParser.DEFAULT_);
	            this.state = 205;
	            this.match(KuneiformParser.L_PAREN);
	            this.state = 206;
	            this.literal_value();
	            this.state = 207;
	            this.match(KuneiformParser.R_PAREN);
	            break;
	        case 45:
	            this.enterOuterAlt(localctx, 5);
	            this.state = 209;
	            this.match(KuneiformParser.MIN_);
	            this.state = 210;
	            this.match(KuneiformParser.L_PAREN);
	            this.state = 211;
	            this.number_value();
	            this.state = 212;
	            this.match(KuneiformParser.R_PAREN);
	            break;
	        case 46:
	            this.enterOuterAlt(localctx, 6);
	            this.state = 214;
	            this.match(KuneiformParser.MAX_);
	            this.state = 215;
	            this.match(KuneiformParser.L_PAREN);
	            this.state = 216;
	            this.number_value();
	            this.state = 217;
	            this.match(KuneiformParser.R_PAREN);
	            break;
	        case 47:
	            this.enterOuterAlt(localctx, 7);
	            this.state = 219;
	            this.match(KuneiformParser.MIN_LEN_);
	            this.state = 220;
	            this.match(KuneiformParser.L_PAREN);
	            this.state = 221;
	            this.number_value();
	            this.state = 222;
	            this.match(KuneiformParser.R_PAREN);
	            break;
	        case 48:
	            this.enterOuterAlt(localctx, 8);
	            this.state = 224;
	            this.match(KuneiformParser.MAX_LEN_);
	            this.state = 225;
	            this.match(KuneiformParser.L_PAREN);
	            this.state = 226;
	            this.number_value();
	            this.state = 227;
	            this.match(KuneiformParser.R_PAREN);
	            break;
	        default:
	            throw new antlr4.error.NoViableAltException(this);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	literal_value() {
	    let localctx = new Literal_valueContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 26, KuneiformParser.RULE_literal_value);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 231;
	        _la = this._input.LA(1);
	        if(!(_la===78 || _la===79)) {
	        this._errHandler.recoverInline(this);
	        }
	        else {
	        	this._errHandler.reportMatch(this);
	            this.consume();
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	number_value() {
	    let localctx = new Number_valueContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 28, KuneiformParser.RULE_number_value);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 233;
	        this.match(KuneiformParser.UNSIGNED_NUMBER_LITERAL);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	index_def() {
	    let localctx = new Index_defContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 30, KuneiformParser.RULE_index_def);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 235;
	        this.index_name();
	        this.state = 236;
	        _la = this._input.LA(1);
	        if(!(((((_la - 50)) & ~0x1f) === 0 && ((1 << (_la - 50)) & 13) !== 0))) {
	        this._errHandler.recoverInline(this);
	        }
	        else {
	        	this._errHandler.reportMatch(this);
	            this.consume();
	        }
	        this.state = 237;
	        this.match(KuneiformParser.L_PAREN);
	        this.state = 238;
	        this.column_name_list();
	        this.state = 239;
	        this.match(KuneiformParser.R_PAREN);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	foreign_key_action() {
	    let localctx = new Foreign_key_actionContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 32, KuneiformParser.RULE_foreign_key_action);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 241;
	        _la = this._input.LA(1);
	        if(!(_la===58 || _la===59)) {
	        this._errHandler.recoverInline(this);
	        }
	        else {
	        	this._errHandler.reportMatch(this);
	            this.consume();
	        }
	        this.state = 243;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===60) {
	            this.state = 242;
	            this.match(KuneiformParser.ACTION_DO_);
	        }

	        this.state = 245;
	        _la = this._input.LA(1);
	        if(!(((((_la - 61)) & ~0x1f) === 0 && ((1 << (_la - 61)) & 31) !== 0))) {
	        this._errHandler.recoverInline(this);
	        }
	        else {
	        	this._errHandler.reportMatch(this);
	            this.consume();
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	foreign_key_def() {
	    let localctx = new Foreign_key_defContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 34, KuneiformParser.RULE_foreign_key_def);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 247;
	        _la = this._input.LA(1);
	        if(!(_la===54 || _la===55)) {
	        this._errHandler.recoverInline(this);
	        }
	        else {
	        	this._errHandler.reportMatch(this);
	            this.consume();
	        }
	        this.state = 248;
	        this.match(KuneiformParser.L_PAREN);
	        this.state = 249;
	        this.column_name_list();
	        this.state = 250;
	        this.match(KuneiformParser.R_PAREN);
	        this.state = 251;
	        _la = this._input.LA(1);
	        if(!(_la===56 || _la===57)) {
	        this._errHandler.recoverInline(this);
	        }
	        else {
	        	this._errHandler.reportMatch(this);
	            this.consume();
	        }
	        this.state = 252;
	        this.table_name();
	        this.state = 253;
	        this.match(KuneiformParser.L_PAREN);
	        this.state = 254;
	        this.column_name_list();
	        this.state = 255;
	        this.match(KuneiformParser.R_PAREN);
	        this.state = 259;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        while(_la===58 || _la===59) {
	            this.state = 256;
	            this.foreign_key_action();
	            this.state = 261;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	action_visibility() {
	    let localctx = new Action_visibilityContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 36, KuneiformParser.RULE_action_visibility);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 262;
	        _la = this._input.LA(1);
	        if(!(_la===37 || _la===38)) {
	        this._errHandler.recoverInline(this);
	        }
	        else {
	        	this._errHandler.reportMatch(this);
	            this.consume();
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	action_mutability() {
	    let localctx = new Action_mutabilityContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 38, KuneiformParser.RULE_action_mutability);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 264;
	        this.match(KuneiformParser.VIEW_);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	action_auxiliary() {
	    let localctx = new Action_auxiliaryContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 40, KuneiformParser.RULE_action_auxiliary);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 266;
	        _la = this._input.LA(1);
	        if(!(_la===40 || _la===41)) {
	        this._errHandler.recoverInline(this);
	        }
	        else {
	        	this._errHandler.reportMatch(this);
	            this.consume();
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	action_attr_list() {
	    let localctx = new Action_attr_listContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 42, KuneiformParser.RULE_action_attr_list);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 273;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        while(((((_la - 37)) & ~0x1f) === 0 && ((1 << (_la - 37)) & 31) !== 0)) {
	            this.state = 271;
	            this._errHandler.sync(this);
	            switch(this._input.LA(1)) {
	            case 37:
	            case 38:
	                this.state = 268;
	                this.action_visibility();
	                break;
	            case 39:
	                this.state = 269;
	                this.action_mutability();
	                break;
	            case 40:
	            case 41:
	                this.state = 270;
	                this.action_auxiliary();
	                break;
	            default:
	                throw new antlr4.error.NoViableAltException(this);
	            }
	            this.state = 275;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	action_decl() {
	    let localctx = new Action_declContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 44, KuneiformParser.RULE_action_decl);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 279;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        while(_la===77) {
	            this.state = 276;
	            this.annotation_decl();
	            this.state = 281;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        }
	        this.state = 282;
	        this.match(KuneiformParser.ACTION_);
	        this.state = 283;
	        this.action_name();
	        this.state = 284;
	        this.match(KuneiformParser.L_PAREN);
	        this.state = 285;
	        this.param_list();
	        this.state = 286;
	        this.match(KuneiformParser.R_PAREN);
	        this.state = 287;
	        this.action_attr_list();
	        this.state = 288;
	        this.match(KuneiformParser.L_BRACE);
	        this.state = 289;
	        this.action_stmt_list();
	        this.state = 290;
	        this.match(KuneiformParser.R_BRACE);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	param_list() {
	    let localctx = new Param_listContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 46, KuneiformParser.RULE_param_list);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 293;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===76) {
	            this.state = 292;
	            this.parameter();
	        }

	        this.state = 299;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        while(_la===7) {
	            this.state = 295;
	            this.match(KuneiformParser.COMMA);
	            this.state = 296;
	            this.parameter();
	            this.state = 301;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	parameter() {
	    let localctx = new ParameterContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 48, KuneiformParser.RULE_parameter);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 302;
	        this.match(KuneiformParser.PARAM_OR_VAR);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	database_name() {
	    let localctx = new Database_nameContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 50, KuneiformParser.RULE_database_name);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 304;
	        this.match(KuneiformParser.IDENTIFIER);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	extension_name() {
	    let localctx = new Extension_nameContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 52, KuneiformParser.RULE_extension_name);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 306;
	        this.match(KuneiformParser.IDENTIFIER);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	ext_config_name() {
	    let localctx = new Ext_config_nameContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 54, KuneiformParser.RULE_ext_config_name);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 308;
	        this.match(KuneiformParser.IDENTIFIER);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	table_name() {
	    let localctx = new Table_nameContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 56, KuneiformParser.RULE_table_name);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 310;
	        this.match(KuneiformParser.IDENTIFIER);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	action_name() {
	    let localctx = new Action_nameContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 58, KuneiformParser.RULE_action_name);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 312;
	        this.match(KuneiformParser.IDENTIFIER);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	column_name() {
	    let localctx = new Column_nameContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 60, KuneiformParser.RULE_column_name);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 314;
	        this.match(KuneiformParser.IDENTIFIER);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	column_name_list() {
	    let localctx = new Column_name_listContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 62, KuneiformParser.RULE_column_name_list);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 316;
	        this.column_name();
	        this.state = 321;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        while(_la===7) {
	            this.state = 317;
	            this.match(KuneiformParser.COMMA);
	            this.state = 318;
	            this.column_name();
	            this.state = 323;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	index_name() {
	    let localctx = new Index_nameContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 64, KuneiformParser.RULE_index_name);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 324;
	        this.match(KuneiformParser.INDEX_NAME);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	annotation_target_name() {
	    let localctx = new Annotation_target_nameContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 66, KuneiformParser.RULE_annotation_target_name);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 326;
	        this.match(KuneiformParser.BLOCK_VAR_OR_ANNOTATION);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	annotation_attr_name() {
	    let localctx = new Annotation_attr_nameContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 68, KuneiformParser.RULE_annotation_attr_name);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 328;
	        this.match(KuneiformParser.IDENTIFIER);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	annotation_attr_value() {
	    let localctx = new Annotation_attr_valueContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 70, KuneiformParser.RULE_annotation_attr_value);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 330;
	        this.literal_value();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	ext_config_value() {
	    let localctx = new Ext_config_valueContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 72, KuneiformParser.RULE_ext_config_value);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 332;
	        this.literal_value();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	init_decl() {
	    let localctx = new Init_declContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 74, KuneiformParser.RULE_init_decl);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 334;
	        this.match(KuneiformParser.INIT_);
	        this.state = 335;
	        this.match(KuneiformParser.L_PAREN);
	        this.state = 336;
	        this.match(KuneiformParser.R_PAREN);
	        this.state = 337;
	        this.match(KuneiformParser.L_BRACE);
	        this.state = 338;
	        this.action_stmt_list();
	        this.state = 339;
	        this.match(KuneiformParser.R_BRACE);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	action_stmt_list() {
	    let localctx = new Action_stmt_listContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 76, KuneiformParser.RULE_action_stmt_list);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 342; 
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        do {
	            this.state = 341;
	            this.action_stmt();
	            this.state = 344; 
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        } while(((((_la - 74)) & ~0x1f) === 0 && ((1 << (_la - 74)) & 133) !== 0));
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	action_stmt() {
	    let localctx = new Action_stmtContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 78, KuneiformParser.RULE_action_stmt);
	    try {
	        this.state = 348;
	        this._errHandler.sync(this);
	        switch(this._input.LA(1)) {
	        case 81:
	            this.enterOuterAlt(localctx, 1);
	            this.state = 346;
	            this.sql_stmt();
	            break;
	        case 74:
	        case 76:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 347;
	            this.call_stmt();
	            break;
	        default:
	            throw new antlr4.error.NoViableAltException(this);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	sql_stmt() {
	    let localctx = new Sql_stmtContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 80, KuneiformParser.RULE_sql_stmt);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 350;
	        this.match(KuneiformParser.SQL_STMT);
	        this.state = 351;
	        this.match(KuneiformParser.SCOL);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	call_stmt() {
	    let localctx = new Call_stmtContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 82, KuneiformParser.RULE_call_stmt);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 356;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===76) {
	            this.state = 353;
	            this.call_receivers();
	            this.state = 354;
	            this.match(KuneiformParser.ASSIGN);
	        }

	        this.state = 358;
	        this.call_body();
	        this.state = 359;
	        this.match(KuneiformParser.SCOL);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	call_receivers() {
	    let localctx = new Call_receiversContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 84, KuneiformParser.RULE_call_receivers);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 361;
	        this.variable();
	        this.state = 366;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        while(_la===7) {
	            this.state = 362;
	            this.match(KuneiformParser.COMMA);
	            this.state = 363;
	            this.variable();
	            this.state = 368;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	call_body() {
	    let localctx = new Call_bodyContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 86, KuneiformParser.RULE_call_body);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 369;
	        this.fn_name();
	        this.state = 370;
	        this.match(KuneiformParser.L_PAREN);
	        this.state = 371;
	        this.fn_arg_list();
	        this.state = 372;
	        this.match(KuneiformParser.R_PAREN);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	variable() {
	    let localctx = new VariableContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 88, KuneiformParser.RULE_variable);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 374;
	        this.match(KuneiformParser.PARAM_OR_VAR);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	block_var() {
	    let localctx = new Block_varContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 90, KuneiformParser.RULE_block_var);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 376;
	        this.match(KuneiformParser.BLOCK_VAR_OR_ANNOTATION);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	extension_call_name() {
	    let localctx = new Extension_call_nameContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 92, KuneiformParser.RULE_extension_call_name);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 378;
	        this.match(KuneiformParser.IDENTIFIER);
	        this.state = 379;
	        this.match(KuneiformParser.PERIOD);
	        this.state = 380;
	        this.match(KuneiformParser.IDENTIFIER);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	fn_name() {
	    let localctx = new Fn_nameContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 94, KuneiformParser.RULE_fn_name);
	    try {
	        this.state = 384;
	        this._errHandler.sync(this);
	        var la_ = this._interp.adaptivePredict(this._input,24,this._ctx);
	        switch(la_) {
	        case 1:
	            this.enterOuterAlt(localctx, 1);
	            this.state = 382;
	            this.extension_call_name();
	            break;

	        case 2:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 383;
	            this.action_name();
	            break;

	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	sfn_name() {
	    let localctx = new Sfn_nameContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 96, KuneiformParser.RULE_sfn_name);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 386;
	        this.match(KuneiformParser.IDENTIFIER);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	fn_arg_list() {
	    let localctx = new Fn_arg_listContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 98, KuneiformParser.RULE_fn_arg_list);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 389;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if((((_la) & ~0x1f) === 0 && ((1 << _la) & 286728) !== 0) || ((((_la - 71)) & ~0x1f) === 0 && ((1 << (_la - 71)) & 489) !== 0)) {
	            this.state = 388;
	            this.fn_arg_expr(0);
	        }

	        this.state = 395;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        while(_la===7) {
	            this.state = 391;
	            this.match(KuneiformParser.COMMA);
	            this.state = 392;
	            this.fn_arg_expr(0);
	            this.state = 397;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}


	fn_arg_expr(_p) {
		if(_p===undefined) {
		    _p = 0;
		}
	    const _parentctx = this._ctx;
	    const _parentState = this.state;
	    let localctx = new Fn_arg_exprContext(this, this._ctx, _parentState);
	    let _prevctx = localctx;
	    const _startState = 100;
	    this.enterRecursionRule(localctx, 100, KuneiformParser.RULE_fn_arg_expr, _p);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 425;
	        this._errHandler.sync(this);
	        switch(this._input.LA(1)) {
	        case 78:
	        case 79:
	            this.state = 399;
	            this.literal_value();
	            break;
	        case 76:
	            this.state = 400;
	            this.variable();
	            break;
	        case 77:
	            this.state = 401;
	            this.block_var();
	            break;
	        case 3:
	            this.state = 402;
	            this.match(KuneiformParser.L_PAREN);
	            this.state = 403;
	            localctx.elevate_expr = this.fn_arg_expr(0);
	            this.state = 404;
	            this.match(KuneiformParser.R_PAREN);
	            break;
	        case 13:
	        case 14:
	        case 18:
	            this.state = 406;
	            _la = this._input.LA(1);
	            if(!((((_la) & ~0x1f) === 0 && ((1 << _la) & 286720) !== 0))) {
	            this._errHandler.recoverInline(this);
	            }
	            else {
	            	this._errHandler.reportMatch(this);
	                this.consume();
	            }
	            this.state = 407;
	            localctx.unary_expr = this.fn_arg_expr(11);
	            break;
	        case 71:
	            this.state = 408;
	            this.match(KuneiformParser.NOT_);
	            this.state = 409;
	            localctx.unary_expr = this.fn_arg_expr(4);
	            break;
	        case 74:
	            this.state = 410;
	            this.sfn_name();
	            this.state = 411;
	            this.match(KuneiformParser.L_PAREN);
	            this.state = 421;
	            this._errHandler.sync(this);
	            switch (this._input.LA(1)) {
	            case 3:
	            case 13:
	            case 14:
	            case 18:
	            case 71:
	            case 74:
	            case 76:
	            case 77:
	            case 78:
	            case 79:
	            	this.state = 412;
	            	this.fn_arg_expr(0);
	            	this.state = 417;
	            	this._errHandler.sync(this);
	            	_la = this._input.LA(1);
	            	while(_la===7) {
	            	    this.state = 413;
	            	    this.match(KuneiformParser.COMMA);
	            	    this.state = 414;
	            	    this.fn_arg_expr(0);
	            	    this.state = 419;
	            	    this._errHandler.sync(this);
	            	    _la = this._input.LA(1);
	            	}
	            	break;
	            case 15:
	            	this.state = 420;
	            	this.match(KuneiformParser.STAR);
	            	break;
	            case 5:
	            	break;
	            default:
	            	break;
	            }
	            this.state = 423;
	            this.match(KuneiformParser.R_PAREN);
	            break;
	        default:
	            throw new antlr4.error.NoViableAltException(this);
	        }
	        this._ctx.stop = this._input.LT(-1);
	        this.state = 453;
	        this._errHandler.sync(this);
	        var _alt = this._interp.adaptivePredict(this._input,31,this._ctx)
	        while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
	            if(_alt===1) {
	                if(this._parseListeners!==null) {
	                    this.triggerExitRuleEvent();
	                }
	                _prevctx = localctx;
	                this.state = 451;
	                this._errHandler.sync(this);
	                var la_ = this._interp.adaptivePredict(this._input,30,this._ctx);
	                switch(la_) {
	                case 1:
	                    localctx = new Fn_arg_exprContext(this, _parentctx, _parentState);
	                    this.pushNewRecursionContext(localctx, _startState, KuneiformParser.RULE_fn_arg_expr);
	                    this.state = 427;
	                    if (!( this.precpred(this._ctx, 10))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 10)");
	                    }
	                    this.state = 428;
	                    this.match(KuneiformParser.PIPE2);
	                    this.state = 429;
	                    this.fn_arg_expr(11);
	                    break;

	                case 2:
	                    localctx = new Fn_arg_exprContext(this, _parentctx, _parentState);
	                    this.pushNewRecursionContext(localctx, _startState, KuneiformParser.RULE_fn_arg_expr);
	                    this.state = 430;
	                    if (!( this.precpred(this._ctx, 9))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 9)");
	                    }
	                    this.state = 431;
	                    _la = this._input.LA(1);
	                    if(!((((_la) & ~0x1f) === 0 && ((1 << _la) & 229376) !== 0))) {
	                    this._errHandler.recoverInline(this);
	                    }
	                    else {
	                    	this._errHandler.reportMatch(this);
	                        this.consume();
	                    }
	                    this.state = 432;
	                    this.fn_arg_expr(10);
	                    break;

	                case 3:
	                    localctx = new Fn_arg_exprContext(this, _parentctx, _parentState);
	                    this.pushNewRecursionContext(localctx, _startState, KuneiformParser.RULE_fn_arg_expr);
	                    this.state = 433;
	                    if (!( this.precpred(this._ctx, 8))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 8)");
	                    }
	                    this.state = 434;
	                    _la = this._input.LA(1);
	                    if(!(_la===13 || _la===14)) {
	                    this._errHandler.recoverInline(this);
	                    }
	                    else {
	                    	this._errHandler.reportMatch(this);
	                        this.consume();
	                    }
	                    this.state = 435;
	                    this.fn_arg_expr(9);
	                    break;

	                case 4:
	                    localctx = new Fn_arg_exprContext(this, _parentctx, _parentState);
	                    this.pushNewRecursionContext(localctx, _startState, KuneiformParser.RULE_fn_arg_expr);
	                    this.state = 436;
	                    if (!( this.precpred(this._ctx, 7))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 7)");
	                    }
	                    this.state = 437;
	                    _la = this._input.LA(1);
	                    if(!((((_la) & ~0x1f) === 0 && ((1 << _la) & 15728640) !== 0))) {
	                    this._errHandler.recoverInline(this);
	                    }
	                    else {
	                    	this._errHandler.reportMatch(this);
	                        this.consume();
	                    }
	                    this.state = 438;
	                    this.fn_arg_expr(8);
	                    break;

	                case 5:
	                    localctx = new Fn_arg_exprContext(this, _parentctx, _parentState);
	                    this.pushNewRecursionContext(localctx, _startState, KuneiformParser.RULE_fn_arg_expr);
	                    this.state = 439;
	                    if (!( this.precpred(this._ctx, 6))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 6)");
	                    }
	                    this.state = 440;
	                    _la = this._input.LA(1);
	                    if(!((((_la) & ~0x1f) === 0 && ((1 << _la) & 503316480) !== 0))) {
	                    this._errHandler.recoverInline(this);
	                    }
	                    else {
	                    	this._errHandler.reportMatch(this);
	                        this.consume();
	                    }
	                    this.state = 441;
	                    this.fn_arg_expr(7);
	                    break;

	                case 6:
	                    localctx = new Fn_arg_exprContext(this, _parentctx, _parentState);
	                    this.pushNewRecursionContext(localctx, _startState, KuneiformParser.RULE_fn_arg_expr);
	                    this.state = 442;
	                    if (!( this.precpred(this._ctx, 5))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 5)");
	                    }
	                    this.state = 443;
	                    _la = this._input.LA(1);
	                    if(!((((_la) & ~0x1f) === 0 && ((1 << _la) & 1627394048) !== 0))) {
	                    this._errHandler.recoverInline(this);
	                    }
	                    else {
	                    	this._errHandler.reportMatch(this);
	                        this.consume();
	                    }
	                    this.state = 444;
	                    this.fn_arg_expr(6);
	                    break;

	                case 7:
	                    localctx = new Fn_arg_exprContext(this, _parentctx, _parentState);
	                    this.pushNewRecursionContext(localctx, _startState, KuneiformParser.RULE_fn_arg_expr);
	                    this.state = 445;
	                    if (!( this.precpred(this._ctx, 3))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 3)");
	                    }
	                    this.state = 446;
	                    this.match(KuneiformParser.AND_);
	                    this.state = 447;
	                    this.fn_arg_expr(4);
	                    break;

	                case 8:
	                    localctx = new Fn_arg_exprContext(this, _parentctx, _parentState);
	                    this.pushNewRecursionContext(localctx, _startState, KuneiformParser.RULE_fn_arg_expr);
	                    this.state = 448;
	                    if (!( this.precpred(this._ctx, 2))) {
	                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 2)");
	                    }
	                    this.state = 449;
	                    this.match(KuneiformParser.OR_);
	                    this.state = 450;
	                    this.fn_arg_expr(3);
	                    break;

	                } 
	            }
	            this.state = 455;
	            this._errHandler.sync(this);
	            _alt = this._interp.adaptivePredict(this._input,31,this._ctx);
	        }

	    } catch( error) {
	        if(error instanceof antlr4.error.RecognitionException) {
		        localctx.exception = error;
		        this._errHandler.reportError(this, error);
		        this._errHandler.recover(this, error);
		    } else {
		    	throw error;
		    }
	    } finally {
	        this.unrollRecursionContexts(_parentctx)
	    }
	    return localctx;
	}


}

KuneiformParser.EOF = antlr4.Token.EOF;
KuneiformParser.COL = 1;
KuneiformParser.SCOL = 2;
KuneiformParser.L_PAREN = 3;
KuneiformParser.L_BRACE = 4;
KuneiformParser.R_PAREN = 5;
KuneiformParser.R_BRACE = 6;
KuneiformParser.COMMA = 7;
KuneiformParser.DOLLAR = 8;
KuneiformParser.HASH = 9;
KuneiformParser.AT = 10;
KuneiformParser.PERIOD = 11;
KuneiformParser.ASSIGN = 12;
KuneiformParser.PLUS = 13;
KuneiformParser.MINUS = 14;
KuneiformParser.STAR = 15;
KuneiformParser.DIV = 16;
KuneiformParser.MOD = 17;
KuneiformParser.TILDE = 18;
KuneiformParser.PIPE2 = 19;
KuneiformParser.LT2 = 20;
KuneiformParser.GT2 = 21;
KuneiformParser.AMP = 22;
KuneiformParser.PIPE = 23;
KuneiformParser.EQ = 24;
KuneiformParser.LT = 25;
KuneiformParser.LT_EQ = 26;
KuneiformParser.GT = 27;
KuneiformParser.GT_EQ = 28;
KuneiformParser.SQL_NOT_EQ1 = 29;
KuneiformParser.SQL_NOT_EQ2 = 30;
KuneiformParser.DATABASE_ = 31;
KuneiformParser.USE_ = 32;
KuneiformParser.AS_ = 33;
KuneiformParser.TABLE_ = 34;
KuneiformParser.ACTION_ = 35;
KuneiformParser.INIT_ = 36;
KuneiformParser.PUBLIC_ = 37;
KuneiformParser.PRIVATE_ = 38;
KuneiformParser.VIEW_ = 39;
KuneiformParser.MUSTSIGN_ = 40;
KuneiformParser.OWNER_ = 41;
KuneiformParser.INT_ = 42;
KuneiformParser.TEXT_ = 43;
KuneiformParser.BLOB_ = 44;
KuneiformParser.MIN_ = 45;
KuneiformParser.MAX_ = 46;
KuneiformParser.MIN_LEN_ = 47;
KuneiformParser.MAX_LEN_ = 48;
KuneiformParser.NOT_NULL_ = 49;
KuneiformParser.PRIMARY_ = 50;
KuneiformParser.DEFAULT_ = 51;
KuneiformParser.UNIQUE_ = 52;
KuneiformParser.INDEX_ = 53;
KuneiformParser.FOREIGN_KEY_ = 54;
KuneiformParser.FOREIGN_KEY_ABBR_ = 55;
KuneiformParser.REFERENCES_ = 56;
KuneiformParser.REFERENCES_ABBR_ = 57;
KuneiformParser.ACTION_ON_UPDATE_ = 58;
KuneiformParser.ACTION_ON_DELETE_ = 59;
KuneiformParser.ACTION_DO_ = 60;
KuneiformParser.ACTION_DO_NO_ACTION_ = 61;
KuneiformParser.ACTION_DO_CASCADE_ = 62;
KuneiformParser.ACTION_DO_SET_NULL_ = 63;
KuneiformParser.ACTION_DO_SET_DEFAULT_ = 64;
KuneiformParser.ACTION_DO_RESTRICT_ = 65;
KuneiformParser.SELECT_ = 66;
KuneiformParser.INSERT_ = 67;
KuneiformParser.UPDATE_ = 68;
KuneiformParser.DELETE_ = 69;
KuneiformParser.WITH_ = 70;
KuneiformParser.NOT_ = 71;
KuneiformParser.AND_ = 72;
KuneiformParser.OR_ = 73;
KuneiformParser.IDENTIFIER = 74;
KuneiformParser.INDEX_NAME = 75;
KuneiformParser.PARAM_OR_VAR = 76;
KuneiformParser.BLOCK_VAR_OR_ANNOTATION = 77;
KuneiformParser.UNSIGNED_NUMBER_LITERAL = 78;
KuneiformParser.STRING_LITERAL = 79;
KuneiformParser.SQL_KEYWORDS = 80;
KuneiformParser.SQL_STMT = 81;
KuneiformParser.WS = 82;
KuneiformParser.TERMINATOR = 83;
KuneiformParser.BLOCK_COMMENT = 84;
KuneiformParser.LINE_COMMENT = 85;

KuneiformParser.RULE_source_unit = 0;
KuneiformParser.RULE_database_directive = 1;
KuneiformParser.RULE_extension_directive = 2;
KuneiformParser.RULE_ext_config_list = 3;
KuneiformParser.RULE_ext_config = 4;
KuneiformParser.RULE_annotation_decl = 5;
KuneiformParser.RULE_annotation_attr_list = 6;
KuneiformParser.RULE_annotation_attr = 7;
KuneiformParser.RULE_table_decl = 8;
KuneiformParser.RULE_column_def = 9;
KuneiformParser.RULE_column_def_list = 10;
KuneiformParser.RULE_column_type = 11;
KuneiformParser.RULE_column_constraint = 12;
KuneiformParser.RULE_literal_value = 13;
KuneiformParser.RULE_number_value = 14;
KuneiformParser.RULE_index_def = 15;
KuneiformParser.RULE_foreign_key_action = 16;
KuneiformParser.RULE_foreign_key_def = 17;
KuneiformParser.RULE_action_visibility = 18;
KuneiformParser.RULE_action_mutability = 19;
KuneiformParser.RULE_action_auxiliary = 20;
KuneiformParser.RULE_action_attr_list = 21;
KuneiformParser.RULE_action_decl = 22;
KuneiformParser.RULE_param_list = 23;
KuneiformParser.RULE_parameter = 24;
KuneiformParser.RULE_database_name = 25;
KuneiformParser.RULE_extension_name = 26;
KuneiformParser.RULE_ext_config_name = 27;
KuneiformParser.RULE_table_name = 28;
KuneiformParser.RULE_action_name = 29;
KuneiformParser.RULE_column_name = 30;
KuneiformParser.RULE_column_name_list = 31;
KuneiformParser.RULE_index_name = 32;
KuneiformParser.RULE_annotation_target_name = 33;
KuneiformParser.RULE_annotation_attr_name = 34;
KuneiformParser.RULE_annotation_attr_value = 35;
KuneiformParser.RULE_ext_config_value = 36;
KuneiformParser.RULE_init_decl = 37;
KuneiformParser.RULE_action_stmt_list = 38;
KuneiformParser.RULE_action_stmt = 39;
KuneiformParser.RULE_sql_stmt = 40;
KuneiformParser.RULE_call_stmt = 41;
KuneiformParser.RULE_call_receivers = 42;
KuneiformParser.RULE_call_body = 43;
KuneiformParser.RULE_variable = 44;
KuneiformParser.RULE_block_var = 45;
KuneiformParser.RULE_extension_call_name = 46;
KuneiformParser.RULE_fn_name = 47;
KuneiformParser.RULE_sfn_name = 48;
KuneiformParser.RULE_fn_arg_list = 49;
KuneiformParser.RULE_fn_arg_expr = 50;

class Source_unitContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = KuneiformParser.RULE_source_unit;
    }

	database_directive() {
	    return this.getTypedRuleContext(Database_directiveContext,0);
	};

	SCOL() {
	    return this.getToken(KuneiformParser.SCOL, 0);
	};

	EOF() {
	    return this.getToken(KuneiformParser.EOF, 0);
	};

	extension_directive = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(Extension_directiveContext);
	    } else {
	        return this.getTypedRuleContext(Extension_directiveContext,i);
	    }
	};

	table_decl = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(Table_declContext);
	    } else {
	        return this.getTypedRuleContext(Table_declContext,i);
	    }
	};

	action_decl = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(Action_declContext);
	    } else {
	        return this.getTypedRuleContext(Action_declContext,i);
	    }
	};

	init_decl = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(Init_declContext);
	    } else {
	        return this.getTypedRuleContext(Init_declContext,i);
	    }
	};

	accept(visitor) {
	    if ( visitor instanceof KuneiformParserVisitor ) {
	        return visitor.visitSource_unit(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Database_directiveContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = KuneiformParser.RULE_database_directive;
    }

	DATABASE_() {
	    return this.getToken(KuneiformParser.DATABASE_, 0);
	};

	database_name() {
	    return this.getTypedRuleContext(Database_nameContext,0);
	};

	accept(visitor) {
	    if ( visitor instanceof KuneiformParserVisitor ) {
	        return visitor.visitDatabase_directive(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Extension_directiveContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = KuneiformParser.RULE_extension_directive;
    }

	USE_() {
	    return this.getToken(KuneiformParser.USE_, 0);
	};

	extension_name = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(Extension_nameContext);
	    } else {
	        return this.getTypedRuleContext(Extension_nameContext,i);
	    }
	};

	AS_() {
	    return this.getToken(KuneiformParser.AS_, 0);
	};

	SCOL() {
	    return this.getToken(KuneiformParser.SCOL, 0);
	};

	L_BRACE() {
	    return this.getToken(KuneiformParser.L_BRACE, 0);
	};

	ext_config_list() {
	    return this.getTypedRuleContext(Ext_config_listContext,0);
	};

	R_BRACE() {
	    return this.getToken(KuneiformParser.R_BRACE, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof KuneiformParserVisitor ) {
	        return visitor.visitExtension_directive(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Ext_config_listContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = KuneiformParser.RULE_ext_config_list;
    }

	ext_config = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(Ext_configContext);
	    } else {
	        return this.getTypedRuleContext(Ext_configContext,i);
	    }
	};

	COMMA = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(KuneiformParser.COMMA);
	    } else {
	        return this.getToken(KuneiformParser.COMMA, i);
	    }
	};


	accept(visitor) {
	    if ( visitor instanceof KuneiformParserVisitor ) {
	        return visitor.visitExt_config_list(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Ext_configContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = KuneiformParser.RULE_ext_config;
    }

	ext_config_name() {
	    return this.getTypedRuleContext(Ext_config_nameContext,0);
	};

	COL() {
	    return this.getToken(KuneiformParser.COL, 0);
	};

	ext_config_value() {
	    return this.getTypedRuleContext(Ext_config_valueContext,0);
	};

	accept(visitor) {
	    if ( visitor instanceof KuneiformParserVisitor ) {
	        return visitor.visitExt_config(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Annotation_declContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = KuneiformParser.RULE_annotation_decl;
    }

	annotation_target_name() {
	    return this.getTypedRuleContext(Annotation_target_nameContext,0);
	};

	L_PAREN() {
	    return this.getToken(KuneiformParser.L_PAREN, 0);
	};

	annotation_attr_list() {
	    return this.getTypedRuleContext(Annotation_attr_listContext,0);
	};

	R_PAREN() {
	    return this.getToken(KuneiformParser.R_PAREN, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof KuneiformParserVisitor ) {
	        return visitor.visitAnnotation_decl(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Annotation_attr_listContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = KuneiformParser.RULE_annotation_attr_list;
    }

	annotation_attr = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(Annotation_attrContext);
	    } else {
	        return this.getTypedRuleContext(Annotation_attrContext,i);
	    }
	};

	COMMA = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(KuneiformParser.COMMA);
	    } else {
	        return this.getToken(KuneiformParser.COMMA, i);
	    }
	};


	accept(visitor) {
	    if ( visitor instanceof KuneiformParserVisitor ) {
	        return visitor.visitAnnotation_attr_list(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Annotation_attrContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = KuneiformParser.RULE_annotation_attr;
    }

	annotation_attr_name() {
	    return this.getTypedRuleContext(Annotation_attr_nameContext,0);
	};

	ASSIGN() {
	    return this.getToken(KuneiformParser.ASSIGN, 0);
	};

	annotation_attr_value() {
	    return this.getTypedRuleContext(Annotation_attr_valueContext,0);
	};

	accept(visitor) {
	    if ( visitor instanceof KuneiformParserVisitor ) {
	        return visitor.visitAnnotation_attr(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Table_declContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = KuneiformParser.RULE_table_decl;
    }

	TABLE_() {
	    return this.getToken(KuneiformParser.TABLE_, 0);
	};

	table_name() {
	    return this.getTypedRuleContext(Table_nameContext,0);
	};

	L_BRACE() {
	    return this.getToken(KuneiformParser.L_BRACE, 0);
	};

	column_def_list() {
	    return this.getTypedRuleContext(Column_def_listContext,0);
	};

	R_BRACE() {
	    return this.getToken(KuneiformParser.R_BRACE, 0);
	};

	COMMA = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(KuneiformParser.COMMA);
	    } else {
	        return this.getToken(KuneiformParser.COMMA, i);
	    }
	};


	index_def = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(Index_defContext);
	    } else {
	        return this.getTypedRuleContext(Index_defContext,i);
	    }
	};

	foreign_key_def = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(Foreign_key_defContext);
	    } else {
	        return this.getTypedRuleContext(Foreign_key_defContext,i);
	    }
	};

	accept(visitor) {
	    if ( visitor instanceof KuneiformParserVisitor ) {
	        return visitor.visitTable_decl(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Column_defContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = KuneiformParser.RULE_column_def;
    }

	column_name() {
	    return this.getTypedRuleContext(Column_nameContext,0);
	};

	column_type() {
	    return this.getTypedRuleContext(Column_typeContext,0);
	};

	column_constraint = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(Column_constraintContext);
	    } else {
	        return this.getTypedRuleContext(Column_constraintContext,i);
	    }
	};

	accept(visitor) {
	    if ( visitor instanceof KuneiformParserVisitor ) {
	        return visitor.visitColumn_def(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Column_def_listContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = KuneiformParser.RULE_column_def_list;
    }

	column_def = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(Column_defContext);
	    } else {
	        return this.getTypedRuleContext(Column_defContext,i);
	    }
	};

	COMMA = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(KuneiformParser.COMMA);
	    } else {
	        return this.getToken(KuneiformParser.COMMA, i);
	    }
	};


	accept(visitor) {
	    if ( visitor instanceof KuneiformParserVisitor ) {
	        return visitor.visitColumn_def_list(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Column_typeContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = KuneiformParser.RULE_column_type;
    }

	INT_() {
	    return this.getToken(KuneiformParser.INT_, 0);
	};

	TEXT_() {
	    return this.getToken(KuneiformParser.TEXT_, 0);
	};

	BLOB_() {
	    return this.getToken(KuneiformParser.BLOB_, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof KuneiformParserVisitor ) {
	        return visitor.visitColumn_type(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Column_constraintContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = KuneiformParser.RULE_column_constraint;
    }

	PRIMARY_() {
	    return this.getToken(KuneiformParser.PRIMARY_, 0);
	};

	NOT_NULL_() {
	    return this.getToken(KuneiformParser.NOT_NULL_, 0);
	};

	UNIQUE_() {
	    return this.getToken(KuneiformParser.UNIQUE_, 0);
	};

	DEFAULT_() {
	    return this.getToken(KuneiformParser.DEFAULT_, 0);
	};

	L_PAREN() {
	    return this.getToken(KuneiformParser.L_PAREN, 0);
	};

	literal_value() {
	    return this.getTypedRuleContext(Literal_valueContext,0);
	};

	R_PAREN() {
	    return this.getToken(KuneiformParser.R_PAREN, 0);
	};

	MIN_() {
	    return this.getToken(KuneiformParser.MIN_, 0);
	};

	number_value() {
	    return this.getTypedRuleContext(Number_valueContext,0);
	};

	MAX_() {
	    return this.getToken(KuneiformParser.MAX_, 0);
	};

	MIN_LEN_() {
	    return this.getToken(KuneiformParser.MIN_LEN_, 0);
	};

	MAX_LEN_() {
	    return this.getToken(KuneiformParser.MAX_LEN_, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof KuneiformParserVisitor ) {
	        return visitor.visitColumn_constraint(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Literal_valueContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = KuneiformParser.RULE_literal_value;
    }

	STRING_LITERAL() {
	    return this.getToken(KuneiformParser.STRING_LITERAL, 0);
	};

	UNSIGNED_NUMBER_LITERAL() {
	    return this.getToken(KuneiformParser.UNSIGNED_NUMBER_LITERAL, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof KuneiformParserVisitor ) {
	        return visitor.visitLiteral_value(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Number_valueContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = KuneiformParser.RULE_number_value;
    }

	UNSIGNED_NUMBER_LITERAL() {
	    return this.getToken(KuneiformParser.UNSIGNED_NUMBER_LITERAL, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof KuneiformParserVisitor ) {
	        return visitor.visitNumber_value(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Index_defContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = KuneiformParser.RULE_index_def;
    }

	index_name() {
	    return this.getTypedRuleContext(Index_nameContext,0);
	};

	L_PAREN() {
	    return this.getToken(KuneiformParser.L_PAREN, 0);
	};

	column_name_list() {
	    return this.getTypedRuleContext(Column_name_listContext,0);
	};

	R_PAREN() {
	    return this.getToken(KuneiformParser.R_PAREN, 0);
	};

	UNIQUE_() {
	    return this.getToken(KuneiformParser.UNIQUE_, 0);
	};

	INDEX_() {
	    return this.getToken(KuneiformParser.INDEX_, 0);
	};

	PRIMARY_() {
	    return this.getToken(KuneiformParser.PRIMARY_, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof KuneiformParserVisitor ) {
	        return visitor.visitIndex_def(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Foreign_key_actionContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = KuneiformParser.RULE_foreign_key_action;
    }

	ACTION_ON_UPDATE_() {
	    return this.getToken(KuneiformParser.ACTION_ON_UPDATE_, 0);
	};

	ACTION_ON_DELETE_() {
	    return this.getToken(KuneiformParser.ACTION_ON_DELETE_, 0);
	};

	ACTION_DO_NO_ACTION_() {
	    return this.getToken(KuneiformParser.ACTION_DO_NO_ACTION_, 0);
	};

	ACTION_DO_RESTRICT_() {
	    return this.getToken(KuneiformParser.ACTION_DO_RESTRICT_, 0);
	};

	ACTION_DO_SET_NULL_() {
	    return this.getToken(KuneiformParser.ACTION_DO_SET_NULL_, 0);
	};

	ACTION_DO_SET_DEFAULT_() {
	    return this.getToken(KuneiformParser.ACTION_DO_SET_DEFAULT_, 0);
	};

	ACTION_DO_CASCADE_() {
	    return this.getToken(KuneiformParser.ACTION_DO_CASCADE_, 0);
	};

	ACTION_DO_() {
	    return this.getToken(KuneiformParser.ACTION_DO_, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof KuneiformParserVisitor ) {
	        return visitor.visitForeign_key_action(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Foreign_key_defContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = KuneiformParser.RULE_foreign_key_def;
    }

	L_PAREN = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(KuneiformParser.L_PAREN);
	    } else {
	        return this.getToken(KuneiformParser.L_PAREN, i);
	    }
	};


	column_name_list = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(Column_name_listContext);
	    } else {
	        return this.getTypedRuleContext(Column_name_listContext,i);
	    }
	};

	R_PAREN = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(KuneiformParser.R_PAREN);
	    } else {
	        return this.getToken(KuneiformParser.R_PAREN, i);
	    }
	};


	table_name() {
	    return this.getTypedRuleContext(Table_nameContext,0);
	};

	FOREIGN_KEY_() {
	    return this.getToken(KuneiformParser.FOREIGN_KEY_, 0);
	};

	FOREIGN_KEY_ABBR_() {
	    return this.getToken(KuneiformParser.FOREIGN_KEY_ABBR_, 0);
	};

	REFERENCES_() {
	    return this.getToken(KuneiformParser.REFERENCES_, 0);
	};

	REFERENCES_ABBR_() {
	    return this.getToken(KuneiformParser.REFERENCES_ABBR_, 0);
	};

	foreign_key_action = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(Foreign_key_actionContext);
	    } else {
	        return this.getTypedRuleContext(Foreign_key_actionContext,i);
	    }
	};

	accept(visitor) {
	    if ( visitor instanceof KuneiformParserVisitor ) {
	        return visitor.visitForeign_key_def(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Action_visibilityContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = KuneiformParser.RULE_action_visibility;
    }

	PUBLIC_() {
	    return this.getToken(KuneiformParser.PUBLIC_, 0);
	};

	PRIVATE_() {
	    return this.getToken(KuneiformParser.PRIVATE_, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof KuneiformParserVisitor ) {
	        return visitor.visitAction_visibility(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Action_mutabilityContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = KuneiformParser.RULE_action_mutability;
    }

	VIEW_() {
	    return this.getToken(KuneiformParser.VIEW_, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof KuneiformParserVisitor ) {
	        return visitor.visitAction_mutability(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Action_auxiliaryContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = KuneiformParser.RULE_action_auxiliary;
    }

	MUSTSIGN_() {
	    return this.getToken(KuneiformParser.MUSTSIGN_, 0);
	};

	OWNER_() {
	    return this.getToken(KuneiformParser.OWNER_, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof KuneiformParserVisitor ) {
	        return visitor.visitAction_auxiliary(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Action_attr_listContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = KuneiformParser.RULE_action_attr_list;
    }

	action_visibility = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(Action_visibilityContext);
	    } else {
	        return this.getTypedRuleContext(Action_visibilityContext,i);
	    }
	};

	action_mutability = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(Action_mutabilityContext);
	    } else {
	        return this.getTypedRuleContext(Action_mutabilityContext,i);
	    }
	};

	action_auxiliary = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(Action_auxiliaryContext);
	    } else {
	        return this.getTypedRuleContext(Action_auxiliaryContext,i);
	    }
	};

	accept(visitor) {
	    if ( visitor instanceof KuneiformParserVisitor ) {
	        return visitor.visitAction_attr_list(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Action_declContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = KuneiformParser.RULE_action_decl;
    }

	ACTION_() {
	    return this.getToken(KuneiformParser.ACTION_, 0);
	};

	action_name() {
	    return this.getTypedRuleContext(Action_nameContext,0);
	};

	L_PAREN() {
	    return this.getToken(KuneiformParser.L_PAREN, 0);
	};

	param_list() {
	    return this.getTypedRuleContext(Param_listContext,0);
	};

	R_PAREN() {
	    return this.getToken(KuneiformParser.R_PAREN, 0);
	};

	action_attr_list() {
	    return this.getTypedRuleContext(Action_attr_listContext,0);
	};

	L_BRACE() {
	    return this.getToken(KuneiformParser.L_BRACE, 0);
	};

	action_stmt_list() {
	    return this.getTypedRuleContext(Action_stmt_listContext,0);
	};

	R_BRACE() {
	    return this.getToken(KuneiformParser.R_BRACE, 0);
	};

	annotation_decl = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(Annotation_declContext);
	    } else {
	        return this.getTypedRuleContext(Annotation_declContext,i);
	    }
	};

	accept(visitor) {
	    if ( visitor instanceof KuneiformParserVisitor ) {
	        return visitor.visitAction_decl(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Param_listContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = KuneiformParser.RULE_param_list;
    }

	parameter = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(ParameterContext);
	    } else {
	        return this.getTypedRuleContext(ParameterContext,i);
	    }
	};

	COMMA = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(KuneiformParser.COMMA);
	    } else {
	        return this.getToken(KuneiformParser.COMMA, i);
	    }
	};


	accept(visitor) {
	    if ( visitor instanceof KuneiformParserVisitor ) {
	        return visitor.visitParam_list(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ParameterContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = KuneiformParser.RULE_parameter;
    }

	PARAM_OR_VAR() {
	    return this.getToken(KuneiformParser.PARAM_OR_VAR, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof KuneiformParserVisitor ) {
	        return visitor.visitParameter(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Database_nameContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = KuneiformParser.RULE_database_name;
    }

	IDENTIFIER() {
	    return this.getToken(KuneiformParser.IDENTIFIER, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof KuneiformParserVisitor ) {
	        return visitor.visitDatabase_name(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Extension_nameContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = KuneiformParser.RULE_extension_name;
    }

	IDENTIFIER() {
	    return this.getToken(KuneiformParser.IDENTIFIER, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof KuneiformParserVisitor ) {
	        return visitor.visitExtension_name(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Ext_config_nameContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = KuneiformParser.RULE_ext_config_name;
    }

	IDENTIFIER() {
	    return this.getToken(KuneiformParser.IDENTIFIER, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof KuneiformParserVisitor ) {
	        return visitor.visitExt_config_name(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Table_nameContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = KuneiformParser.RULE_table_name;
    }

	IDENTIFIER() {
	    return this.getToken(KuneiformParser.IDENTIFIER, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof KuneiformParserVisitor ) {
	        return visitor.visitTable_name(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Action_nameContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = KuneiformParser.RULE_action_name;
    }

	IDENTIFIER() {
	    return this.getToken(KuneiformParser.IDENTIFIER, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof KuneiformParserVisitor ) {
	        return visitor.visitAction_name(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Column_nameContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = KuneiformParser.RULE_column_name;
    }

	IDENTIFIER() {
	    return this.getToken(KuneiformParser.IDENTIFIER, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof KuneiformParserVisitor ) {
	        return visitor.visitColumn_name(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Column_name_listContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = KuneiformParser.RULE_column_name_list;
    }

	column_name = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(Column_nameContext);
	    } else {
	        return this.getTypedRuleContext(Column_nameContext,i);
	    }
	};

	COMMA = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(KuneiformParser.COMMA);
	    } else {
	        return this.getToken(KuneiformParser.COMMA, i);
	    }
	};


	accept(visitor) {
	    if ( visitor instanceof KuneiformParserVisitor ) {
	        return visitor.visitColumn_name_list(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Index_nameContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = KuneiformParser.RULE_index_name;
    }

	INDEX_NAME() {
	    return this.getToken(KuneiformParser.INDEX_NAME, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof KuneiformParserVisitor ) {
	        return visitor.visitIndex_name(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Annotation_target_nameContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = KuneiformParser.RULE_annotation_target_name;
    }

	BLOCK_VAR_OR_ANNOTATION() {
	    return this.getToken(KuneiformParser.BLOCK_VAR_OR_ANNOTATION, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof KuneiformParserVisitor ) {
	        return visitor.visitAnnotation_target_name(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Annotation_attr_nameContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = KuneiformParser.RULE_annotation_attr_name;
    }

	IDENTIFIER() {
	    return this.getToken(KuneiformParser.IDENTIFIER, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof KuneiformParserVisitor ) {
	        return visitor.visitAnnotation_attr_name(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Annotation_attr_valueContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = KuneiformParser.RULE_annotation_attr_value;
    }

	literal_value() {
	    return this.getTypedRuleContext(Literal_valueContext,0);
	};

	accept(visitor) {
	    if ( visitor instanceof KuneiformParserVisitor ) {
	        return visitor.visitAnnotation_attr_value(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Ext_config_valueContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = KuneiformParser.RULE_ext_config_value;
    }

	literal_value() {
	    return this.getTypedRuleContext(Literal_valueContext,0);
	};

	accept(visitor) {
	    if ( visitor instanceof KuneiformParserVisitor ) {
	        return visitor.visitExt_config_value(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Init_declContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = KuneiformParser.RULE_init_decl;
    }

	INIT_() {
	    return this.getToken(KuneiformParser.INIT_, 0);
	};

	L_PAREN() {
	    return this.getToken(KuneiformParser.L_PAREN, 0);
	};

	R_PAREN() {
	    return this.getToken(KuneiformParser.R_PAREN, 0);
	};

	L_BRACE() {
	    return this.getToken(KuneiformParser.L_BRACE, 0);
	};

	action_stmt_list() {
	    return this.getTypedRuleContext(Action_stmt_listContext,0);
	};

	R_BRACE() {
	    return this.getToken(KuneiformParser.R_BRACE, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof KuneiformParserVisitor ) {
	        return visitor.visitInit_decl(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Action_stmt_listContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = KuneiformParser.RULE_action_stmt_list;
    }

	action_stmt = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(Action_stmtContext);
	    } else {
	        return this.getTypedRuleContext(Action_stmtContext,i);
	    }
	};

	accept(visitor) {
	    if ( visitor instanceof KuneiformParserVisitor ) {
	        return visitor.visitAction_stmt_list(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Action_stmtContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = KuneiformParser.RULE_action_stmt;
    }

	sql_stmt() {
	    return this.getTypedRuleContext(Sql_stmtContext,0);
	};

	call_stmt() {
	    return this.getTypedRuleContext(Call_stmtContext,0);
	};

	accept(visitor) {
	    if ( visitor instanceof KuneiformParserVisitor ) {
	        return visitor.visitAction_stmt(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Sql_stmtContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = KuneiformParser.RULE_sql_stmt;
    }

	SQL_STMT() {
	    return this.getToken(KuneiformParser.SQL_STMT, 0);
	};

	SCOL() {
	    return this.getToken(KuneiformParser.SCOL, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof KuneiformParserVisitor ) {
	        return visitor.visitSql_stmt(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Call_stmtContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = KuneiformParser.RULE_call_stmt;
    }

	call_body() {
	    return this.getTypedRuleContext(Call_bodyContext,0);
	};

	SCOL() {
	    return this.getToken(KuneiformParser.SCOL, 0);
	};

	call_receivers() {
	    return this.getTypedRuleContext(Call_receiversContext,0);
	};

	ASSIGN() {
	    return this.getToken(KuneiformParser.ASSIGN, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof KuneiformParserVisitor ) {
	        return visitor.visitCall_stmt(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Call_receiversContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = KuneiformParser.RULE_call_receivers;
    }

	variable = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(VariableContext);
	    } else {
	        return this.getTypedRuleContext(VariableContext,i);
	    }
	};

	COMMA = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(KuneiformParser.COMMA);
	    } else {
	        return this.getToken(KuneiformParser.COMMA, i);
	    }
	};


	accept(visitor) {
	    if ( visitor instanceof KuneiformParserVisitor ) {
	        return visitor.visitCall_receivers(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Call_bodyContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = KuneiformParser.RULE_call_body;
    }

	fn_name() {
	    return this.getTypedRuleContext(Fn_nameContext,0);
	};

	L_PAREN() {
	    return this.getToken(KuneiformParser.L_PAREN, 0);
	};

	fn_arg_list() {
	    return this.getTypedRuleContext(Fn_arg_listContext,0);
	};

	R_PAREN() {
	    return this.getToken(KuneiformParser.R_PAREN, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof KuneiformParserVisitor ) {
	        return visitor.visitCall_body(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class VariableContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = KuneiformParser.RULE_variable;
    }

	PARAM_OR_VAR() {
	    return this.getToken(KuneiformParser.PARAM_OR_VAR, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof KuneiformParserVisitor ) {
	        return visitor.visitVariable(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Block_varContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = KuneiformParser.RULE_block_var;
    }

	BLOCK_VAR_OR_ANNOTATION() {
	    return this.getToken(KuneiformParser.BLOCK_VAR_OR_ANNOTATION, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof KuneiformParserVisitor ) {
	        return visitor.visitBlock_var(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Extension_call_nameContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = KuneiformParser.RULE_extension_call_name;
    }

	IDENTIFIER = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(KuneiformParser.IDENTIFIER);
	    } else {
	        return this.getToken(KuneiformParser.IDENTIFIER, i);
	    }
	};


	PERIOD() {
	    return this.getToken(KuneiformParser.PERIOD, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof KuneiformParserVisitor ) {
	        return visitor.visitExtension_call_name(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Fn_nameContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = KuneiformParser.RULE_fn_name;
    }

	extension_call_name() {
	    return this.getTypedRuleContext(Extension_call_nameContext,0);
	};

	action_name() {
	    return this.getTypedRuleContext(Action_nameContext,0);
	};

	accept(visitor) {
	    if ( visitor instanceof KuneiformParserVisitor ) {
	        return visitor.visitFn_name(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Sfn_nameContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = KuneiformParser.RULE_sfn_name;
    }

	IDENTIFIER() {
	    return this.getToken(KuneiformParser.IDENTIFIER, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof KuneiformParserVisitor ) {
	        return visitor.visitSfn_name(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Fn_arg_listContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = KuneiformParser.RULE_fn_arg_list;
    }

	fn_arg_expr = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(Fn_arg_exprContext);
	    } else {
	        return this.getTypedRuleContext(Fn_arg_exprContext,i);
	    }
	};

	COMMA = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(KuneiformParser.COMMA);
	    } else {
	        return this.getToken(KuneiformParser.COMMA, i);
	    }
	};


	accept(visitor) {
	    if ( visitor instanceof KuneiformParserVisitor ) {
	        return visitor.visitFn_arg_list(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Fn_arg_exprContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = KuneiformParser.RULE_fn_arg_expr;
        this.elevate_expr = null;
        this.unary_expr = null;
    }

	literal_value() {
	    return this.getTypedRuleContext(Literal_valueContext,0);
	};

	variable() {
	    return this.getTypedRuleContext(VariableContext,0);
	};

	block_var() {
	    return this.getTypedRuleContext(Block_varContext,0);
	};

	L_PAREN() {
	    return this.getToken(KuneiformParser.L_PAREN, 0);
	};

	R_PAREN() {
	    return this.getToken(KuneiformParser.R_PAREN, 0);
	};

	fn_arg_expr = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(Fn_arg_exprContext);
	    } else {
	        return this.getTypedRuleContext(Fn_arg_exprContext,i);
	    }
	};

	MINUS() {
	    return this.getToken(KuneiformParser.MINUS, 0);
	};

	PLUS() {
	    return this.getToken(KuneiformParser.PLUS, 0);
	};

	TILDE() {
	    return this.getToken(KuneiformParser.TILDE, 0);
	};

	NOT_() {
	    return this.getToken(KuneiformParser.NOT_, 0);
	};

	sfn_name() {
	    return this.getTypedRuleContext(Sfn_nameContext,0);
	};

	STAR() {
	    return this.getToken(KuneiformParser.STAR, 0);
	};

	COMMA = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(KuneiformParser.COMMA);
	    } else {
	        return this.getToken(KuneiformParser.COMMA, i);
	    }
	};


	PIPE2() {
	    return this.getToken(KuneiformParser.PIPE2, 0);
	};

	DIV() {
	    return this.getToken(KuneiformParser.DIV, 0);
	};

	MOD() {
	    return this.getToken(KuneiformParser.MOD, 0);
	};

	LT2() {
	    return this.getToken(KuneiformParser.LT2, 0);
	};

	GT2() {
	    return this.getToken(KuneiformParser.GT2, 0);
	};

	AMP() {
	    return this.getToken(KuneiformParser.AMP, 0);
	};

	PIPE() {
	    return this.getToken(KuneiformParser.PIPE, 0);
	};

	LT() {
	    return this.getToken(KuneiformParser.LT, 0);
	};

	LT_EQ() {
	    return this.getToken(KuneiformParser.LT_EQ, 0);
	};

	GT() {
	    return this.getToken(KuneiformParser.GT, 0);
	};

	GT_EQ() {
	    return this.getToken(KuneiformParser.GT_EQ, 0);
	};

	ASSIGN() {
	    return this.getToken(KuneiformParser.ASSIGN, 0);
	};

	EQ() {
	    return this.getToken(KuneiformParser.EQ, 0);
	};

	SQL_NOT_EQ1() {
	    return this.getToken(KuneiformParser.SQL_NOT_EQ1, 0);
	};

	SQL_NOT_EQ2() {
	    return this.getToken(KuneiformParser.SQL_NOT_EQ2, 0);
	};

	AND_() {
	    return this.getToken(KuneiformParser.AND_, 0);
	};

	OR_() {
	    return this.getToken(KuneiformParser.OR_, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof KuneiformParserVisitor ) {
	        return visitor.visitFn_arg_expr(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}




KuneiformParser.Source_unitContext = Source_unitContext; 
KuneiformParser.Database_directiveContext = Database_directiveContext; 
KuneiformParser.Extension_directiveContext = Extension_directiveContext; 
KuneiformParser.Ext_config_listContext = Ext_config_listContext; 
KuneiformParser.Ext_configContext = Ext_configContext; 
KuneiformParser.Annotation_declContext = Annotation_declContext; 
KuneiformParser.Annotation_attr_listContext = Annotation_attr_listContext; 
KuneiformParser.Annotation_attrContext = Annotation_attrContext; 
KuneiformParser.Table_declContext = Table_declContext; 
KuneiformParser.Column_defContext = Column_defContext; 
KuneiformParser.Column_def_listContext = Column_def_listContext; 
KuneiformParser.Column_typeContext = Column_typeContext; 
KuneiformParser.Column_constraintContext = Column_constraintContext; 
KuneiformParser.Literal_valueContext = Literal_valueContext; 
KuneiformParser.Number_valueContext = Number_valueContext; 
KuneiformParser.Index_defContext = Index_defContext; 
KuneiformParser.Foreign_key_actionContext = Foreign_key_actionContext; 
KuneiformParser.Foreign_key_defContext = Foreign_key_defContext; 
KuneiformParser.Action_visibilityContext = Action_visibilityContext; 
KuneiformParser.Action_mutabilityContext = Action_mutabilityContext; 
KuneiformParser.Action_auxiliaryContext = Action_auxiliaryContext; 
KuneiformParser.Action_attr_listContext = Action_attr_listContext; 
KuneiformParser.Action_declContext = Action_declContext; 
KuneiformParser.Param_listContext = Param_listContext; 
KuneiformParser.ParameterContext = ParameterContext; 
KuneiformParser.Database_nameContext = Database_nameContext; 
KuneiformParser.Extension_nameContext = Extension_nameContext; 
KuneiformParser.Ext_config_nameContext = Ext_config_nameContext; 
KuneiformParser.Table_nameContext = Table_nameContext; 
KuneiformParser.Action_nameContext = Action_nameContext; 
KuneiformParser.Column_nameContext = Column_nameContext; 
KuneiformParser.Column_name_listContext = Column_name_listContext; 
KuneiformParser.Index_nameContext = Index_nameContext; 
KuneiformParser.Annotation_target_nameContext = Annotation_target_nameContext; 
KuneiformParser.Annotation_attr_nameContext = Annotation_attr_nameContext; 
KuneiformParser.Annotation_attr_valueContext = Annotation_attr_valueContext; 
KuneiformParser.Ext_config_valueContext = Ext_config_valueContext; 
KuneiformParser.Init_declContext = Init_declContext; 
KuneiformParser.Action_stmt_listContext = Action_stmt_listContext; 
KuneiformParser.Action_stmtContext = Action_stmtContext; 
KuneiformParser.Sql_stmtContext = Sql_stmtContext; 
KuneiformParser.Call_stmtContext = Call_stmtContext; 
KuneiformParser.Call_receiversContext = Call_receiversContext; 
KuneiformParser.Call_bodyContext = Call_bodyContext; 
KuneiformParser.VariableContext = VariableContext; 
KuneiformParser.Block_varContext = Block_varContext; 
KuneiformParser.Extension_call_nameContext = Extension_call_nameContext; 
KuneiformParser.Fn_nameContext = Fn_nameContext; 
KuneiformParser.Sfn_nameContext = Sfn_nameContext; 
KuneiformParser.Fn_arg_listContext = Fn_arg_listContext; 
KuneiformParser.Fn_arg_exprContext = Fn_arg_exprContext; 
