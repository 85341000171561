import { createAction, createReducer } from "@reduxjs/toolkit";
import { socialNetworkKf } from "./sampleKf/socialNetwork";
import { tokenPrice } from "./sampleKf/tokenPrice";
import { videoGame } from "./sampleKf/videoGame";

export const newFile = createAction('newFile')
export const changeFolder = createAction('changeFolder')
export const changeCode = createAction('changeCode')
export const deleteFile = createAction('deleteFile')
export const resetFiles = createAction('resetFiles')

export const initalFileState = {
    "social_network.kf": {
        file: "social_network.kf",
        folder: "sample_databases",
        code: socialNetworkKf,
        workSpace: "Default Workspace"
    },
    "token_data.kf": {
        file: "token_data.kf",
        folder: "sample_databases",
        code: tokenPrice,
        workSpace: "Default Workspace"
    },
    "video_game.kf": {
        file: "video_game.kf",
        folder: "sample_databases",
        code: videoGame,
        workSpace: "Default Workspace"
    },
    "index.kf": {
        file: "index.kf",
        folder: "",
        code: "",
        workSpace: "Default Workspace"
    }
}

export const filesDTO = createReducer(initalFileState, {
    [newFile]: (state, action) => {
        const { fileName, folderName, code, workSpace } = action.payload

        return {
            ...state,
            [fileName]: {
                file: fileName,
                folder: folderName,
                code: code,
                workSpace: workSpace
            }
        }
    },
    [changeFolder]: (state, action) => {
        const { fileName, folderName } = action.payload

        return {
            ...state,
            [fileName]: {
                ...state[fileName],
                folder: folderName
            }
        }
    },
    [changeCode]: (state, action) => {
        const { fileName, code } = action.payload

        return {
            ...state,
            [fileName]: {
                ...state[fileName],
                code: code
            }
        }
    },
    [deleteFile]: (state, action) => {
        let newState = {...state}
        delete newState[action.payload]

        return newState
    },
    [resetFiles]: () => {
        return {}
    },
})