import { TableBody, TableHead } from "@mui/material";
import { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { select } from "../../../../utils/playFunctions";
import { CustomRow, CustomTable, CustomTableContainer, TableBodyCell, TableHeaderCell } from "../../../Mui/table";


export default function PlayTable({ table, owner, db, actionExecutedCount }) {
    const [tableData, setTableData] = useState([])

    const { name, columns } = table

    async function retData() {
        try {
            const { data } = await select(owner, db, `SELECT * FROM ${name} LIMIT 35`)
            setTableData(data)
        } catch (error) {
            console.log(error)
            setTableData([])
        }
    }

    useEffect(() => {
        if(name && owner && db) retData()
    }, [name, owner, db])

    useEffect(() => {
        retData()
    }, [actionExecutedCount, name])

    return(
        <div className="play-table">
            <h3
                className="table-name"
            >
                Table: {name}
            </h3>
            <div
                className="table-container"
            >
                <Scrollbars
                    autoHeight
                    autoHeightMin={0}
                    autoHeightMax={"200px"}
                    renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}
                >
                    <CustomTableContainer>
                        <CustomTable stickyHeader>
                            <TableHead>
                                <CustomRow>
                                    {columns && columns.length > 0 && columns.map((column, i) => {
                                        return(
                                            <TableHeaderCell key={i}>{column.name}</TableHeaderCell>
                                        )
                                    })}
                                </CustomRow>
                            </TableHead>
                            <TableBody>
                                {tableData && tableData.length > 0 && tableData.map((row, i) => {
                                    return(
                                        <CustomRow key={i}>
                                            {columns && columns.length > 0 && columns.map((column, i) => {
                                                return(
                                                    <TableBodyCell key={i}>{row[column.name]}</TableBodyCell>
                                                )
                                            })}
                                        </CustomRow>
                                    )
                                })}
                            </TableBody>
                        </CustomTable>
                    </CustomTableContainer>
                </Scrollbars>
            </div>
        </div>
    )
}