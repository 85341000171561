import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeFolder } from "../../../../redux/reducers/filesDTO";
import { arrangeFiles } from "../../../../utils/fileHelpers";
import FileInterface from "./FileManager-components/FileInterface";
import FolderInterface from "./FileManager-components/FolderInterface";
import NewFileInterface from "./FileManager-components/NewFileInterface";
import NewFolderInterface from "./FileManager-components/NewFolderInterface";


export default function FileManager({ newFolder, setNewFolder, currentFolder, setCurrentFolder, newFile, setNewFile }) {
    const allFiles = useSelector(state => state.filesDTO)
    const currentWorkspace = useSelector(state => state.workspaces.currentWorkspace);
    const [organizedFiles, setOrganizedFiles] = useState([])
    const [unorganizedFiles, setUnorganizedFiles] = useState([])
    const [createdFolder, setCreatedFolder] = useState("")
    
    const dispatch = useDispatch()

    useEffect(() => {
        const files = arrangeFiles(allFiles, currentWorkspace, createdFolder)
        setOrganizedFiles(files.organizedFileArray)
        setUnorganizedFiles(files.unorganized)
    }, [createdFolder, allFiles, currentWorkspace])

    // functions for drag and drop
    const [draggedFile, setDraggedFile] = useState(null)

    function handleDragStart(file) {
        setDraggedFile(file)
    }

    function handleDragOver(e) {
        e.preventDefault();
    }

    function handleDrop(e, folder) {
        e.stopPropagation();
        e.preventDefault();
        dispatch(changeFolder({ fileName: draggedFile, folderName: folder }))
    }

    return(
        <div className="file-manager" onDragOver={(e) => handleDragOver(e)} onDrop={(e) => handleDrop(e, "")}>
            {organizedFiles.length > 0 && organizedFiles.map((folder, index) => {
                return(
                    <FolderInterface
                        key={index}
                        folderName={Object.keys(folder)[0]}
                        files={folder[Object.keys(folder)[0]]}
                        currentFolder={currentFolder}
                        setCurrentFolder={setCurrentFolder}
                        newFile={newFile}
                        setNewFile={setNewFile}
                        handleDragStart={handleDragStart}
                        handleDragOver={handleDragOver}
                        handleDrop={handleDrop}
                    />
                )
            })}
            {unorganizedFiles.length > 0 && unorganizedFiles.map((file, index) => {
                return(
                    <FileInterface
                        key={index}
                        fileName={file.file}
                        handleDragStart={handleDragStart}
                    />
                )
            })}
            {newFolder && 
                <NewFolderInterface 
                    setNewFolder={setNewFolder}
                    setCreatedFolder={setCreatedFolder}
                />
            }
            {newFile && newFile.folder === "" &&
                <NewFileInterface
                    currentFolder=""
                    setNewFile={setNewFile}
                />
            }
        </div>
    )
}