import { useDispatch, useSelector } from "react-redux";
import CodeEditor from "./components/CodeEditor/CodeEditor";
import { CustomAlert, CustomSnackbar } from "./components/Mui/alerts";
import NavBar from "./components/NavBar/NavBar";
import SidePanel from "./components/SidePanel/SidePanel";
import { errorOff, successOff } from "./redux/reducers/alerts";


export default function Main() {
    const isSuccessOn = useSelector(state => state.alerts.success)
    const successMessage = useSelector(state => state.alerts.successMessage)
    const isErrorOn = useSelector(state => state.alerts.error)
    const errorMessage = useSelector(state => state.alerts.errorMessage)

    const dispatch = useDispatch()

    return (
        <div className="background">
            <NavBar />
            <SidePanel />
            <CodeEditor />
            <CustomSnackbar
                open={isSuccessOn}
                autoHideDuration={4000}
                onClose={() => dispatch(successOff())}
            >
                <CustomAlert
                    variant="filled"
                    severity="success"
                >
                    <pre style={{ fontFamily: 'inherit', fontSize: 'inherit', margin: 0 }}>
                        {successMessage}
                    </pre>
                </CustomAlert>
            </CustomSnackbar>
            <CustomSnackbar
                open={isErrorOn}
                onClose={() => dispatch(errorOff())}
            >
                <CustomAlert
                    variant="filled"
                    severity="error"
                >
                    <pre style={{ fontFamily: 'inherit', fontSize: 'inherit', margin: 0 }}>
                        {errorMessage}
                    </pre>
                </CustomAlert>
            </CustomSnackbar>
        </div>
    )
}