import styled from "@emotion/styled"
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material"

export const CustomAccordion = styled(Accordion)({
    width: "100%",
    backgroundColor: "#D9D9D9",
    boxShadow: "none",
    "&:before" : {
        background: "rgba(255, 255, 255, 0.2)"
    },
    borderRadius: "2px !important",
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
})

export const ActionAccordionSummary = styled(AccordionSummary)({
    minHeight: "20px",
    maxHeight: "20px",
    fontFamily: "AvertaStd",
    fontSize: "13px",
    fontWeight: "400",
    color: "#000",
    padding: "0px 4px"
})

export const ActionAccordionDetails = styled(AccordionDetails)({
    padding: "0px 4px",
    display: "flex",
    flexDirection: "column",
})