import { TableBody, TableContainer, TableHead } from "@mui/material";
import Scrollbars from "react-custom-scrollbars-2";
import { CustomRow, CustomTable, CustomTableContainer, TableBodyCell, TableHeaderCell } from "../../../Mui/table";


export default function QueryResTable({ dataFromAction }) {
    const columns = dataFromAction && dataFromAction.length > 0 ? Object.keys(dataFromAction[0]) : []

    return(
        <div className="query-res-table">
            <h3
                className="table-name"
            >
                Query Results
            </h3>
            <div
                className="table-container"
            >
                <TableContainer>
                    <Scrollbars
                        autoHeight
                        autoHeightMin={0}
                        autoHeightMax={"200px"}
                        renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}
                    >
                        <CustomTableContainer>
                            <CustomTable stickyHeader>
                                <TableHead>
                                    <CustomRow>
                                        {columns && columns.length > 0 && columns.map((column, i) => {
                                            return(
                                                <TableHeaderCell key={i}>{column}</TableHeaderCell>
                                            )
                                        })}
                                    </CustomRow>
                                </TableHead>
                                <TableBody>
                                    {dataFromAction && dataFromAction.length > 0 && dataFromAction.map((row, i) => {
                                        return(
                                            <CustomRow key={i}>
                                                {columns && columns.length > 0 && columns.map((column, i) => {
                                                    return(
                                                        <TableBodyCell key={i}>{row[column]}</TableBodyCell>
                                                    )
                                                })}
                                            </CustomRow>
                                        )
                                    })}
                                </TableBody>
                            </CustomTable>
                        </CustomTableContainer>
                    </Scrollbars>
                </TableContainer>
            </div>
        </div>
    )
}