import { useState } from "react";
import { FolderNameButton } from "../../../Mui/buttons";
import { CloseFolderIcon, OpenFolderIcon } from "../../../Mui/icons";
import FileResultInterface from "./FileResultInterface";


export default function FolderResultInterface({ folderName, files, searchTerm }) {
    const [folderOpen, setFolderOpen] = useState(true)
    const filesArray = files ? Object.values(files) : []

    return(
        <>
            <FolderNameButton
                startIcon={folderOpen ? <OpenFolderIcon /> : <CloseFolderIcon />}
                sx={{
                    background: folderOpen ? "#686868" : "transparent",
                }}
                onClick={() => setFolderOpen(!folderOpen)}
            >
                <p className="file-text">{folderName}</p>
            </FolderNameButton>
            {folderOpen &&
                <div className="files-in-folder">
                    {filesArray.length > 0 && filesArray.map((file, index) => {
                        return(
                            <FileResultInterface
                                key={index}
                                file={file}
                                searchTerm={searchTerm}
                            />
                        )
                    })}
                </div>
            }
        </>
    )
}