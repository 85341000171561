import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { newFile } from "../../../../../redux/reducers/filesDTO";
import { addTab } from "../../../../../redux/reducers/loadedFiles";
import { FileNameButton } from "../../../../Mui/buttons";
import { SingleFileIcon } from "../../../../Mui/icons";
import { NewFileInput } from "../../../../Mui/textFields";


export default function NewFileInterface({ currentFolder, setNewFile }) {
    const [newFileName, setNewFileName] = useState("");
    const currentWorkspace = useSelector(state => state.workspaces.currentWorkspace);
    const newFileRef = useRef();
    const buttonRef = useRef();
    const existingFiles = useSelector(state => state.filesDTO)
    const existingFn = Object.keys(existingFiles) ? Object.keys(existingFiles) : null


    const dispatch = useDispatch();

    function addKf(i) {
        if(!i.endsWith(".kf")) {
            return i + ".kf"
        }

        return i
    }

    function addFile(name, folder, space) {
        if(!existingFn.includes(addKf(name))) {
            dispatch(newFile({ fileName: addKf(name), folderName: folder, code: "", workSpace: space}))
            dispatch(addTab(addKf(name)))
        }
        setNewFile({});
    };

    function handleSubmit(n, f, s) {
        if (!n) {
            setNewFile({});
            return
        }

        addFile(n, f, s);
        setNewFileName("");
        setNewFile({});
    }
    
    useEffect(() => {
        newFileRef.current.focus();
    }, [])

    useEffect(() => {
        function handleClickOutside(e) {
            if(buttonRef.current && !buttonRef.current.contains(e.target)) {
                handleSubmit(newFileName, currentFolder, currentWorkspace)
            }
        }

        document.addEventListener("mousedown", handleClickOutside)

        return () => document.removeEventListener("mousedown", handleClickOutside)

    }, [buttonRef, newFileName])

    return(
        <FileNameButton
            startIcon={<SingleFileIcon />}
            ref={buttonRef}
        >
            <NewFileInput
                value={newFileName}
                inputRef={newFileRef}
                placeholder="New File"
                onChange={(e) => setNewFileName(e.target.value)}
                onKeyDown={(e) => {
                    if(e.key === "Enter") {
                        handleSubmit(newFileName, currentFolder, currentWorkspace)
                    }
                }}
            />
        </FileNameButton>
    )
}