import { SquareChecked } from "../../../Mui/icons"
import { CompileFileOption } from "../../../Mui/menus"


export default function CompileFilesInterface({ file, selectedFiles, setSelectedFiles }) {
    const fileName = file.file ? file.file : null

    function handleClick() {
        if (selectedFiles.includes(fileName)) {
            setSelectedFiles(selectedFiles.filter(file => file !== fileName))
        } else {
            setSelectedFiles([...selectedFiles, fileName])
        }
    }

    const isSelected = selectedFiles.includes(fileName)

    return(
        <>
            <CompileFileOption
                onClick={handleClick}
            >
                <SquareChecked
                    checked={isSelected}
                />
                <p className="compile-files-text">{fileName}</p>
            </CompileFileOption>
        </>
    )
}
