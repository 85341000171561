import { createAction, createReducer } from "@reduxjs/toolkit";

export const successOn = createAction('successOn')
export const successOff = createAction('successOff')
export const errorOn = createAction('errorOn')
export const errorOff = createAction('errorOff')

const initialState = {
    success: false,
    successMessage: "",
    error: false,
    errorMessage: ""
}

export const alerts = createReducer(initialState, {
    [successOn]: (state, action) => {
        return{
            success: true,
            successMessage: action.payload,
            error: state.error,
            errorMessage: state.errorMessage,
        }
    },
    [successOff]: (state) => {
        return{
            success: false,
            successMessage: "",
            error: state.error,
            errorMessage: state.errorMessage,
        }
    },
    [errorOn]: (state, action) => {
        return{
            success: state.success,
            successMessage: state.successMessage,
            error: true,
            errorMessage: action.payload,
        }
    },
    [errorOff]: (state) => {
        return{
            success: state.success,
            successMessage: state.successMessage,
            error: false,
            errorMessage: "",
        }
    },
})