import { useState } from "react"
import { FolderNameButton } from "../../../../Mui/buttons"
import { CloseFolderIcon, OpenFolderIcon } from "../../../../Mui/icons"
import FileInterface from "./FileInterface"
import NewFileInterface from "./NewFileInterface"


export default function FolderInterface({ folderName, files, currentFolder, setCurrentFolder, newFile, setNewFile, handleDragStart, handleDragOver, handleDrop }) {
    const [folderOpen, setFolderOpen] = useState(false)
    const filesArray = files ? Object.values(files) : []


    function handleClick(name) {
        if(!folderOpen) {
            setCurrentFolder(name)
        }
        
        if(folderOpen && currentFolder === folderName) {
            setCurrentFolder("")
        }

        setFolderOpen(!folderOpen)
    }

    return(
        <>
            <FolderNameButton
                startIcon={folderOpen ? <OpenFolderIcon /> : <CloseFolderIcon />}
                sx={{
                    background: folderOpen ? "#686868" : "transparent",
                }}
                onClick={() => handleClick(folderName)}
                onDragOver={(e) => handleDragOver(e)}
                onDrop={(e) => handleDrop(e, folderName)}
            >
                <p className="file-text">{folderName}</p>
            </FolderNameButton>
            {folderOpen &&
                <div 
                    className="files-in-folder"
                    onDragOver={(e) => handleDragOver(e)}
                    onDrop={(e) => handleDrop(e, folderName)}
                >
                    {filesArray.length > 0 && filesArray.map((file, index) => {
                        return(
                            <FileInterface
                                key={index}
                                fileName={file.file}
                                handleDragStart={handleDragStart}
                            />
                        )
                    })}
                    {newFile && newFile.folder === folderName &&
                        <NewFileInterface
                            currentFolder={folderName}
                            setNewFile={setNewFile}
                        />
                    }
                </div>
            }
        </>
    )
}

