import * as monaco from 'monaco-editor-core';

const pairsToAutoClose = {
    '(': ')',
    '[': ']',
    '{': '}',
    // Add more pairs if needed
};

const stringsToClose = {
    "'": "'",
}

const isEnterEvent = (change) => {
    return change === '\n' || change === '\r\n'
};

const getCurrentIndentation = (line) => {
    const leadingWhitespaceRegex = /^(\s+)/;
    const match = line.match(leadingWhitespaceRegex);
    return match ? match[0] : '';
};

function isClosingBracket(char) {
    return Object.values(pairsToAutoClose).includes(char);
}

function isClosingString(char) {
    return Object.values(stringsToClose).includes(char);
}

function skipOverCloseBracket(char, currentPos, editor) {
    const nextChar = editor.getModel().getValueInRange({
        startLineNumber: currentPos.lineNumber,
        startColumn: currentPos.column + 1,
        endLineNumber: currentPos.lineNumber,
        endColumn: currentPos.column + 2
    });

    if (nextChar === char) {
        const model = editor.getModel();
        const positionAfter = model.modifyPosition(currentPos, 1);
        editor.executeEdits(null, [
            {
                range: new monaco.Range(
                    currentPos.lineNumber,
                    currentPos.column,
                    positionAfter.lineNumber,
                    positionAfter.column
                ),
                text: '',
            }
        ]);
        
        const newPosition = {
            lineNumber: currentPos.lineNumber,
            column: currentPos.column + 1,
        };

        editor.setPosition(newPosition);
    }
}

function isNextLineClosingBracket(line) {
    let filterBreak = line.replace("\n", "")
    const leadingWhitespaceRegex = /^(\s+)/;
    const match = filterBreak.match(leadingWhitespaceRegex);
    let space = match ? match[0] : '';
    filterBreak = filterBreak.replace(space, "")
    return filterBreak
}

export const autoClosingPairs = (editor) => {
    let previousSelectedText = '';

    editor.onDidChangeCursorSelection((event) => {
      const currentSelection = event.selection;
      previousSelectedText = editor.getModel().getValueInRange(currentSelection);
    });

    return (
        editor.onDidChangeModelContent((event) => {
            const changes = event.changes;
        
            for (const change of changes) {
                const char = change.text;
                const position = change.range.getStartPosition();

                if (isClosingBracket(char) || isClosingString(char)) {
                    skipOverCloseBracket(char, position, editor);
                    continue;
                }

                const prevLine = editor.getModel().getLineContent(position.lineNumber);
                const currentIndentation = getCurrentIndentation(prevLine);
            
                if (isEnterEvent(change.text.replace(currentIndentation, ''))) {
                    const prevCharPosition = {
                        lineNumber: change.range.startLineNumber,
                        column: change.range.startColumn - 1,
                    };
        
                    const prevChar = editor.getModel().getValueInRange({
                        startLineNumber: prevCharPosition.lineNumber,
                        startColumn: prevCharPosition.column,
                        endLineNumber: prevCharPosition.lineNumber,
                        endColumn: prevCharPosition.column + 1,
                    });

                    const nextLine = editor.getModel().getValueInRange({
                        startLineNumber: position.lineNumber,
                        startColumn: position.column,
                        endLineNumber: position.lineNumber + 1,
                        endColumn: position.column
                    })

                    if (pairsToAutoClose[prevChar] && isNextLineClosingBracket(nextLine) === pairsToAutoClose[prevChar]) {
                        const tabSize = editor.getModel().getOptions().tabSize;
                        const extraIndentation = ' '.repeat(tabSize);
                        editor.executeEdits(null, [
                            {
                                range: monaco.Range.fromPositions(position, position),
                                text: '\n' + currentIndentation + extraIndentation,
                            },
                        ]);
        
                        const newPosition = {
                            lineNumber: position.lineNumber + 1,
                            column: position.column + currentIndentation.length + tabSize,
                        };
        
                        editor.setPosition(newPosition);
                    }
                } 
                
                if (pairsToAutoClose[char]) {
                    const closingChar = pairsToAutoClose[char];
                    const position = change.range.getStartPosition();
                    const nextChar = editor.getModel().getValueInRange({
                        startLineNumber: position.lineNumber,
                        startColumn: position.column + 1,
                        endLineNumber: position.lineNumber,
                        endColumn: position.column + 2,
                    });
        
                    if (nextChar !== closingChar) {
                        const newPosition = {
                            lineNumber: position.lineNumber,
                            column: position.column + 1,
                        };
        
                        editor.executeEdits(null, [
                            {
                                range: monaco.Range.fromPositions(newPosition, newPosition),
                                text: previousSelectedText + closingChar,
                            },
                        ]);
        
                        editor.setPosition(newPosition);
                    }
                }
            }
        })
    )
}

export const autoCloseStrings = (editor) => {
    let previousSelectedText = '';

    editor.onDidChangeCursorSelection((event) => {
      const currentSelection = event.selection;
      previousSelectedText = editor.getModel().getValueInRange(currentSelection);
    });

    return(
        editor.onDidChangeModelContent((event) => {
            const changes = event.changes;

            for (const change of changes) {
                const currentChar = change.text;
                
                const prevCharPosition = {
                    lineNumber: change.range.startLineNumber,
                    column: change.range.startColumn - 1,
                };
    
                const prevChar = editor.getModel().getValueInRange({
                    startLineNumber: prevCharPosition.lineNumber,
                    startColumn: prevCharPosition.column,
                    endLineNumber: prevCharPosition.lineNumber,
                    endColumn: prevCharPosition.column + 1,
                });

                if (stringsToClose[currentChar] && !stringsToClose[prevChar]) {
                    const closingChar = stringsToClose[currentChar];
                    const position = change.range.getStartPosition();
                    const nextChar = editor.getModel().getValueInRange({
                        startLineNumber: position.lineNumber,
                        startColumn: position.column + 1,
                        endLineNumber: position.lineNumber,
                        endColumn: position.column + 2,
                    });

                    if (nextChar !== closingChar) {
                        const newPosition = {
                            lineNumber: position.lineNumber,
                            column: position.column + 1,
                        };

                        editor.executeEdits(null, [
                            {
                                range: monaco.Range.fromPositions(newPosition, newPosition),
                                text: previousSelectedText + closingChar,
                            },
                        ]);

                        editor.setPosition(newPosition);
                    }
                }
                
            }
        })
    )
}