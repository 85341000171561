const sqlFunctions = 
[
    'abs',
    'coalesce',
    'glob',
    'hex',
    'ifnull',
    'iif',
    'instr',
    'length',
    'lower',
    'ltrim',
    'nullif',
    'quote',
    'replace',
    'rtrim',
    'sign',
    'substr',
    'trim',
    'typeof',
    'unhex',
    'unicode',
    'upper',
    'error',

    'ABS',
    'COALESCE',
    'GLOB',
    'HEX',
    'IFNULL',
    'IIF',
    'INSTR',
    'LENGTH',
    'LOWER',
    'LTRIM',
    'NULLIF',
    'QUOTE',
    'REPLACE',
    'RTRIM',
    'SIGN',
    'SUBSTR',
    'TRIM',
    'TYPEOF',
    'UNHEX',
    'UNICODE',
    'UPPER',
    'ERROR',
    
]

export const sqlKeywords = 
[
    ...sqlFunctions,
    'ABORT',
    'ADD',
    'ALL',
    'AND',
    'AS',
    'ASC',
    'BETWEEN',
    'BY',
    'CASE',
    'COLLATE',
    'COMMIT',
    'CONFLICT',
    'CREATE',
    'CROSS',
    'DELETE',
    'DESC',
    'DISTINCT',
    'ELSE',
    'END',
    'ESCAPE',
    'EXCEPT',
    'EXISTS',
    'FAIL',
    'FROM',
    'FULL',
    'GLOB',
    'GROUP',
    'HAVING',
    'IGNORE',
    'IN',
    'INDEXED',
    'INNER',
    'INSERT',
    'INTERSECT',
    'INTO',
    'IS',
    'ISNULL',
    'JOIN',
    'LEFT',
    'LIKE',
    'LIMIT',
    'MATCH',
    'NATURAL',
    'NOT',
    'NULL',
    'OF',
    'OFFSET',
    'ON',
    'OR',
    'ORDER',
    'OUTER',
    'RAISE',
    'REGEXP',
    'REPLACE',
    'RETURNING',
    'RIGHT',
    'ROLLBACK',
    'SELECT',
    'SET',
    'THEN',
    'UNION',
    'UPDATE',
    'USING',
    'VALUES',
    'WHEN',
    'WHERE',
    'WITH',
    'TRUE',
    'FALSE',
    'NULLS',
    'FIRST',
    'LAST',
    'FILTER',
    'GROUPS',
    'DO',
    'NOTHING',
    'abort',
    'add',
    'all',
    'and',
    'as',
    'asc',
    'between',
    'by',
    'case',
    'collate',
    'commit',
    'conflict',
    'create',
    'cross',
    'delete',
    'desc',
    'distinct',
    'else',
    'end',
    'escape',
    'except',
    'exists',
    'fail',
    'from',
    'full',
    'glob',
    'group',
    'having',
    'ignore',
    'in',
    'indexed',
    'inner',
    'insert',
    'intersect',
    'into',
    'is',
    'isnull',
    'join',
    'left',
    'like',
    'limit',
    'match',
    'natural',
    'not',
    'null',
    'of',
    'offset',
    'on',
    'or',
    'order',
    'outer',
    'raise',
    'regexp',
    'replace',
    'returning',
    'right',
    'rollback',
    'select',
    'set',
    'then',
    'union',
    'update',
    'using',
    'values',
    'when',
    'where',
    'with',
    'true',
    'false',
    'nulls',
    'first',
    'last',
    'filter',
    'groups',
    'do',
    'nothing'
]

