import styled from "@emotion/styled";
import { Table, TableCell, TableContainer, TableRow } from "@mui/material";

export const CustomTable = styled(Table)({
    width: "100%",
    maxHeight: "calc(150px - 19px)",
    overflow: "scroll",
    marginBottom: "5px",
    borderRadius: "2px",
    "&::-webkit-scrollbar": {
        width: '6px',
        height: '6px',
        display: 'none', // Optional: To hide scrollbar when not scrolling
    },
    "&::-webkit-scrollbar-track": {
        background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
        background: "transparent",
    },
    "&:hover::-webkit-scrollbar-thumb": {
        background: "#999", // Optional: Change scrollbar color on hover
    },
})

export const CustomRow = styled(TableRow)({
    "&::-webkit-scrollbar": {
        width: '6px',
        height: '6px',
        display: 'none', // Optional: To hide scrollbar when not scrolling
    },
    "&::-webkit-scrollbar-track": {
        background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
        background: "transparent",
    },
    "&:hover::-webkit-scrollbar-thumb": {
        background: "#999", // Optional: Change scrollbar color on hover
    },
})

export const TableHeaderCell = styled(TableCell)({
    background: "#B3B3B3",
    color: "#fff",
    fontFamily: "AvertaStd",
    fontSize: "13px",
    fontWeight: "300",
    border: "none",
    margin: "0px",
    padding: "0px",
    alignItems: "center",
    textAlign: "center",
    minWidth: "60px",
    maxWidth: "60px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
})

export const TableBodyCell = styled(TableCell)({
    background: "transparent",
    color: "##010101",
    fontFamily: "AvertaStd",
    fontSize: "13px",
    fontWeight: "300",
    border: "none",
    margin: "0px",
    padding: "0px",
    alignItems: "center",
    textAlign: "center",
    minWidth: "60px",
    maxWidth: "60px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
})

export const CustomTableContainer = styled(TableContainer)({
    maxHeight: "calc(150px - 19px)",
    "&::-webkit-scrollbar": {
        display: 'none', // Optional: To hide scrollbar when not scrolling
    },
    "&::-webkit-scrollbar-track": {
        background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
        background: "transparent",
    },
})