import { createAction, createReducer } from "@reduxjs/toolkit";

export const createWorkspace = createAction('newWorkspace')
export const selectWorkspace = createAction('selectWorkspace')
export const removeWorkspace = createAction('removeWorkspace')
export const resetWorkspace = createAction('resetWorkspace')

export const initialWorkspaceState = {
    workspaceList: [{name: "Default Workspace", provider: "https://longhorn.kwil.com", chainId: "longhorn"}],
    currentWorkspace: "Default Workspace"
}

export const workspaces = createReducer(initialWorkspaceState, {
    [createWorkspace]: (state, action) => {
        const { name, provider, chainId } = action.payload
        return{
            workspaceList: [...state.workspaceList, {name: name, provider: provider, chainId: chainId}],
            currentWorkspace: state.currentWorkspace
        }
    },
    [selectWorkspace]: (state, action) => {
        return{
            workspaceList: state.workspaceList,
            currentWorkspace: action.payload
        }
    },
    [removeWorkspace]: (state, action) => { 
        return{
            workspaceList: state.workspaceList.filter(
                (workspace) => workspace.name !== action.payload
            ),
            currentWorkspace: state.currentWorkspace
        }
    },
    [resetWorkspace]: () => {
        return{
            workspaceList: ["Default Workspace"],
            currentWorkspace: "Default Workspace"
        }
    },
})