import store from "./redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import persistStore from "redux-persist/es/persistStore";
import Main from "./Main";
import '@rainbow-me/rainbowkit/styles.css';
import {
  getDefaultWallets,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { configureChains, createClient, sepolia, WagmiConfig } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';

const { chains, provider } = configureChains(
  [sepolia],
  [
    publicProvider()
  ]
);

const { connectors } = getDefaultWallets({
  appName: 'Kunieform-ide',
  projectId: 'f5fc369fc5ecd416a3ec3a109a37800e',
  chains
});

const wagmiClient = createClient({
  autoConnect: false,
  connectors,
  provider,
})

export default function App() {

    let persistentStore = persistStore(store)
    return(
        <WagmiConfig client={wagmiClient}>
            <RainbowKitProvider chains={chains}>
                <Provider store={store}>
                    <PersistGate loading={<div />} persistor={persistentStore}>
                        <Main />
                    </PersistGate>
                </Provider>
            </RainbowKitProvider>
        </WagmiConfig>
        
    )
}