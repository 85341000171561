import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "./styles/background.css";
import "./styles/navBar.css";
import "./styles/sidePanel.css";
import "./styles/fonts.css";
import "./styles/codeEditor.css";
import "./styles/filesMenu.css";
import "./styles/compile.css";
import "./styles/search.css";
import "./styles/play.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
