import { kwil } from "./webKwil"

export async function broadcastDb(tx) {
    const res = await kwil.broadcast(tx);
    return res.data.tx_hash;
}

export async function buildDbTx(parsedJson, public_key, signer) {
    const tx = await kwil
        .dbBuilder()
        .payload(parsedJson)
        .publicKey(public_key)
        .description('Sign to deploy your database!')
        .signer(signer)
        .buildTx();

    return tx;
}

export async function waitForSuccess(hash) {
    return new Promise(async (resolve, reject) => {
        setTimeout(async () => {
            try {
                const res = await kwil.txInfo(hash);
                const result = res.data.tx_result.log;

                if (!result) {
                    resolve(await waitForSuccess(hash));
                    return;
                }

                if(result === 'success') {
                    // wait 500ms before resolving
                    setTimeout(() => {
                        resolve();
                    }, 1000);
                } else {
                    reject(new Error(result));
                }
            } catch (err) {
                console.log(err)
                if(err.contains && err.includes('transaction not found')) {
                    resolve(await waitForSuccess(hash));
                    return;
                }
                reject(err);
            }
        }, 1500);
    });
}


export async function dropDb(dbName, publicKey, signer) {
    const dbid = kwil.getDBID(publicKey, dbName);

    const tx = await kwil
        .dropDbBuilder()
        .payload({
            dbid
        })
        .signer(signer)
        .publicKey(publicKey)
        .description('Sign to drop your database!')
        .buildTx();

        const res = await kwil.broadcast(tx);
        return res.data.tx_hash;
}