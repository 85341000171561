import MetamaskInterface from "./SidePanel-components/MetamaskInterface";
import FilesMenu from "./SidePanel-components/FilesMenu";
import { useSelector } from "react-redux";
import Compile from "./SidePanel-components/Compile";
import Search from "./SidePanel-components/Search";
import Play from "./SidePanel-components/Play";
import { useState } from "react";
import { useGetAccount } from "../../utils/useGetAccount";
import { useAccount } from "wagmi";
import { BigNumber } from "ethers";

export default function SidePanel() {
    const [newDeployed, setNewDeployed] = useState("")
    const { address } = useAccount()

    const filesOpen = useSelector(state => state.navigation.files)
    const searchOpen = useSelector(state => state.navigation.search)
    const compileOpen = useSelector(state => state.navigation.compile)
    const playOpen = useSelector(state => state.navigation.play)

    const acct = useGetAccount(address, newDeployed)

    // round to three decimal places
    const balance = acct ? BigNumber.from(acct.balance).div(BigNumber.from(10).pow(15)).toNumber() / 1000 : null
    
    return (
        <div className="side-panel">
            <h1>Kwil</h1>
            <h2>Kuneiform IDE</h2>
            <div className="side-panel-buttons">
                <MetamaskInterface />
                {balance !== null &&
                    <div className="funding-interface">
                        <p>Balance: {balance}</p>
                    </div>
                }

            </div>
            <FilesMenu
                open={filesOpen}
            />
            <Search
                open={searchOpen}
            />
            <Compile
                open={compileOpen}
                setNewDeployed={setNewDeployed}
                balance={balance}
            />
            <Play
                open={playOpen}
                newDeployed={newDeployed}
                setNewDeployed={setNewDeployed}
            />
        </div>
    )
}