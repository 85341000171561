import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeCode } from "../../../redux/reducers/filesDTO";
import { isWithinAction, isWithinTable } from "./kuneiform-service/completion-manager/completion";
import { autoCloseStrings, autoClosingPairs } from "./kuneiform-service/styling/autoIndent";
import { triggerErrors, updateCompletionItems, setupMonacoCompletionProvider, setupEditor, setupResizeHandler, getMonacoTokenizer } from "./kuneiform-service/setup";

export default function Monaco() {
    const [divNode, setDivNode] = useState(null);
    const selectedFile = useSelector(state => state.loadedFiles.selectedFile);
    const allFiles = useSelector(state => state.filesDTO);
    const currentCode = selectedFile && allFiles[selectedFile] ? allFiles[selectedFile].code : null;

    const dispatch = useDispatch();

    const completeDataRef = useRef({
        tables: [],
        tables: [],
        actions: [],
        params: [],
        kfDefault: [],
        tableDefault: [],
        actionDefault: [],
        dbDeclaration: [],
        extensionList: []
    });

    useEffect(() => {
        if(!divNode) return;

        const provider = setupMonacoCompletionProvider(completeDataRef);

        return () => {
            provider.dispose()
        };
    }, [divNode])

    useLayoutEffect(() => {
        if(!divNode) return;

        const editor = setupEditor(divNode, currentCode); 
        const resizeListener = setupResizeHandler(editor); 
        const editorChangeListener = setupEditorChangeHandler(editor); 

        autoCloseStrings(editor);
        autoClosingPairs(editor);

        return () => {
            window.removeEventListener('resize', resizeListener);
            editor.getModel()?.dispose();
            editor.dispose();
            editorChangeListener.dispose();
        }
    }, [divNode, selectedFile]);

    function setupEditorChangeHandler(editor) {
        return editor.onDidChangeModelContent(() => {
            handleEditorChange(editor);
        })
    }

    function handleEditorChange(editor) {
        const value = editor.getValue();
        dispatchChangeCode(value);
        
        const position = editor.getPosition()
        const offset = editor.getModel()?.getOffsetAt(position)

        triggerErrors(editor, value)

        const isInAction = isWithinAction(value, offset)
        const isInTable = isWithinTable(value, offset)

        completeDataRef.current = updateCompletionItems(editor, value, isInAction, isInTable);
    }

    function dispatchChangeCode(value) {
        dispatch(changeCode({ fileName: selectedFile, code: value }));
    }

    return(
        <div className="monaco">
            <div ref={setDivNode} style={{ height: 'calc(100% - 4px)'}}></div>
        </div>
    )
}