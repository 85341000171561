import styled from "@emotion/styled";
import { Button } from "@mui/material";


export const MetamaskButton = styled(Button)({
    background: "rgba(217, 217, 217, 0.5)",
    borderRadius: "100px",
    color: "white",
    fontFamily: "AvertaStd",
    fontWeight: "300",
    width: "100%",
    height: "36px",
    fontSize: "13px",
    lineHeight: "15px",
    textTransform: "none",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    "&:hover": {
        background: "rgba(217, 217, 217, 0.5)",
    },
})

export const FundingPoolButton = styled(Button)({
    color: "white",
    fontFamily: "AvertaStd",
    fontWeight: "300",
    width: "110px",
    height: "36px",
    fontSize: "13px",
    lineHeight: "15px",
    textTransform: "none",
    "&:hover": {
        background: "rgba(217, 217, 217, 0.25)",
        
    }
})

export const IDETabButton = styled(Button)({
    borderRadius: "8px 8px 0px 0px",
    minWidth: "132px",
    width: "auto",
    height: "33.33px",
    padding: "0px",
    textTransform: "none",
    margin: "2.75px 0px 0px 0px",
    "& .MuiTouchRipple-root": {
        color: "transparent"
    },
    "&:hover": {
        background: "#272727",
    },
    "&:active": {
        opacity: "0.7"
    }
})

export const SideBarButton = styled(Button)({
    height: "35px",
    minWidth: "43px",
    background: "transparent",
    padding: "0px",
    margin: "10px auto 0px auto",
    display: "flex",
    "&:hover": {
        background: "transparent",
    },
    "& .MuiTouchRipple-root": {
        color: "#fff"
    }
})

export const ConfirmWorkspaceButton = styled(Button)({
    minWidth: "36px",
    width: "36px",
    height: "23px",
    minHeight: "23px",
    background: "transparent",
    border: "1px solid #118EA0",
    borderRadius: "2px",
    padding: "4px auto",
    margin: "0px 0px 0px auto",
    color: "#fff",
    fontFamily: "AvertaStd",
    fontWeight: "300",
    fontSize: "13px",
    lineHeight: "15px",
    textTransform: "none",
    "&:hover": {
        background: "transparent",
    },
    "& .MuiTouchRipple-root": {
        color: "#fff"
    }
})

export const ConfirmDropDbButton = styled(Button)({
    minWidth: "auto",
    width: "auto",
    height: "23px",
    minHeight: "23px",
    background: "transparent",
    border: "1px solid #118EA0",
    borderRadius: "2px",
    padding: "4px auto",
    margin: "0px 0px 0px auto",
    color: "#fff",
    fontFamily: "AvertaStd",
    fontWeight: "300",
    fontSize: "13px",
    lineHeight: "15px",
    textTransform: "none",
    "&:hover": {
        background: "transparent",
    },
    "& .MuiTouchRipple-root": {
        color: "#fff"
    }
})


export const CancelWorkspaceButton = styled(Button)({
    minWidth: "77px",
    width: "77px",
    height: "23px",
    minHeight: "23px",
    background: "transparent",
    border: "1px solid #fff",
    borderRadius: "2px",
    padding: "4px auto",
    margin: "0px 0px 0px 13px",
    color: "#fff",
    fontFamily: "AvertaStd",
    fontWeight: "300",
    fontSize: "13px",
    lineHeight: "15px",
    textTransform: "none",
    "&:hover": {
        background: "transparent",
    },
    "& .MuiTouchRipple-root": {
        color: "#fff"
    }
})

export const RemoveWorkspaceButton = styled(Button)({
    width: "100%",

    background: "transparent",
    borderRadius: "2px",
    padding: "4px auto",
    margin: "0px",
    color: "#fff",
    fontFamily: "AvertaStd",
    fontWeight: "300",
    fontSize: "13px",
    lineHeight: "15px",
    textTransform: "none",
    "&:hover": {
       border: "1px solid #fff"
    },
    "& .MuiTouchRipple-root": {
        color: "transparent"
    },
})

export const FolderNameButton = styled(Button)({
    width: "100%",
    height: "18px",
    minHeight: "18px",
    borderRadius: "0px",
    color: "#fff",
    margin: "1px 0px",
    "& .MuiTouchRipple-root": {
        color: "transparent"
    },
    "&:hover": {
        background: "#686868",
    },
    "& .MuiButton-startIcon": {
        width: "20px",
        display: "flex"
    },
    "&:active": {
        opacity: "0.5"
    }
})

export const FileNameButton = styled(Button)({
    width: "100%",
    height: "18px",
    minHeight: "18px",
    background: "transparent",
    borderRadius: "0px",
    margin: "1px 0px",
    color: "#fff",
    "& .MuiTouchRipple-root": {
        color: "transparent"
    },
    "&:hover": {
        background: "#686868",
    },
    "& .MuiButton-startIcon": {
        width: "20px",
        display: "flex"
    },
    "&:active": {
        opacity: "0.5"
    }
})

export const CompiledFileButton = styled(Button)({
    width: "100%",
    height: "20px",
    minHeight: "18px",
    borderRadius: "3px",
    margin: "0px 0px",
    color: "#fff",
    "& .MuiTouchRipple-root": {
        color: "transparent"
    },
    "&:hover": {
        background: "#686868",
    },
    "& .MuiButton-startIcon": {
        width: "20px",
        display: "flex"
    },
    "&:active": {
        opacity: "0.5"
    }
})

export const NewFileButton = styled(Button)({
    width: 'auto',
    minWidth: "30px",
    height: 'auto',
    background: 'transparent',
    borderRadius: '0px',
    margin: '0px',
    padding: '0px',
    "&:hover": {
        background: "transparent",
    },
    "& .MuiTouchRipple-root": {
        color: "transparent"
    }
})

export const CompileButton = styled(Button)({
    width: "106px",
    height: "32px",
    background: "#118EA0",
    borderRadius: "2px",
    color: "#fff",
    fontFamily: "AvertaStd",
    fontWeight: "300",
    fontSize: "16px",
    textTransform: "none",
    padding: "0px",
    margin: "0px 7px 6px 7px",
    "&:hover": {
        background: "#0E7988",
    },
    "& .MuiTouchRipple-root": {
        color: "#fff"
    }
})

export const DeployButton = styled(Button)({
    width: "225px",
    height: "32px",
    background: "#118EA0",
    borderRadius: "2px",
    color: "#fff",
    fontFamily: "AvertaStd",
    fontWeight: "300",
    fontSize: "16px",
    textTransform: "none",
    padding: "0px",
    margin: "0px 7px 6px 7px",
    "&:hover": {
        background: "#0E7988",
    },
    "& .MuiTouchRipple-root": {
        color: "#fff"
    }
})

export const FileResultButton = styled(Button)({
    width: "100%",
    maxWidth: "calc(100% - 14px)",
    height: "18px",
    minHeight: "18px",
    background: "transparent",
    borderRadius: "0px",
    margin: "1px 0px",
    color: "#fff",
    display: "flex",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "& .MuiTouchRipple-root": {
        color: "transparent"
    },
    "&:hover": {
        background: "#686868",
    },
    "& .MuiButton-startIcon": {
        width: "20px",
        display: "flex"
    },
    "&:active": {
        opacity: "0.5"
    }
})

export const AddFundsButton = styled(Button)({
    width: "auto",
    height: "16px",
    minHeight: "16px",
    background: "transparent",
    borderRadius: "0px",
    margin: "2px auto 7px 0px",
    color: "#fff",
    fontFamily: "AvertaStd",
    fontWeight: "300",
    fontSize: "12px",
    padding: "0px",
    textTransform: "none",
    "& .MuiTouchRipple-root": {
        color: "transparent"
    },
    "&:hover": {
        background: "transparent",
        textDecoration: "underline"
    },
    "& .MuiButton-endIcon": {
        margin: "0px 0px 0px 2px"
    },
})

export const ConfirmFundsButton = styled(Button)({
    width: "13px",
    minWidth: "13px",
    height: "13px",
    minHeight: "13px",
    background: "#dddddd",
    "& .MuiTouchRipple-root": {
        color: "transparent"
    },
    "&:hover": {
        background: "#CECECE",
        textDecoration: "underline"
    },
})

export const ExecuteActionButton = styled(Button)({
    width: "auto",
    height: "18px",
    minHeight: "18px",
    borderRadius: "2px",
    margin: "0px 4px 6px auto",
    background: "rgba(17, 142, 160, 0.75)",
    "& .MuiTouchRipple-root": {
        color: "transparent"
    },
    "&:hover": {
        background: "rgba(12, 114, 129, 0.75)",
    },
})

export const DropDbButton = styled(Button)({
    height: "24px",
    width: "100%",
    minHeight: "24px",
    borderRadius: "2px",
    background: "#FF6961",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
    marginBottom: "5px",
    fontFamily: "AvertaStd",
    fontWeight: "300",
    textTransform: "none",
    fontSize: "14px",
    color: "#fff",
    "& .MuiTouchRipple-root": {
        color: "transparent"
    },
    "&:hover": {
        background: "#D0574F",
    },
    "&:active": {
        opacity: "0.8"
    }
});