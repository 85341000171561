import { useDispatch, useSelector } from "react-redux";
import { deleteFile } from "../../../../../redux/reducers/filesDTO";
import { removeTab, selectFile } from "../../../../../redux/reducers/loadedFiles";
import { RemoveWorkspaceButton } from "../../../../Mui/buttons";


export default function FileContextMenu({ left, top, currentFile }) {
    const dispatch = useDispatch();

    const tabFiles = useSelector(state => state.loadedFiles.tabFiles);

    function detNewFileIndex(i) {
        if (i >= 0) {
            return i
        }

        return 1   
    }

    function removeFiles(n) {
        dispatch(deleteFile(n))

        if(tabFiles.includes(n)) {
            const fileIndex = detNewFileIndex(tabFiles.indexOf(n))
            dispatch(selectFile(tabFiles[fileIndex - 1]))
            dispatch(removeTab(n))
        }
    }

    return(
        <div
            className="menu-context-container"
            style={{
                left: left,
                top: top
            }}
        >
            <RemoveWorkspaceButton
                onClick={() => removeFiles(currentFile)}
            >
                Delete File
            </RemoveWorkspaceButton>
        </div>
    )
}