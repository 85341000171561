import { useDispatch, useSelector } from "react-redux";
import { deleteFile } from "../../../../redux/reducers/filesDTO";
import { removeWorkspace, selectWorkspace } from "../../../../redux/reducers/workspaces";
import { RemoveWorkspaceButton } from "../../../Mui/buttons";


export default function ContextMenu({ left, top, rightClickedWorkspace }) {
    const allFiles = useSelector(state => state.filesDTO);

    const dispatch = useDispatch();

    function removeFilesFromWorkspace(selected) {
        for (let file in allFiles) {
            if(allFiles[file].workSpace === selected) {
                dispatch(deleteFile(file))
            }
        }
    }

    function deleteWorkspace(selected) {
        dispatch(removeWorkspace(selected))
        removeFilesFromWorkspace(selected)
        dispatch(selectWorkspace("Default Workspace"))
    }

    return(
        <div 
            className="menu-context-container"
            style={{
                left: left,
                top: top
            }}
            onClick={() => deleteWorkspace(rightClickedWorkspace)}
        >
            <RemoveWorkspaceButton>Remove Workspace</RemoveWorkspaceButton>
        </div>
    )
    
}