import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addTab, selectFile } from "../../../../redux/reducers/loadedFiles";
import { FileNameButton, FileResultButton } from "../../../Mui/buttons";
import { FileResultIcon } from "../../../Mui/icons";


export default function FileResultInterface({ file, searchTerm }) {
    const [expanded, setExpanded] = useState(true)
    const fileName = file.file ? file.file : null
    const tabFiles = useSelector(state => state.loadedFiles.tabFiles)

    const dispatch = useDispatch();
    
    function renderHighlightedText(text, keyword) {
        const regex = new RegExp(`(${keyword})`, "gi");
        const parts = text.split(regex);

        return parts.map((part, index) => {
            if(part.toLowerCase() === keyword.toLowerCase()) {
                return <span key={`highlight-${index}`} className="highlight">{part}</span>
            }
            return part
        })
    }

    function handleClick(n) {
        if(tabFiles.includes(n)) {
            dispatch(selectFile(n))
            return
        }

        dispatch(addTab(n))
    }
   
    return(
        <>
            <FileNameButton
                startIcon={<FileResultIcon expanded={expanded} />}
                onClick={() => setExpanded(!expanded)}
            >
                <p className="file-text">{file.file}</p>
            </FileNameButton>
            {expanded &&
                <div className="file-results-container">
                    {file.results.length > 0 && file.results.map((result, index) => {
                        const searchResult = renderHighlightedText(result, searchTerm)
                        return(
                            <FileResultButton
                                key={index}
                                onClick={() => handleClick(fileName)}
                            >
                                <p className="file-text">
                                    {searchResult}
                                </p>
                            </FileResultButton>
                        )})}
                </div>
            }
        </>
    )
}