// Generated from KuneiformParser.g4 by ANTLR 4.12.0
// jshint ignore: start
import antlr4 from 'antlr4';

// This class defines a complete generic visitor for a parse tree produced by KuneiformParser.

export default class KuneiformParserVisitor extends antlr4.tree.ParseTreeVisitor {

	// Visit a parse tree produced by KuneiformParser#source_unit.
	visitSource_unit(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by KuneiformParser#database_directive.
	visitDatabase_directive(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by KuneiformParser#extension_directive.
	visitExtension_directive(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by KuneiformParser#ext_config_list.
	visitExt_config_list(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by KuneiformParser#ext_config.
	visitExt_config(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by KuneiformParser#annotation_decl.
	visitAnnotation_decl(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by KuneiformParser#annotation_attr_list.
	visitAnnotation_attr_list(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by KuneiformParser#annotation_attr.
	visitAnnotation_attr(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by KuneiformParser#table_decl.
	visitTable_decl(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by KuneiformParser#column_def.
	visitColumn_def(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by KuneiformParser#column_def_list.
	visitColumn_def_list(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by KuneiformParser#column_type.
	visitColumn_type(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by KuneiformParser#column_constraint.
	visitColumn_constraint(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by KuneiformParser#literal_value.
	visitLiteral_value(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by KuneiformParser#number_value.
	visitNumber_value(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by KuneiformParser#index_def.
	visitIndex_def(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by KuneiformParser#foreign_key_action.
	visitForeign_key_action(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by KuneiformParser#foreign_key_def.
	visitForeign_key_def(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by KuneiformParser#action_visibility.
	visitAction_visibility(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by KuneiformParser#action_mutability.
	visitAction_mutability(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by KuneiformParser#action_auxiliary.
	visitAction_auxiliary(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by KuneiformParser#action_attr_list.
	visitAction_attr_list(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by KuneiformParser#action_decl.
	visitAction_decl(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by KuneiformParser#param_list.
	visitParam_list(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by KuneiformParser#parameter.
	visitParameter(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by KuneiformParser#database_name.
	visitDatabase_name(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by KuneiformParser#extension_name.
	visitExtension_name(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by KuneiformParser#ext_config_name.
	visitExt_config_name(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by KuneiformParser#table_name.
	visitTable_name(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by KuneiformParser#action_name.
	visitAction_name(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by KuneiformParser#column_name.
	visitColumn_name(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by KuneiformParser#column_name_list.
	visitColumn_name_list(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by KuneiformParser#index_name.
	visitIndex_name(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by KuneiformParser#annotation_target_name.
	visitAnnotation_target_name(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by KuneiformParser#annotation_attr_name.
	visitAnnotation_attr_name(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by KuneiformParser#annotation_attr_value.
	visitAnnotation_attr_value(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by KuneiformParser#ext_config_value.
	visitExt_config_value(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by KuneiformParser#init_decl.
	visitInit_decl(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by KuneiformParser#action_stmt_list.
	visitAction_stmt_list(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by KuneiformParser#action_stmt.
	visitAction_stmt(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by KuneiformParser#sql_stmt.
	visitSql_stmt(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by KuneiformParser#call_stmt.
	visitCall_stmt(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by KuneiformParser#call_receivers.
	visitCall_receivers(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by KuneiformParser#call_body.
	visitCall_body(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by KuneiformParser#variable.
	visitVariable(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by KuneiformParser#block_var.
	visitBlock_var(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by KuneiformParser#extension_call_name.
	visitExtension_call_name(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by KuneiformParser#fn_name.
	visitFn_name(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by KuneiformParser#sfn_name.
	visitSfn_name(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by KuneiformParser#fn_arg_list.
	visitFn_arg_list(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by KuneiformParser#fn_arg_expr.
	visitFn_arg_expr(ctx) {
	  return this.visitChildren(ctx);
	}



}