import { createAction, createReducer } from "@reduxjs/toolkit";

export const toFiles = createAction('toFiles')
export const toSearch = createAction('toSearch')
export const toCompile = createAction('toCompile')
export const toPlay = createAction('toPlay')

export const initialNavigationState = {
    files: true,
    search: false,
    compile: false,
    play: false
}

export const navigation = createReducer(initialNavigationState, {
    [toFiles]: () => {
        return{
            files: true,
            search: false,
            compile: false,
            play: false
        }
    },
    [toSearch]: () => {
        return{
            files: false,
            search: true,
            compile: false,
            play: false
        }
    },
    [toCompile]: () => {
        return{
            files: false,
            search: false,
            compile: true,
            play: false
        }
    },
    [toPlay]: () => {
        return{
            files: false,
            search: false,
            compile: false,
            play: true
        }
    }
})