import styled from "@emotion/styled";
import { Alert, Snackbar } from "@mui/material";


export const CustomSnackbar = styled(Snackbar)({
    margin: "0px auto"
})

export const CustomAlert = styled(Alert)({
    width: "100%",
})