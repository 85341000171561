import { InputBase } from "@mui/material";
import { CompileDisabledOption, CompileFilesDropDown, DeployedDatabaseOption } from "../../../Mui/menus";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getDatabases } from "../../../../utils/playFunctions";
import { useAccount } from "wagmi";

export default function SelectPlayMenu({ selectedDb, setSelectedDb, newDeployed, refresh }) {
    const [databases, setDatabases] = useState([])
    const { address } = useAccount()

    const currentWorkspace = useSelector(state => state.workspaces.currentWorkspace)

    async function setDbs() {
        const res = await getDatabases(address)
        setDatabases(res)
    }

    useEffect(() => {
        if(address) setDbs()
        if(!address) {
            setDatabases([])
            setSelectedDb("")
        }
    }, [address, currentWorkspace, refresh])

    useEffect(() => {
        if(newDeployed) setDbs()
    }, [newDeployed])

    useEffect(() => {
        setSelectedDb("")
    }, [currentWorkspace])

    return(
        <>
            <CompileFilesDropDown
                displayEmpty
                value={selectedDb}
                input={<InputBase />}
                IconComponent={UnfoldMoreIcon}
                MenuProps={{
                    PaperProps: {
                        style: {
                            background: "rgba(139, 139, 139, 0.9)",
                        }
                    },
                    style: { zIndex: 99 },
                }}
                onChange={(e) => setSelectedDb(e.target.value)}
            >
                <CompileDisabledOption value="" disabled>
                    Select Deployed Database
                </CompileDisabledOption>
                {databases && databases.length > 0 && databases.map((db, i) => {
                    return(
                        <DeployedDatabaseOption
                            key={i}
                            value={db}
                        >
                            {db}
                        </DeployedDatabaseOption>
                    )
                })}
            </CompileFilesDropDown>
        </>
    )
}
