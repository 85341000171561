import { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { useSelector } from "react-redux";
import { arrangeFiles } from "../../../utils/fileHelpers";
import { SearchInput } from "../../Mui/textFields";
import FileResultInterface from "./Search-components/FileResultInterface";
import FolderResultInterface from "./Search-components/FolderResultInterface";


export default function Search({ open }) {
    const [searchTerm, setSearchTerm] = useState("")
    const allFiles = useSelector(state => state.filesDTO)
    const currentWorkspace = useSelector(state => state.workspaces.currentWorkspace);
    const [organizedResults, setOrganizedResults] = useState([])
    const [unorganizedResults, setUnorganizedResults] = useState([])

    function searchKeywordInFile(files, keyword) {
        function searchWithContext(text, keyword) {
            function searchInLine(line, keyword) {
                const words = line.split(" ");
                const result = [];
                const keywordWords = keyword.split(" ");
            
                for (let i = 0; i < words.length; i++) {
                    let match = true;
                    for (let j = 0; j < keywordWords.length; j++) {
                        if (!words[i + j] || !words[i + j].includes(keywordWords[j])) {
                            match = false;
                            break;
                        }
                    }
            
                if (match) {
                    const startIndex = Math.max(i - 2, 0);
                    const endIndex = Math.min(i + keywordWords.length + 2, words.length);
                    const contextWords = words.slice(startIndex, endIndex);
                    result.push(contextWords.join(" "));
                }}
            
                return result;

            }

            const lines = text.split(/\r?\n/);
            let result = [];

            for (const line of lines) {
                const lineResults = searchInLine(line, keyword);
                result = result.concat(lineResults);
            }

            return result
        }

        let resultFiles = []

        for (const fileKey in files) {
            const fileObj = files[fileKey]
            const searchResult = searchWithContext(fileObj.code, keyword)
            if(searchResult.length > 0) {
                resultFiles[fileKey] = {
                    ...fileObj,
                    results: searchResult
                }
            }
        }

        return resultFiles
    }


    useEffect(() => {
        if(searchTerm === "") {
            setOrganizedResults([])
            setUnorganizedResults([])
            return
        }
        const results = searchKeywordInFile(allFiles, searchTerm)
        const files = arrangeFiles(results, currentWorkspace)
        setOrganizedResults(files.organizedFileArray)
        setUnorganizedResults(files.unorganized)
    }, [allFiles, searchTerm, currentWorkspace])

    return(
        <div
            className="search-menu"
            style={{display: open ? "flex" : "none"}}
        >
            <SearchInput 
                placeholder="Search"
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
            />
            <Scrollbars
                autoHeight
                autoHeightMin={0}
                autoHeightMax={"calc(100vh - 148px)"}
            >
                <div className="search-results">
                    {organizedResults.length > 0 && organizedResults.map((folder, index) => {
                        return(
                            <FolderResultInterface 
                                key={index}
                                folderName={Object.keys(folder)[0]}
                                files={folder[Object.keys(folder)[0]]}
                                searchTerm={searchTerm}
                            />
                        )
                    })}
                    {unorganizedResults.length > 0 && unorganizedResults.map((file, index) => {
                        return(
                            <FileResultInterface
                                key={index}
                                file={file}
                                searchTerm={searchTerm}
                            />
                        )
                    })}
                </div>
            </Scrollbars>
            
        </div>
    )
}