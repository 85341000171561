const tokenPrice = `database token_data;

//Below is a sample database to track the prices of certain digital assets
//The first table is to track the price of btc
table btc_price {
    id int primary notnull,
    date_of_price text notnull,
    price text notnull,
    submitted_by_wallet text notnull
}

action add_btc ($id, $date, $price) public {
    INSERT INTO btc_price
    VALUES ($id, $date, $price, @caller);
}

// private actions below can only be executed by the wallet that deploys the database
action update_my_btc ($id, $price) public {
    UPDATE btc_price SET price = $price WHERE id = $id AND submitted_by_wallet = @caller;
}

action update_btc ($price, $id) private {
    UPDATE btc_price SET price = $price WHERE id = $id;
}

action delete_btc ($id, $date) private {
    DELETE FROM btc_price WHERE id = $id AND date_of_price = $date;
}

action get_btc () private {
    SELECT * FROM btc_price;
}

// the second table is to track the price of eth
table eth_price {
    id int primary notnull,
    date_of_price text notnull,
    price text notnull,
    avg_gas_fee text notnull,
    btc_price_at_day text,
    #price_fee_index unique(price, avg_gas_fee)
}

// actions below to modify the eth_price table. Notice the SELECT query that references the btc_price table.
action add_eth ($id, $date, $price, $gas) public {
    INSERT INTO eth_price VALUES ( $id , $date , $price , $gas , ( SELECT price FROM btc_price WHERE date_of_price = $date ) );
}

action update_eth ($price, $id) private {
    UPDATE eth_price SET price = $price WHERE id = $id;
}

action delete_eth ($id, $date) private {
    DELETE FROM eth_price WHERE id = $id AND date_of_price = $date;
}

action list_eth () private {
    SELECT * FROM eth_price;
}

// the third table is to track the price of AR.
table ar_price {
    id int primary notnull,
    date_of_price text notnull,
    price text notnull,
    daily_transaction_count text notnull,
    wallet text notnull,
    #transaction_index index(daily_transaction_count)
}

action add_ar ($id, $date, $price, $txcount) public {
    INSERT INTO ar_price VALUES ( $id , $date , $price , $txcount , @caller );
}

action update_ar_price ($id, $price) private {
    UPDATE ar_price SET price = $price WHERE id = $id;
}

action update_ar_tx ($id, $txcount) private {
    UPDATE ar_price SET daily_transaction_count = $txcount WHERE id = $id;
}

action delete_ar ($id) private {
    DELETE FROM ar_price WHERE id = $id;
}`

export { tokenPrice }