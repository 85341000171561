import { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { useAccount, useSigner } from "wagmi";
import { getSchema, isJsonError } from "../../../utils/playFunctions";
import PlayAction from "./Play-components/PlayActions";
import PlayTable from "./Play-components/PlayTable";
import QueryResTable from "./Play-components/QueryResTable";
import SelectPlayMenu from "./Play-components/SelectPlayMenu";
import { DropDbButton } from "../../Mui/buttons";
import DropDbModal from "./Play-components/DropDbModal";
import { dropDb, waitForSuccess } from "../../../utils/deployFunctions";
import { useDispatch } from "react-redux";
import { errorOn, successOff, successOn } from "../../../redux/reducers/alerts";

export default function Play({ open, newDeployed, setNewDeployed }) {
    const [selectedDb, setSelectedDb] = useState("")
    const [refresh, setRefresh] = useState(0)

    return (
        <div
            className="play"
            style={{ display: open ? "flex" : "none" }}
        >
            <SelectPlayMenu
                selectedDb={selectedDb}
                setSelectedDb={setSelectedDb}
                newDeployed={newDeployed}
                refresh={refresh}
            />
            <ActionBody
                selectedDb={selectedDb}
                setSelectedDb={setSelectedDb}
                refresh={refresh}
                setRefresh={setRefresh}
                setNewDeployed={setNewDeployed}
            />
        </div>
    )
}

function ActionBody({ selectedDb, setSelectedDb, refresh, setRefresh, setNewDeployed }) {
    const [loadedDb, setLoadedDb] = useState("")
    const [tables, setTables] = useState([])
    const [actions, setActions] = useState([])
    const [dataFromAction, setDataFromAction] = useState([])
    const [actionExecutedCount, setActionExecutedCount] = useState(0)
    const [dropDbModal, setDropDbModal] = useState(false)
    const { address } = useAccount();
    const { data: signer } = useSigner();
    const dispatch = useDispatch();

    async function executeDrop() {
        try {
            const hash = await dropDb(loadedDb, address, signer)
            dispatch(successOn(`Database dropping, this may take a few seconds... Hash: ${hash}`))
            await waitForSuccess(hash)
            dispatch(successOff())
            setDropDbModal(false)
            setSelectedDb("")
            setRefresh(refresh + 1)
            setNewDeployed(refresh + 1)
            dispatch(successOn("Database successfully dropped!"))
        } catch (error) {
            console.log(error)
            const message = isJsonError(error)
            setNewDeployed(refresh + 1)
            dispatch(errorOn(message))
        }
    }

    useEffect(() => {
        async function retDb() {
            if (!selectedDb) return
            const { data } = await getSchema(address, selectedDb)
            setTables(data.tables)
            setActions(data.actions)
            setLoadedDb(selectedDb)
            setDataFromAction([])
        }

        if (selectedDb && address) retDb()

    }, [selectedDb, address, refresh])

    useEffect(() => {
        if (!address) {
            setTables([])
            setActions([])
            setLoadedDb("")
            setSelectedDb("")
        }

    }, [address])

    return (
        <>
            {!selectedDb ?
                <>
                    <p className="test-message">Test existing deployed Databases</p>
                    <p className="wallet-message">Make sure your wallet is connected!</p>
                </> :
                <div
                    className="test-area"
                >
                    <Scrollbars
                        autoHeight
                        autoHeightMin={0}
                        autoHeightMax={"calc(100vh - 130px)"}
                    >
                        {tables.length > 0 && tables.map((table, i) => {
                            return (
                                <PlayTable
                                    key={i}
                                    table={table}
                                    owner={address}
                                    db={loadedDb}
                                    actionExecutedCount={actionExecutedCount}
                                />
                            )
                        })}
                        {dataFromAction && dataFromAction.length > 0 &&
                            <QueryResTable
                                dataFromAction={dataFromAction}
                            />
                        }
                        {actions.length > 0 && actions.map((action, i) => {
                            return (
                                <PlayAction
                                    key={i}
                                    action={action}
                                    owner={address}
                                    db={selectedDb}
                                    setDataFromAction={setDataFromAction}
                                    actionExecutedCount={actionExecutedCount}
                                    setActionExecutedCount={setActionExecutedCount}
                                    setNewDeployed={setNewDeployed}
                                />
                            )
                        })}
                        <DropDbButton
                            onClick={() => setDropDbModal(true)}
                        >
                            Drop Database
                        </DropDbButton>
                    </Scrollbars>
                    <DropDbModal
                        openModal={dropDbModal}
                        setOpenModal={setDropDbModal}
                        dropDbFunc={executeDrop}
                    />
                </div>
            }
        </>
    )
}