import styled from "@emotion/styled";
import { TextField } from "@mui/material";


export const WorkspaceNameInput = styled(TextField)({
    background: "#6B6B82",
    width: "calc(100% - 54px)",
    height: "25px",
    borderRadius: "5px",
    border: 'transparent',
    display: "flex",
    padding: "0px",
    margin: "6px 27px 0px 27px",
    "& .MuiOutlinedInput-notchedOutline": {
        border: 'transparent',
    },
    "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
            border: 'transparent',
        },
    },
    input: {
        padding: "0px 10px",
        color: "#fff",
        fontFamily: "AvertaStd",
        fontWeight: "300",
        fontSize: "13px",
        lineHeight: "25px",
        height: "25px",
        textAlign: "horizontal",
    },
})

export const NewFileInput = styled(TextField)({
    width: "70%",
    height: "18px",
    padding: "0px",
    border: "transparent",
    background: "#8B8B8B",
    borderRadius: "2px",
    margin: "0px auto 0px 0px",
    input: {
        padding: "0px 5px",
        color: "#fff",
        fontFamily: "AvertaStd",
        fontWeight: "300",
        fontSize: "12px",
        lineHeight: "16px",
        height: "18px",
        border: "transparent",
    },
    "& .MuiOutlinedInput-notchedOutline": {
        border: 'transparent',
    },
    "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
            border: 'transparent',
        },
    },
})

export const SearchInput = styled(TextField)({
    width: "calc(100% - 14px)",
    height: "25px",
    borderRadius: "5px",
    margin: "0px 7px",
    background: "#8B8B8B",
    border: "transparent",
    input: {
        padding: "0px 10px",
        color: "#fff",
        fontFamily: "AvertaStd",
        fontWeight: "300",
        fontSize: "14px",
        lineHeight: "25px",
        height: "25px",
        border: "transparent",
    },
    "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
            border: 'transparent',
        },
    },
})

export const FundingInput = styled(TextField)({
    width: "auto",
    height: "20px",
    borderRadius: "2px",
    margin: "2px 0px 7px 0px",
    background: "#BBBABA",
    border: "transparent",
    input: {
        padding: "0px 0px 0px 5px",
        color: "#fff",
        fontFamily: "AvertaStd",
        fontWeight: "300",
        fontSize: "12px",
        lineHeight: "20px",
        height: "20px",
        border: "transparent",
    },
    "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
            border: 'transparent',
        },
    },
    "& .MuiInputBase-root": {
        width: "100%",
        padding: "0px 5px 0px 0px"
    },
     '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      'WebkitAppearance': 'none',
      margin: 0,
    },
    '& input[type=number]': {
      'MozAppearance': 'textfield',
    },
})

export const ActionInput = styled(TextField)({
    width: "50%",
    height: "16px",
    marginLeft: "1px",
    background: "transparent",
    border: "transparent",
    input: {
        padding: "0px 0px 0px 5px",
        color: "#fff",
        fontFamily: "AvertaStd",
        fontWeight: "300",
        fontSize: "12px",
        lineHeight: "16px",
        height: "20px",
        border: "transparent",
    },
    "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
            border: 'none',
        },
    },
    "& .MuiInputBase-root": {
        width: "100%",
        height: "16px",
        padding: "0px 5px 0px 0px",
        border: "none !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
        border: 'none',
    }
})