import { useEffect, useRef, useState } from "react";
import { FolderNameButton } from "../../../../Mui/buttons";
import { CloseFolderIcon } from "../../../../Mui/icons";
import { NewFileInput } from "../../../../Mui/textFields";


export default function NewFolderInterface({ setCreatedFolder, setNewFolder }) {
    const [newFolderName, setNewFolderName] = useState("");
    const newFolderRef = useRef(null)
    const buttonRef = useRef(null)

    function handleSubmit(name) {
        if(!name) {
            setNewFolder(false)
            return
        }

        setCreatedFolder(name)
        setNewFolderName("")
        setNewFolder(false)
    }

    useEffect(() => {
        newFolderRef.current.focus()
    }, [])

    useEffect(() => {
        function handleClickOutside(e) {
            if(buttonRef.current && !buttonRef.current.contains(e.target)) {
                handleSubmit(newFolderName)
            }
        }

        document.addEventListener("mousedown", handleClickOutside)

        return () => document.removeEventListener("mousedown", handleClickOutside)
        
    }, [buttonRef, newFolderName])

    return(
        <FolderNameButton
            startIcon={<CloseFolderIcon />}
            ref={buttonRef}
        >
            <NewFileInput
                value={newFolderName}
                inputRef={newFolderRef}
                placeholder="New Folder"
                onChange={(e) => setNewFolderName(e.target.value)}
                onKeyDown={(e) => {
                    if(e.key === "Enter") {
                        handleSubmit(newFolderName)
                    }
                }}
            />
        </FolderNameButton>
    )
}