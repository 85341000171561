import styled from '@emotion/styled';
import fileSelected from '../../assets/file-selected.svg';
import fileUnselected from '../../assets/file-unselected.svg';
import SearchIcon from '@mui/icons-material/Search';
import kformSelected from '../../assets/kform-selected.svg';
import kformUnselected from '../../assets/kform-unselected.svg';
import LaunchIcon from '@mui/icons-material/Launch';
import metamaskFox from '../../assets/metamask-icon.svg';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import AddFolderIcon from '../../assets/add-folder-icon.svg'
import AddFileIcon from '../../assets/add-file-icon.svg'
import FolderIcon from '@mui/icons-material/Folder';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import SingleFile from "../../assets/single-file-icon.svg";
import WhiteFeather from "../../assets/kwil-white-feather.svg";
import CheckedBox from "../../assets/checked-box.svg";
import UncheckedBox from "../../assets/unchecked-box.svg";
import ExpandFileResult from "../../assets/expand-file-result.svg";
import CloseFileResult from "../../assets/close-file-result.svg";
import CheckIcon from '@mui/icons-material/Check';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import { ExpandMore } from "@mui/icons-material"
import { CircularProgress } from '@mui/material';

export function FileIcon({ selected }) {
    return(
        selected ? <img src={fileSelected} className="nav-icon" alt='file icon' /> : <img src={fileUnselected} className="nav-icon" alt='file icon' />
    )
}

const CustomSearchIcon = styled(SearchIcon)({
    height: "27px",
    cursor: "pointer",
    '&:hover': {
        opacity: "0.8"
    }
})

export function CombinedSearchIcon({ selected }) {
    return(
        <CustomSearchIcon
            sx={{ color: selected ? "#fff" : "#575757"}}
        />
    )
}

export function CombinedPlayIcon({ selected, icon }) {
    return(
        <PlayArrowOutlinedIcon
            sx={{ 
                color: selected ? "#fff" : "#575757",
                minWidth: "27px",
            }}
        />
    )
}

export function KuneiformIcon({ selected }) {
    return(
        selected ? <img src={kformSelected} className="nav-icon" alt='kf icon'/> : <img src={kformUnselected} className="nav-icon" alt='kf icon' />
    )
}

const CustomLaunchIcon = styled(LaunchIcon)({
    height: "27px",
    cursor: "pointer",
    '&:hover': {
        opacity: "0.8"
    }
})

export function CombinedLaunchIcon({ selected }) {
    return(
        <CustomLaunchIcon
            sx={{ color: selected ? "#fff" : "#575757"}}
        />
    )
}

export function MetamaskIcon() {
    return(
        <img src={metamaskFox} alt="metamask fox"/>
    )
}

export const CloseFileIcon = styled(CloseIcon)({
    color: "#E4E4E4",
    height: "20px",
    right: "0",
    position: "absolute",
    "&:hover": {
        background: "#3B3B3A"
    }
})

export const AddWorkspaceIcon = styled(AddIcon)({
    color: "#118EA0",
    height: "17px",
    marginLeft: "5px"
})

export function NewFileIcon() {
    return(
        <img 
            src={AddFileIcon}
            className="file-icon"
            alt='add file icon'
        />
    )
}

export function NewFolderIcon() {
    return(
        <img
            src={AddFolderIcon}
            className="folder-icon"
            alt='add folder icon'
        />
    )
}

export const CloseFolderIcon = styled(FolderIcon)({
    color: "#fff"
})

export const OpenFolderIcon = styled(FolderOpenIcon)({
    color: "#fff"
})

export function SingleFileIcon() {
    return(
        <img src={SingleFile} className="single-file" alt="single file" />
    )
}

export function WhiteFeatherIcon() {
    return(
        <img src={WhiteFeather} alt='white feather' />
    )
}

export function SquareChecked({ checked }) {
    return(
        <>
            {checked ? <img src={CheckedBox} className="checked-box" alt='checked box' /> : <img src={UncheckedBox} className="checked-box" alt='unchecked box' />}
        </>
    )
}

export function FileResultIcon({ expanded }) {
    return(
        <>
            {expanded ? <img src={ExpandFileResult} className="single-file" alt='expand file result' /> : <img src={CloseFileResult} className="single-file" alt='close file result' />}
        </>
    )
}

export const AddFundsIcon = styled(AddIcon)({
    color: "#fff",
    width: "16px"
})

export const AddFundsCheck = styled(CheckIcon)({
    color: "#575757",
    width: "15px"
})

export const ExpandMoreIcon = styled(ExpandMore)({
    color: "#000",
})

export const CustomLoader = styled(CircularProgress)({
    maxHeight: "20px",
    maxWidth: "20px",
    margin: "0 6px"
})