import { combineReducers, configureStore } from "@reduxjs/toolkit";
import persistReducer from "redux-persist/es/persistReducer";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import { navigation } from "./reducers/navigation";
import { workspaces, initialWorkspaceState } from "./reducers/workspaces";
import { filesDTO, initalFileState } from "./reducers/filesDTO";
import { loadedFiles } from "./reducers/loadedFiles";
import { alerts } from "./reducers/alerts";
import createMigrate from "redux-persist/es/createMigrate";

const migrations = {
    9: (state) => {
        return {
            ...state,
            filesDTO: {
                ...initalFileState,
            },
            workspaces: {
                ...initialWorkspaceState,
            }
        }
    },
}

const persistConfig = {
    key: 'root',
    storage: storage,
    version: 9,
    migrate: createMigrate(migrations, { debug: false }),
};

const appReducer = combineReducers({
    navigation: navigation,
    workspaces: workspaces,
    filesDTO: filesDTO,
    loadedFiles: loadedFiles,
    alerts: alerts,
});

const persistedReducer = persistReducer(persistConfig, appReducer);

const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk]
})

export default store;