import styled from "@emotion/styled";
import { MenuItem, Select } from "@mui/material";


export const WorkspaceDropdown = styled(Select)({
    background: "#8b8b8b",
    width: "100%",
    height: "25px",
    borderRadius: "5px",
    border: 'transparent',
    color: "#fff",
    fontFamily: "AvertaStd",
    fontWeight: "300",
    fontSize: "13px",
    padding: "0px 10px",
    "& .MuiSelect-icon": {
        height: "20px",
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        pointerEvents: 'none',
        bottom: '0px',
        margin: "auto 0px",
        color: "#575757",
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'transparent',
    },
})

export const CustomMenuItem = styled(MenuItem)({
    background: "transparent",
    color: "#fff",
    fontFamily: "AvertaStd",
    fontWeight: "300",
    fontSize: "13px",
    "&.Mui-selected": {
        background: "transparent"
    },
    "&:hover": {
        background: "#69787A"
    },
    "& .MuiTouchRipple-root": {
        color: "#118EA0"
    }
})

export const WorkspaceTemplateDropDown = styled(Select)({
    background: "#6B6B82",
    width: "calc(100% - 54px)",
    height: "25px",
    borderRadius: "5px",
    border: 'transparent',
    color: "#fff",
    fontFamily: "AvertaStd",
    fontWeight: "300",
    fontSize: "13px",
    padding: "0px 10px",
    margin: "6px 27px 0px 27px",
    "& .MuiSelect-icon": {
        height: "20px",
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        pointerEvents: 'none',
        bottom: '0px',
        margin: "auto 0px",
        color: "#fff",
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'transparent',
    },
})

export const CompileFilesDropDown = styled(Select)({
    width: "calc(100% - 14px)",
    height: "25px",
    background: "#8b8b8b",
    border: 'transparent',
    color: "#fff",
    fontFamily: "AvertaStd",
    fontWeight: "300",
    fontSize: "13px",
    borderRadius: "5px",
    padding: "0px 10px",
    margin: "0px 7px 6px 7px",
    "& .MuiSelect-icon": {
        height: "20px",
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        pointerEvents: 'none',
        bottom: '0px',
        margin: "auto 0px",
        color: "#575757",
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'transparent',
    },
})

export const CompileDisabledOption = styled(MenuItem)({
    background: "transparent",
    display: "flex",
    padding: "0px 10px",
    height: "20px",
    fontFamily: "AvertaStd",
    fontWeight: "500",
    fontSize: "13px",
    "&.Mui-selected": {
        background: "transparent"
    },
    "&:hover": {
        background: "#69787A"
    },
    "& .MuiTouchRipple-root": {
        color: "#118EA0"
    }
})

export const CompileFolderOption = styled(MenuItem)({
    background: "transparent",
    display: "flex",
    padding: "0px 10px",
    height: "20px",
    "&.Mui-selected": {
        background: "transparent"
    },
    "&:hover": {
        background: "#69787A"
    },
    "& .MuiTouchRipple-root": {
        color: "#118EA0"
    }
})


export const CompileFileOption = styled(MenuItem)({
    background: "transparent",
    padding: "0px 10px",
    height: "20px",
    "&.Mui-selected": {
        background: "transparent"
    },
    "&:hover": {
        background: "#69787A"
    },
    "& .MuiTouchRipple-root": {
        color: "#118EA0"
    }
})

export const DeployedDatabaseOption = styled(MenuItem)({
    background: "transparent",
    display: "flex",
    padding: "auto 10px",
    height: "20px",
    fontFamily: "AvertaStd",
    fontWeight: "300",
    fontSize: "13px",
    color: "#fff",
    "&.Mui-selected": {
        background: "transparent"
    },
    "&:hover": {
        background: "#69787A"
    },
    "& .MuiTouchRipple-root": {
        color: "#118EA0"
    },
    "& .MuiSelect-select": {
        padding: "auto"
    }
})