import { useEffect, useState } from "react";
import { getAccount } from "./webKwil";

export function useGetAccount(address, refresh) {
    const [account, setAccount] = useState()

    async function goAccount() {
        const acct = await getAccount(address);
        setAccount(acct);
    }

    useEffect(() => {
        if(!address) {
            setAccount(null);
            return;
        };
        goAccount();
    }, [address, refresh])

    return account;
}