import { CombinedLaunchIcon, CombinedPlayIcon, CombinedSearchIcon, FileIcon } from "../Mui/icons";
import kwilBlueFeather from "../../assets/kwil-blue-feather.svg";
import { useSelector, useDispatch } from "react-redux";
import { toFiles, toSearch, toCompile, toPlay } from "../../redux/reducers/navigation";
import { SideBarButton } from "../Mui/buttons";

export default function NavBar() {
    const fileSelected = useSelector(state => state.navigation.files)
    const searchSelected = useSelector(state => state.navigation.search)
    const compileSelected = useSelector(state => state.navigation.compile)
    const playSelected = useSelector(state => state.navigation.play)

    const dispatch = useDispatch()

    return(
        <div className="nav-bar">
            <img 
                src={kwilBlueFeather}
                className="kwil-feather"
                onClick={() => window.open("https://kwil.com")}
                alt="kwil feather"
            />
            <SideBarButton
                onClick={() => dispatch(toFiles())}
            >
                <FileIcon 
                    selected={fileSelected}
                    
                />
            </SideBarButton>
            <SideBarButton
                onClick={() => dispatch(toSearch())}
            >
                <CombinedSearchIcon 
                    selected={searchSelected}
                />
            </SideBarButton>
            <SideBarButton
                onClick={() => dispatch(toCompile())}
            >
                <CombinedLaunchIcon
                    selected={compileSelected}
                />
            </SideBarButton>
            <SideBarButton
                onClick={() => dispatch(toPlay())}
            >
                <CombinedPlayIcon
                    selected={playSelected}
                />
            </SideBarButton>
        </div>
    )
}