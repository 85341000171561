import { useState } from "react";
import FileManager from "./FilesMenu-components/FileManager";
import WorkspaceMenu from "./FilesMenu-components/WorkspaceMenu";

export default function FilesMenu({ open }) {
    const [newFolder, setNewFolder] = useState(false)
    const [newFile, setNewFile] = useState({})
    const [currentFolder, setCurrentFolder] = useState("")

    return(
        <div 
            className="files-menu"
            style={{ display: open ? "flex" : "none"}}    
        >
            <WorkspaceMenu
                setNewFolder={setNewFolder}
                currentFolder={currentFolder}
                setCurrentFolder={setCurrentFolder}
                setNewFile={setNewFile}
            />
            <FileManager
                newFolder={newFolder}
                setNewFolder={setNewFolder}
                currentFolder={currentFolder}
                setCurrentFolder={setCurrentFolder}
                newFile={newFile}
                setNewFile={setNewFile}
            />
        </div>
    )
}