import { CompileFolderOption } from "../../../Mui/menus"
import { SquareChecked } from "../../../Mui/icons";
import CompileFilesInterface from "./CompileFilesInterface";
import { useEffect, useState } from "react";


export default function CompileFoldersInterface({ folderName, files, selectedFiles, setSelectedFiles }) {
    const filesArray = files ? Object.values(files) : []
    const [isSelected, setIsSelected] = useState(false)
    
    function isFolderSelected() {
        let value = false
        for (let i = 0; i < filesArray.length; i++) {
            for (let j = 0; j < selectedFiles.length; j++) {
                if (filesArray[i].file === selectedFiles[j]) {
                    value = true
                }
            }
        }
        return value
    }

    function areAllFilesSelected() {
        let value = true
        for (let i = 0; i < filesArray.length; i++) {
            if (!selectedFiles.includes(filesArray[i].file)) {
                value = false
            }
        }
        return value
    }

    function handleClick() {
        const allFilesSelected = areAllFilesSelected()

        if (allFilesSelected) {
            for (let i = 0; i < filesArray.length; i++) {
                setSelectedFiles(selectedFiles => selectedFiles.filter(file => file !== filesArray[i].file))
            }
            return
        }

        if(!allFilesSelected) {
            filesArray.forEach(file => {
                if (!selectedFiles.includes(file.file)) {
                    setSelectedFiles(selectedFiles => [...selectedFiles, file.file])
                }
            })
        }
    }

    useEffect(() => {
        setIsSelected(isFolderSelected())
    }, [selectedFiles])


    return(
        <>
            <CompileFolderOption
                onClick={handleClick}
            >
                <SquareChecked 
                    checked={isSelected}
                />
                <p className="compile-files-text">{folderName}</p>
            </CompileFolderOption>
            <div className="files-in-folder">
                {filesArray.length > 0 && filesArray.map((file, index) => {
                    return(
                        <CompileFilesInterface 
                            key={index}
                            file={file}
                            selectedFiles={selectedFiles}
                            setSelectedFiles={setSelectedFiles}
                        />
                    )
                })}
            </div>
        </>
    )
}
