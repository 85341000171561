import { useEffect, useState } from "react";
import { CompileButton, CompiledFileButton, DeployButton, FileNameButton } from "../../Mui/buttons";
import { SingleFileIcon, WhiteFeatherIcon } from "../../Mui/icons";
import CompileFilesMenu from "./Compile-components/CompileFilesMenu";
import klWasm from "../../../assets/kl.wasm";
import { useDispatch, useSelector } from "react-redux";
import { addTab, selectFile } from "../../../redux/reducers/loadedFiles";
import { cleanCode, createErrorMessage, parseKfArray } from "../../../utils/compileFunctions";
import { errorOn, successOff, successOn } from "../../../redux/reducers/alerts";
import { broadcastDb, buildDbTx, waitForSuccess } from "../../../utils/deployFunctions";
import CompileFileContextMenu from "./Compile-components/CompileFileContextMenu";
import { useAccount, useSigner } from "wagmi";
import { isJsonError } from "../../../utils/playFunctions";

export default function Compile({ open, setNewDeployed, balance }) {
    const [selectedFiles, setSelectedFiles] = useState([])
    const tabFiles = useSelector(state => state.loadedFiles.tabFiles);
    const allFiles = useSelector(state => state.filesDTO);
    const [compiledFiles, setCompiledFiles] = useState([])

    const dispatch = useDispatch();

    function handleClick(n) {
        if (tabFiles.includes(n)) {
            dispatch(selectFile(n))
            return
        }

        dispatch(addTab(n))
    }

    useEffect(() => {
        async function loadWasm() {
            try {
                const go = new window.Go()
                const response = await fetch(klWasm);
                const wasmBinary = await response.arrayBuffer();
                const result = await WebAssembly.instantiate(wasmBinary, go.importObject);

                go.run(result.instance);
            } catch (e) {
                console.log(e)
            }
        }
        loadWasm()
    }, [])

    //reset selected and compiled files if workspace changes
    const currentWorkspace = useSelector(state => state.workspaces.currentWorkspace)
    useEffect(() => {
        setSelectedFiles([])
        setCompiledFiles([])
    }, [currentWorkspace])

    return (
        <div
            className="compile-menu"
            style={{ display: open ? "flex" : "none" }}
        >
            <CompileFilesMenu
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
            />
            <SelectedFilesDisplay
                selectedFiles={selectedFiles}
                handleClick={handleClick}
            />
            <CompileComponent
                allFiles={allFiles}
                selectedFiles={selectedFiles}
                setCompiledFiles={setCompiledFiles}
            />
            <CompiledFilesDisplay
                compiledFiles={compiledFiles}
                handleClick={handleClick}
            />
            <DeployComponent
                compiledFiles={compiledFiles}
                setCompiledFiles={setCompiledFiles}
                setNewDeployed={setNewDeployed}
                balance={balance}
            />
        </div>
    )
}

function SelectedFilesDisplay({ selectedFiles, handleClick }) {
    return (
        <div
            className="selected-compile-files"
        >
            <p className="selected-files">Selected Files</p>
            {selectedFiles.length > 0 ? selectedFiles.map((file, index) => {
                return (
                    <FileNameButton
                        startIcon={<SingleFileIcon />}
                        key={index}
                        onClick={() => handleClick(file)}
                    >
                        <p className="file-text">{file}</p>
                    </FileNameButton>
                )
            }) :
                <p className="no-file-selected">No files selected</p>
            }
        </div>
    )
}

function CompileComponent({ allFiles, selectedFiles, setCompiledFiles }) {
    const dispatch = useDispatch();

    function compile() {
        const readyForCompile = cleanCode(allFiles, selectedFiles);
        const parsed = parseKfArray(readyForCompile)

        setCompiledFiles(parsed)

        const errors = createErrorMessage(parsed)

        if (parsed.length === 0) {
            dispatch(errorOn("No files selected to compile"))
            return
        }

        if (!errors && window.parseKuneiform) {
            dispatch(successOn("All files compiled successfully!"))
        }

        if (errors) {
            dispatch(errorOn(errors))
        }

        if (!window.parseKuneiform) {
            dispatch(errorOn("Error loading Kuneiform parser. Please refresh your page and try again."))
        }
    }

    return(
        <CompileButton
            endIcon={<WhiteFeatherIcon />}
            onClick={compile}
        >
            Compile
        </CompileButton>
    )
}

function CompiledFilesDisplay({ compiledFiles, handleClick }) {
    const [contextClicked, setContextClicked] = useState(false)
    const [points, setPoints] = useState({ x: 0, y: 0 })

    useEffect(() => {
        const handleClick = () => setContextClicked(false)
        document.addEventListener("click", handleClick)
        return () => document.removeEventListener("click", handleClick)
    }, [])

    return(
        <div className="selected-compile-files">
            <p className="selected-files">Compiled Files</p>
            {compiledFiles.length > 0 ? compiledFiles.map((file, index) => {
                return (
                    <>
                        <CompiledFileButton
                            startIcon={<SingleFileIcon />}
                            key={index}
                            sx={{
                                backgroundColor: file.error ? "#EE6055" : "#4E723F"
                            }}
                            onClick={() => handleClick(file.file)}
                            onContextMenu={(e) => {
                                if (file.json) {
                                    e.preventDefault()
                                    setContextClicked(true)
                                    setPoints({ x: e.clientX, y: e.clientY })
                                }
                            }}
                        >
                            <p className="file-text">{file.file}</p>
                        </CompiledFileButton>
                        {contextClicked &&
                            <CompileFileContextMenu
                                left={points.x}
                                top={points.y}
                                json={file.json}
                                name={file.file}
                            />
                        }
                    </>
                )
            }) :
                <p className="no-file-selected">No files compiled</p>
            }
        </div>
    )
}

function DeployComponent({ compiledFiles, setCompiledFiles, setNewDeployed, balance }) {
    const dispatch = useDispatch();
    const { data: signer } = useSigner();
    const { address } = useAccount();

    
    async function deployCompiled() {
        //check if there are any files to deploy
        if (compiledFiles.length === 0) {
            dispatch(errorOn("No files compiled to deploy"))
            return
        }

        //check if metamask is installed
        if(!window.ethereum) {
            dispatch(errorOn("Please ensure you have Metamask installed!"))
            return
        }

        //check if user is connected
        if (!address || !signer) {
            dispatch(errorOn("Please connect your wallet!"))
            return
        }
        
        //deploy each file
        for (const obj of compiledFiles) {
            //because the kuneiform parser returns a stringified json, we need to parse it back to json
            const parsedCompiledKuneiform = JSON.parse(obj.json);

            // add the owner to the json
            parsedCompiledKuneiform.owner = address;

            // set the database name to lowercase
            parsedCompiledKuneiform.name = parsedCompiledKuneiform.name.toLowerCase();

            try {
                //build the transaction
                const tx = await buildDbTx(parsedCompiledKuneiform, address, signer);

                //broadcast the transaction
                const hash = await broadcastDb(tx);
                dispatch(successOn(`Transaction deploying, this may take a few seconds... Hash: ${hash}`))

                //wait for the transaction to be mined
                await waitForSuccess(hash);
                dispatch(successOff())

                //refresh the deployed database list
                setNewDeployed(obj.code)

                setTimeout(() => {
                    dispatch(successOn("Database successfully deployed!"))
                }, 300);
            } catch (error) {
                const e = isJsonError(error);
                const fundingErrMsg = Number(balance) === 0 ? ". Make sure you have funds in your account! You can request Kwil testnet funds at https://faucet.kwil.com" : ""
                dispatch(errorOn(e + fundingErrMsg))
                // generate random number
                const random = Math.floor(Math.random() * 1000000);
                setNewDeployed(random.toString())
            }
        }

        //reset the compiled files list
        setCompiledFiles([])
    }

    return(
        <DeployButton
            endIcon={<WhiteFeatherIcon />}
            onClick={deployCompiled}
        >
            Deploy Compiled Files
        </DeployButton>
    )
}