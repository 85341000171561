import { InputBase } from "@mui/material";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { CustomMenuItem, WorkspaceDropdown } from "../../../Mui/menus";
import { AddWorkspaceIcon, NewFileIcon, NewFolderIcon } from "../../../Mui/icons";
import { useEffect, useState } from "react";
import NewWorkspaceModal from "./NewWorkspaceModal";
import { useDispatch, useSelector } from "react-redux";
import ContextMenu from "./ContextMenu";
import { selectWorkspace } from "../../../../redux/reducers/workspaces";
import { NewFileButton } from "../../../Mui/buttons";
import { resetLoadedFiles } from "../../../../redux/reducers/loadedFiles";


export default function WorkspaceMenu({ setNewFolder, currentFolder, setNewFile, setCurrentFolder }) {
    const [newWorkspace, setNewWorkspace] = useState(false);
    const workspaces = useSelector(state => state.workspaces.workspaceList)
    const selectedWorkspace = useSelector(state => state.workspaces.currentWorkspace);

    const dispatch = useDispatch();

    //custom right click menu for deleting workspaces
    const [clicked, setClicked] = useState(false);
    const [points, setPoints] = useState({ x: 0, y: 0 });
    const [rightClickedWorkspace, setRightClickedWorkspace] = useState("");

    useEffect(() => {
        const handleClick = () => setClicked(false);
        window.addEventListener("click", handleClick);
        return () => window.removeEventListener("click", handleClick);
    }, [])

    // create new file
    function createNewFile() {
        setNewFile({
            folder: currentFolder,
        })
    }

    return(
        <div className="workspace-menu">
            <WorkspaceDropdown
                displayEmpty
                input={<InputBase />}
                IconComponent={UnfoldMoreIcon}
                MenuProps={{
                   PaperProps: {
                        style: {
                            background: "#3B3B3A",
                        }
                   },
                   style: { zIndex: 99 }
                }}
                value={selectedWorkspace}
                onChange={(e) => {
                    if(e.target.value !== "new-workspace") {
                        dispatch(selectWorkspace(e.target.value))
                        setCurrentFolder("")
                        dispatch(resetLoadedFiles())
                    }
                }}
            >
                {workspaces.length > 0 && workspaces.map((workspace, index) => {
                    return(
                        <CustomMenuItem 
                            value={workspace.name}
                            key={index}
                            onContextMenu={(e) => {
                                if(workspace !== "Default Workspace") {
                                    e.preventDefault();
                                    setClicked(true);
                                    setPoints({ x: e.pageX, y: e.pageY });
                                    setRightClickedWorkspace(workspace.name);
                                }
                            }}
                        >
                            {workspace.name}
                        </CustomMenuItem>
                    )
                })}
                <CustomMenuItem
                    value="new-workspace"
                    onClick={() => setNewWorkspace(true)}
                >
                    <p className="new-workspace">New Workspace</p>
                    <AddWorkspaceIcon />
                </CustomMenuItem>
            </WorkspaceDropdown>
            <NewFileButton
                onClick={() => createNewFile()}
            >
                <NewFileIcon />
            </NewFileButton>
            <NewFileButton
                onClick={() => setNewFolder(true)}
            >
                <NewFolderIcon/>
            </NewFileButton>
            <NewWorkspaceModal
                newWorkspace={newWorkspace}
                setNewWorkspace={setNewWorkspace}
            />
            {clicked && 
                <ContextMenu 
                    left={points.x} 
                    top={points.y} 
                    rightClickedWorkspace={rightClickedWorkspace}
                />
            }
        </div>
    )
}
